var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center dropdown-item",
      on: {
        click: function($event) {
          return _vm.selectCountry(_vm.option)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "mr-1 col-auto px-0 d-flex" },
        [
          _c("AppImage", {
            staticClass: "rounded",
            attrs: {
              src: _vm.option.image,
              width: "23px",
              cover: "",
              height: "16px"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex gap-5px" }, [
        _c("div", { staticClass: "text-truncate label-text para-2" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.option.name) + "\n\t\t")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "additional-text para-2" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.option.dialCode) + "\n\t\t")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }