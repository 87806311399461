var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: {
        id: "store-customizing-modal",
        action: _vm.$t("Start Customizing"),
        title: _vm.$t("It’s time to start customizing your products."),
        subheading: _vm.$t(
          "Select one of your pre-built products to edit the details, or, +Add Product to explore other product types."
        )
      },
      on: { close: _vm.closeModal, cta: _vm.closeModal }
    },
    [
      _c("img", {
        staticClass: "img-fluid mb-2",
        attrs: { src: "/images/customize-products.png" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }