<template>
	<div class="accordion-item card" :class="{ 'no-header': !showHeader, 'has-actions': !!actions }" ref="wrapper">
		<div
			class="card-header"
			:id="heading"
			data-toggle="collapse"
			:data-target="dataTarget"
			aria-expanded="true"
			:aria-controls="collapseId"
			:class="{ collapsed: !visible }"
			v-if="showHeader"
			@change="$emit('changed', name)"
		>
			<div class="card-header-content-wrapper">
				<div class="accordion-icon" :class="{ error }" v-if="image">
					<InlineSvg :src="image" alt="" />
				</div>
				<div class="accordion-title">
					<span class="h3 text-truncate text-bold" :class="titleClass">
						{{ title }}
					</span>
					<div v-if="titleLabels.length > 0" class="accordion-title-label-wrapper accordion-actions-wrapper">
						<template v-for="action in titleLabels">
							<span
								class="col-auto px-0"
								:key="action.key"
								:class="action.customClass"
								v-if="action.type === 'tag' && action.visible"
								@click.stop="$emit('action-trigger', action)"
							>
								<AppTag
									:label="action.label"
									:backgroundColor="action.backgroundColor"
									:textColor="action.textColor"
									:image="action.image"
									:svgFill="action.svgFill"
									:fontWeight="400"
								/>
							</span>
						</template>
					</div>
				</div>

				<div class="accordion-actions-wrapper">
					<template v-for="action in actions">
						<span
							class="col-auto px-0"
							:key="action.key"
							:class="action.customClass"
							v-if="action.type === 'tag' && action.visible"
							@click.stop="$emit('action-trigger', action)"
						>
							<AppTag
								:label="action.label"
								:backgroundColor="action.backgroundColor"
								:textColor="action.textColor"
								:image="action.image"
							/>
						</span>
						<span
							:key="action.key"
							class="accordion-icon d-flex align-items-center"
							:class="action.class"
							v-else-if="action.visible"
							@click.stop="$emit('action-trigger', action)"
						>
							<InlineSvg :src="action.icon" />
						</span>
					</template>
					<div class="accordion-icon" v-if="dropdown">
						<AppDropdownMenu class="px-1" :options="dropdown" @selected="dropdownSelected" />
					</div>
				</div>

				<span class="accordion-icon indicator" v-if="showAccordionArrow">
					<img src="/images/icons/ic-chevron-down.svg" />
				</span>
			</div>
		</div>
		<div :id="collapseId" class="collapse" :class="{ show: visible }" :aria-labelledby="heading" :data-parent="dataParent">
			<div class="card-body" :class="[bodyClass, { 'no-border px-0 pb-0': isWrapper }]">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AppAccordionItem',
		props: {
			name: { type: String, defualt: '' },
			showHeader: { type: Boolean, default: () => true },
			visible: { type: Boolean, defualt: false },
			parent: { type: String, defualt: '' },
			title: { type: String, defualt: '' },
			image: { type: String, defualt: '' },
			titleLabels: { type: Array, default: () => [] },
			actions: { type: Array, default: () => [] },
			isWrapper: { type: Boolean, default: false },
			showAccordionArrow: { type: Boolean, default: true },
			dropdown: { type: Array, default: () => undefined },
			bodyClass: { type: String, defualt: '' },
			titleClass: { type: String, defualt: '' },
			error: { type: Boolean, default: false },
		},
		mounted() {
			$(this.dataTarget).on('shown.bs.collapse', this.triggerShown)
			$(this.dataTarget).on('.collapse', this.triggerShown)
			$(this.dataTarget).on('hidden.bs.collapse', this.triggerHidden)

			$(this.dataTarget).on('focusin', this.triggerFocusIn)
			$(this.dataTarget).on('focusout', this.triggerFocusOut)

			$(this.$refs.wrapper).on('mouseup', this.triggerMouseUp)
			$(this.$refs.wrapper).on('mousedown', this.triggerMouseDown)
			$(this.$refs.wrapper).on('click', this.triggerClick)
		},
		destroyed() {
			$(this.dataTarget).off('shown.bs.collapse', this.triggerShown)
			$(this.dataTarget).off('hidden.bs.collapse', this.triggerHidden)
			$(this.dataTarget).off('.collapse', this.triggerShown)

			$(this.dataTarget).off('focusin', this.triggerFocusIn)
			$(this.dataTarget).off('focusout', this.triggerFocusOut)

			$(this.$refs.wrapper).off('mouseup', this.triggerMouseUp)
			$(this.$refs.wrapper).off('mousedown', this.triggerMouseDown)
			$(this.$refs.wrapper).off('click', this.triggerClick)
		},
		computed: {
			collapseId() {
				return `collapse${this.name}`
			},
			heading() {
				return `heading${this.name}`
			},
			dataParent() {
				return this.parent ? `#accordion-${this.parent}` : ''
			},
			dataTarget() {
				return `#${this.collapseId}`
			},
		},
		methods: {
			triggerShown(event) {
				this.$emit('shown', event)
			},
			triggerHidden(event) {
				this.$emit('hidden', event)
			},
			triggerFocusIn(event) {
				this.$emit('focusin', event)
			},
			triggerFocusOut(event) {
				this.$emit('focusout', event)
			},
			triggerMouseUp(event) {
				this.$emit('mouseup', event)
			},
			triggerMouseDown(event) {
				this.$emit('mousedown', event)
			},
			triggerClick(event) {
				this.$emit('click', event)
			},
			dropdownSelected(event) {
				this.$emit('selected', event)
			},
		},
		data() {
			return {}
		},
	}
</script>

<style lang="scss" scoped>
	%flex-auto {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.accordion-item {
		&.card,
		&.card:not(:last-of-type),
		&.card:not(:first-of-type) {
			box-shadow: var(--stan-box-shadow);
			background: var(--stan-white);
			overflow: hidden;
			border-radius: 13px;
			border: none;
			margin-bottom: 25px;
			&.has-actions {
				overflow: visible;
			}

			.card-header {
				padding: 0;
				margin-bottom: -1px;
				border-bottom: none;
				background: transparent;
				user-select: none;

				:hover {
					cursor: pointer;
				}
				.card-header-content-wrapper {
					display: flex;
					align-items: center;
					gap: 0.5rem;
					padding: 1.5625rem 1.25rem;
				}
			}
			.card-body {
				padding: 0 1.25rem 1.5rem;
			}
			&.border-less {
				&,
				.card-header,
				.card-body {
					border: none !important;
				}
			}
		}
		.indicator {
			transition: all linear 0.2s;
			transform: rotate(180deg);
		}
		.collapsed {
			.indicator {
				transform: rotate(360deg);
			}
		}
		&.no-header {
			.card-body {
				padding-top: 1.5rem;
			}
		}
	}
	.accordion-title {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		min-width: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
	}
	.accordion-icon {
		display: flex;
		align-items: center;
		@extend %flex-auto;
		img,
		svg::v-deep {
			width: 24px;
			height: 24px;
			path:not(.no-fill),
			rect:not(.no-fill) {
				fill: var(--stan-text-dark-color);
			}
		}
		&.error::v-deep,
		&.ai-danger::v-deep {
			color: var(--stan-danger);
			svg,
			svg path {
				fill: var(--stan-danger);
			}
		}
		& + .accordion-title {
			padding: 0;
		}
	}
	.accordion-title-label-wrapper {
		margin-left: 1rem;
	}
	.accordion-actions-wrapper {
		display: flex;
		align-items: center;
		@extend %flex-auto;
	}
	.overflow-visible {
		overflow: visible !important;
	}
	.accordion-error > .card-header > .card-header-content-wrapper > .accordion-title {
		color: var(--stan-danger);
	}
</style>
