var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "AppModal",
        {
          staticStyle: { "max-height": "100vh", "max-width": "100vw" },
          attrs: {
            large: "",
            frame: "",
            isVideo: "",
            id: _vm.id,
            className: "block-modal fade",
            closeButton: false,
            backdrop: false
          },
          on: { close: _vm.closeModal }
        },
        [
          _vm.isPlayingVideo
            ? _c(
                "div",
                {
                  staticClass: "embed-responsive embed-responsive-16by9",
                  attrs: { id: "video-play-wrapper" }
                },
                [
                  _c("iframe", {
                    staticClass: "embed-responsive-item",
                    attrs: {
                      src: _vm.selectedVideoUri,
                      frameborder: "0",
                      allow:
                        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
                      allowfullscreen: ""
                    }
                  })
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }