<template>
	<div class="position-relative">
		<div :class="{ 'creator-pro-feature-not-allowed': !isAffiliateShareAllowed }">
			<div class="affiliate-link-section">
				<div class="mb-3 text-sm" v-html="getHelperText()"></div>
				<div class="mb-3 text-sm sub-title">{{ $t('Affiliate Commission %') }}</div>
				<div v-if="value">
					<div class="affiliate-share-wrapper mb-5 mb-sm-2" v-for="(affiliate_share, i) in value" :key="`affiliate_share-${i}`">
						<div class="row align-items-center flex-nowrap" v-show="affiliate_share.status !== 'deleted'">
							<div class="col-auto">
								<AppInput
									:id="`affiliate_share_percent_${i}`"
									:class="{ 'form-error': validation.$each[i].percent.$error }"
									:disabled="affiliate_share.status === 'active'"
									class="mb-1 affiliate_share_percent_input"
									type="number"
									v-model.number="affiliate_share.percent"
									:placeholder="$t('ex. 15')"
									suffix="%"
									:min="percent_min"
									:max="percent_max"
								/>
							</div>
							<div class="align-content-center al-link text-break">
								<span class="user-select-none text-truncate">
									{{ getAffiliateShareLink(affiliate_share) }}
								</span>

								<InlineSvg
									role="button"
									class="copy-icon"
									src="/images/icons/ic-copy.svg"
									v-if="getAffiliateShareLink(affiliate_share) && pageId > 0"
									@click="copyAffiliateUrl(affiliate_share)"
								/>
							</div>
							<AppDropdownMenu
								keepOpen
								rightAlign
								class="pr-2"
								:options="dropDownOptions(affiliate_share, pageId)"
								@selected="$event.handler(affiliate_share, i)"
							>
							</AppDropdownMenu>
						</div>
						<div>
							<small class="stan-text-danger error" v-if="!validation.$each[i].percent.between">
								Percentage must be between {{ percent_min }}% and {{ percent_max }}%
							</small>
						</div>
						<div>
							<small class="stan-text-danger error" v-if="!validation.$each[i].percent.isUnique">
								Percentage must be unique
							</small>
						</div>
					</div>
					<AppButton name="add-affiliate-share-link" outline class="w-100" @click="$emit('addAffiliateShare')">
						{{ $t('+ Add') }} <span v-if="value.length">&nbsp;{{ $t('Another') }}&nbsp;</span> {{ $t('Affiliate Link') }}
					</AppButton>
				</div>
			</div>
		</div>
		<AppUpgrade title="Want to create affiliate links for your product?" v-if="!isAffiliateShareAllowed" />
	</div>
</template>

<script>
	import { mapGetters, mapActions } from 'vuex'
	import { nanoid } from 'nanoid'
	import AppUpgrade from '~/components/shared/AppUpgrade'

	export default {
		name: 'AffiliateShareForm',
		components: {
			AppUpgrade,
		},
		props: {
			pageId: {
				type: Number,
				default: -1,
			},
			pageSlug: {
				type: String,
				default: undefined,
			},
			value: {
				type: Array,
				default: () => [],
			},
			validation: {
				type: Object,
				default: () => {},
			},
		},
		mounted() {},
		data() {
			return {
				percent_min: 1,
				percent_max: 90,
			}
		},
		computed: {
			...mapGetters('Stores', ['getStore']),
			...mapGetters('Auth', ['user']),
			affiliateShareLinkCodeLength() {
				return 6
			},
			isAffiliateShareAllowed() {
				return this.user.subscription_tier_allowed_modules?.indexOf('affiliate-share') !== -1
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			dropDownOptions(affiliateShare, pageId) {
				const out = []
				if (pageId > 0) {
					out.push({ label: this.$t('Copy URL'), handler: this.copyAffiliateUrl })
				}
				out.push({ label: this.$t('Delete'), handler: this.deleteAffiliateShare })
				return out
			},
			copyAffiliateUrl(affiliateShare) {
				const url = this.buildLink(affiliateShare.code)
				this.$clipboard(url)
				this.clipboardSuccessHandler()
			},
			deleteAffiliateShare(affiliateShare, index) {
				this.$emit('removeAffiliateShare', index)
			},
			clipboardSuccessHandler() {
				this.$stanNotify({
					type: 'success', // can be 'info', 'warning', 'success', 'error'
					title: this.$t('URL Copied!'),
					duration: 2000,
				})
			},
			getAffiliateCode(affiliateShare) {
				const deletedRecord = this.value.find(link => link.percent === affiliateShare.percent && link.status === 'deleted')
				if (deletedRecord) {
					return deletedRecord.code
				}
				affiliateShare.code = nanoid(this.affiliateShareLinkCodeLength)
				return affiliateShare.code
			},
			getAffiliateShareLink(affiliateShare) {
				const code =
					affiliateShare.code.length === this.affiliateShareLinkCodeLength ? affiliateShare.code : this.getAffiliateCode(affiliateShare)
				return this.buildLink(code)
			},
			buildLink(code) {
				if (this.pageId <= 0) return this.$t('Your affiliate link will be ready once you publish this product.')
				let url = `${this.siteSettings.frontDomain}${this.user.username}/${this.getStore?.slug}/page/${this.pageId}`
				if (this.pageSlug) {
					url = `${this.siteSettings.frontDomain}${this.user.username}/p/${this.pageSlug}`
				}
				return `${url}?al=${code}`
			},
			changePercent(index) {
				const currPercent = this.value[index].percent
				let deletedRecord
				let deletedRecordIndex
				this.value.every((link, index) => {
					if (link.percent === currPercent && link.status === 'deleted') {
						deletedRecord = link
						deletedRecordIndex = index
						return false
					}
					return true
				})

				if (deletedRecord && deletedRecordIndex >= 0) {
					this.value[index] = { ...deletedRecord }
					this.value[index].status = 'active'
					this.$emit('removeAffiliateShare', deletedRecordIndex)
				}
				this.validation.$touch()
			},
			openLink(url) {
				window.open(url, '_blank')
			},
			getHelperText() {
				return `${this.$t(
					'Affiliate your products so that anyone can sell them. Imagine what 100 people selling your product on your behalf could do!'
				)} <a href="https://help.stan.store/article/38-affiliate-share-feature" target="_blank">${this.$t('Learn more.')}</a>`
			},
		},
		watch: {},
	}
</script>

<style lang="scss" scoped>
	.affiliate-link-section {
		font-style: normal;
		font-size: 14px;

		.sub-title {
			font-weight: 700;
			line-height: 130%;
			color: var(--stan-blue-5);
		}
		.al-link {
			font-weight: 600;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: var(--stan-primary-primary-color);
			display: grid;
			grid-template-columns: auto auto;
			flex: 10 0;
		}
		.copy-icon::v-deep {
			max-width: 20px;
			path:not(.no-fill),
			rect:not(.no-fill) {
				fill: var(--stan-primary-primary-color);
			}
		}

		.affiliate_share_percent_input {
			min-width: 85px;
		}
	}
	.affiliate-link-resell-rights-section {
		display: flex;
		padding: 20px;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		align-self: stretch;
		border-radius: 0px 0px 10px 10px;
		border: 1px solid #b8c8ff;
		background: #f9f8ff;

		margin-top: 35px;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		margin-bottom: -1.5rem;

		.toggle {
			display: flex;
			align-items: flex-start;
			gap: 20px;
			align-self: stretch;

			.toggle-label-header {
				display: flex;
				align-items: center;
				gap: 8px;
				.menu-item-icon {
					width: 18px;
					height: 18px;
				}
				.new-highlighter {
					display: flex;
					padding: 3px 5px;
					align-items: flex-start;
					gap: 10px;
					border-radius: 3px;
					background: var(--Primary-Primary, #6355ff);
				}
			}

			.toggle-label {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;
			}
		}
		.toggle-expand {
			display: inline-flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 24px;
			padding-left: 58px;
			padding-top: 15px;

			.revenue-share {
				display: inline-flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 5px;

				.affiliate_share_percent_input::v-deep {
					.form-control {
						background-color: var(--stan-white) !important;
					}
					width: 100px;
					margin-right: 9px;
				}
			}
			.affiliate-share-message {
				display: flex;
				max-width: 608px;
				padding: 15px;
				align-items: flex-start;
				gap: 10px;
				border-radius: 8px;
				background: rgba(255, 255, 255, 0.5);
				box-shadow: 0px 5px 20px 0px rgba(0, 16, 109, 0.08);
			}
		}
	}
</style>
