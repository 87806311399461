var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tbody",
    _vm._l(_vm.lines, function(row, index) {
      return _c("tr", { key: index }, [
        _c(
          "td",
          { attrs: { colspan: "7" } },
          [
            _c("Skeleton", {
              staticClass: "skeleton-row",
              attrs: { heading: false, lines: 1 }
            })
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }