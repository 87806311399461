var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: { id: "send-opt-in-email-success-modal", action: "Okay!" },
      on: { cta: _vm.closeModal, close: _vm.closeModal }
    },
    [
      _c(
        "div",
        { staticClass: "pb-1 d-flex w-100 justify-content-center" },
        [
          _c("InlineSvg", { attrs: { src: "/images/emojis/party-popper.svg" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "m-4 text-bold text-center" }, [
        _vm._v(_vm._s(_vm.sendToText))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-4 text-light para-2 text-center" }, [
        _vm._v(
          "\n\t\tYour audience will start receiving emails from you as soon as they opt-in.\n\t"
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }