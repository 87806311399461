var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "transaction-form pay-form-wrapper card-payment-bg" },
    [
      _c("div", { staticClass: "form-bar" }, [
        _c(
          "form",
          {
            staticClass: "form-border-radius row no-gutters my-2 sentry-mask",
            attrs: { id: "payment-form", "data-test-id": "payment-form" }
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _c("div", {
                staticClass: "p-3 position-relative card-element-number",
                attrs: {
                  id: "card-element-number" + _vm.stripeElementIdSuffix,
                  "data-test-id": "card-number"
                }
              }),
              _vm._v(" "),
              _vm._m(0)
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("div", {
                staticClass: "p-3 card-element-expiry",
                attrs: {
                  id: "card-element-expiry" + _vm.stripeElementIdSuffix,
                  "data-test-id": "card-expiry"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-6" }, [
              _c("div", {
                staticClass: "p-3 position-relative card-element-cvc",
                attrs: {
                  id: "card-element-cvc" + _vm.stripeElementIdSuffix,
                  "data-test-id": "card-cvc"
                }
              }),
              _vm._v(" "),
              _vm._m(1)
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.preAuth && _vm.showCardMessages
          ? _c(
              "h4",
              { key: "1", staticClass: "m-0" },
              [
                _vm._v(
                  "\n\t\t\tYou might see a $1 pre-authorization charge on your statement. Don't worry! This will be immediately refunded! Powered by\n\t\t\t"
                ),
                _c("InlineSvg", {
                  staticClass: "px-1",
                  attrs: { src: "images/gray-stripe.svg" }
                })
              ],
              1
            )
          : _c(
              "h4",
              { key: "2", staticClass: "m-0" },
              [
                _vm._v("Secure Payment Powered by "),
                _c("InlineSvg", {
                  staticClass: "px-1",
                  attrs: { src: "images/gray-stripe.svg" }
                })
              ],
              1
            )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex position-absolute card-icon cart-type" },
      [
        _c("span", { staticClass: "opacity-0", attrs: { id: "visa" } }, [
          _c("img", { attrs: { src: "images/Visa.svg" } })
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "opacity-0", attrs: { id: "amex" } }, [
          _c("img", { attrs: { src: "images/Amex.svg" } })
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "opacity-0", attrs: { id: "discover" } }, [
          _c("img", { attrs: { src: "images/Discover.svg" } })
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "opacity-0", attrs: { id: "mastercard" } }, [
          _c("img", { attrs: { src: "images/Mastercard.svg" } })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-absolute card-icon" }, [
      _c("span", [_c("img", { attrs: { src: "images/cvc.svg" } })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }