var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-nowrap" },
    [
      _vm.tip.tip
        ? _c(
            "AppTooltip",
            {
              staticClass: "d-inline",
              attrs: {
                click: _vm.tip.click,
                hover: _vm.tip.hover,
                tip: _vm.tip.tip,
                html: _vm.tip.html,
                direction: _vm.tip.direction
              }
            },
            [
              this.labelText
                ? _c(
                    "div",
                    {
                      staticClass: "tag-container text-truncate mr-2",
                      class: { "w-100": _vm.fullWidth, round: _vm.rounded },
                      style: _vm.style
                    },
                    [
                      _vm.image
                        ? _c("InlineSvg", {
                            staticClass: "icon-image",
                            attrs: { src: _vm.image, fill: _vm.svgFill }
                          })
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.labelText) + "\n\t\t")
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : this.labelText
        ? _c(
            "div",
            {
              staticClass: "tag-container text-truncate mr-2",
              class: { "w-100": _vm.fullWidth, round: _vm.rounded },
              style: _vm.style
            },
            [
              _vm.image
                ? _c("InlineSvg", {
                    staticClass: "icon-image",
                    attrs: { src: _vm.image, fill: _vm.svgFill }
                  })
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.labelText) + "\n\t")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }