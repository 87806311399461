<template>
	<div class="product-editor-wrapper">
		<div class="row d-flex">
			<div class="col-md-12 order-1 order-xl-1" :class="{ 'col-xl-7': showPreview }">
				<div>
					<div v-if="loading" class="col pt-5">
						<Skeleton :lines="2" class="mb-5" />
						<Skeleton :lines="2" class="mb-5" />
						<Skeleton :lines="2" class="mb-5" />
						<Skeleton :lines="2" class="mb-5" />
					</div>
					<slot v-else name="product-details"></slot>
				</div>
			</div>
			<div class="col-md-12 col-xl-5 order-2 order-xl-2 relative" v-if="showPreview">
				<div class="preview-group-wrapper sticky-top">
					<div class="text-center preview-button-wrapper d-xl-none" @click="togglePreview">
						<div class="d-flex align-items-center"><inline-svg class="icon-eye mr-2" src="/images/icons/ic-show.svg" />Preview</div>
						<div><inline-svg class="icon-close" src="/images/icons/ic-close.svg" /></div>
					</div>
					<div class="preview-wrapper">
						<div v-if="loading" class="col pt-5 ">
							<Skeleton :lines="0" :img="true" class="mb-5" />
							<div class="site-preview-section mx-auto">
								<CalendarSkeleton :heading="false" class="mb-5" />
								<Skeleton :lines="2" class="mb-5" />
								<Skeleton :lines="2" class="mb-5" />
								<Skeleton :lines="2" class="mb-5" />
								<Skeleton :lines="2" class="mb-5" />
							</div>
						</div>
						<slot v-else name="preview"></slot>
					</div>
				</div>
			</div>
		</div>
		<div class="sticky-wrap">
			<slot name="control"></slot>
		</div>
	</div>
</template>
<script>
	import Skeleton from '~/components/skeletons/Skeleton'
	import CalendarSkeleton from '~/components/skeletons/CalendarSkeleton'

	export default {
		components: {
			Skeleton,
			CalendarSkeleton,
		},
		props: {
			loading: Boolean,
			showPreview: { type: Boolean, default: true },
		},
		methods: {
			togglePreview() {
				$('body').toggleClass('fullscreen-product-preview-show')
			},
		},
	}
</script>
<style lang="scss">
	@media (max-width: 1199px) {
		.wrapper {
			&.route-linksite_edit_page,
			&.route-funnel_edit_page {
				.mobile-preview-wrapper {
					display: none;
				}
				.content {
					padding-bottom: 100px;
				}
			}
		}
		body.fullscreen-product-preview-show,
		body.preview-active {
			@media (max-width: 991px) {
				.route-linksite_edit_page,
				.route-funnel_edit_page {
					.leftPanelWrapper {
						position: fixed;
					}
					.rightPanel {
						display: flex;
					}
				}
				.preview-group-wrapper {
					bottom: 80px;
				}
			}
			@media (max-width: 344px) {
				.preview-group-wrapper {
					bottom: 100px;
				}
			}
			.preview-group-wrapper {
				background-color: var(--stan-white);
				top: 0;
				padding-left: 0;
				display: flex;
				flex-direction: column;
				padding-bottom: 0;
				z-index: 3;
				.preview-button-wrapper {
					justify-content: space-between;
					.icon-close {
						display: block;
					}
				}
				.preview-wrapper {
					flex-basis: 0;
					flex-grow: 1;
					overflow: scroll;
					padding: 20px;
				}
			}
		}
		.hsds-beacon .BeaconFabButtonFrame {
			bottom: 60px !important;
			z-index: 1 !important;
		}
		@media (max-width: 991px) {
			.hsds-beacon .BeaconFabButtonFrame {
				bottom: 100px !important;
			}
		}
	}
</style>
<style lang="scss" scoped>
	.sticky-wrap {
		position: sticky;
		bottom: 0;
		width: 100%;
		z-index: 11;
		background: var(--stan-white);
		@media (max-width: 1200px) {
			position: relative;
			z-index: 0;
		}
	}
	.sticky-top {
		top: 10px;
		z-index: 0;
		@media (max-width: 767px) {
			position: relative;
			padding-bottom: 40px;
		}
	}
	.preview-group-wrapper {
		margin-left: 1rem;
		margin-right: 1rem;
		transition: all 0.5s ease;
		.preview-button-wrapper {
			display: none;
		}

		@media (max-width: 1199px) {
			position: fixed;
			top: calc(100% - 54px);
			width: 100%;
			left: 0;
			margin-left: 0;
			margin-right: 0;
			z-index: 1;
			user-select: none;
			bottom: 0;
			background-color: var(--stan-white);

			.preview-button-wrapper {
				padding: 15px;
				background-color: var(--stan-gray-primary-color);
				justify-content: center;
				color: var(--stan-text-light-color);
				.icon-close {
					display: none;
				}
				.icon-eye::v-deep {
					path,
					rect {
						fill: var(--stan-text-light-color);
					}
				}
				&:hover {
					cursor: pointer;
					background-color: var(--stan-gray-strike-color);
				}
			}
			@media (min-width: 992px) {
				padding-left: 220px;
				.preview-button-wrapper {
					display: flex;
					align-items: center;
					margin-bottom: 65px;
				}
			}
		}
	}
	.border-top-left {
		border-top-left-radius: 10px;
	}
	.border-top-right {
		border-top-right-radius: 10px;
	}
	.row.page-row::v-deep {
		margin: 0 -15px;
		padding-left: 26px;
		padding-right: 35px;
		@media (max-width: 480px) {
			padding-left: 23px;
			padding-right: 23px;
		}
		.col-md-12 {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.header {
		min-height: 54px;
		background: var(--stan-oxford-blue-2);
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: 0.04em;
		color: var(--stan-primary-dark-color);
		display: flex;
		align-items: center;
		i {
			font-size: 22px;
			margin-right: 10px;
		}
	}
	.product-editor-wrapper {
		position: relative;
		overflow: visible;
	}
	@media (max-width: 575px) {
		.accordion::v-deep {
			.accordion-item.card.compact:first-child {
				border-top-left-radius: 13px;
			}
		}
	}
</style>
