<template>
	<AppModal
		id="refund-payments-modal"
		:title="$t('Refund Money')"
		:subheading="$t('You can only refund pledges from the past 90 days. Once a refund is issued it can take up to 5-7 business days to process.')"
		@close="closeModal"
		action="Refund"
		@cta="refundPayment"
	>
		<div>
			<!-- Refundable Payments Table -->
			<div class="my-3">
				<AppTable>
					<thead>
						<tr>
							<th></th>
							<th>{{ $t('Order #') }}</th>
							<th>{{ $t('Date & Time') }}</th>
							<th>{{ $t('Order Type') }}</th>
							<th>{{ $t('Amount') }}</th>
						</tr>
					</thead>
					<tbody v-if="payments.length > 0">
						<tr v-for="(payment, index) in payments" v-bind:key="payment.payment_id" class="bg-white">
							<td>
								<label class="label-container d-flex align-items-center my-0">
									<input
										type="radio"
										:id="index"
										name="refundGroup"
										:checked="index === 0 ? 'checked' : null"
										:value="index"
										v-model="selectedRefundPaymentIndex"
									/>
									<span class="radio-btn"></span>
								</label>
							</td>
							<td class="bolded order-number">#{{ payment.orderNumber }}</td>
							<td>{{ payment.createdAt }}</td>
							<td>{{ payment.orderType }}</td>
							<td class="bolded amount">${{ payment.amount.toFixed(2) }}</td>
						</tr>
					</tbody>
				</AppTable>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import axios from 'axios'

	export default {
		props: {
			userFanId: {
				type: Number,
				required: true,
			},
		},
		data() {
			return {
				payments: [],
				selectedRefundPaymentIndex: null,
				selectedFanName: null,
			}
		},
		watch: {
			userFanId() {
				this.populatePayments()
			},
		},
		mounted() {
			this.populatePayments()
		},
		methods: {
			refundPayment() {
				this.closeModal()
				const paymentId = this.payments[this.selectedRefundPaymentIndex].orderNumber
				axios
					.post('v1/payments/refund', { payment_id: paymentId })
					.then(() => {
						this.$stanNotify({
							type: 'success',
							title: this.$t('Done!'),
							text: this.$t('This payment has been refunded.'),
						})
					})
					.catch(error => {
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							text: this.$t('Please contact us at friends@stanwith.me'),
						})
						this.$logError(error)
					})
			},
			populatePayments() {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				axios
					.get(`/v1/payments/fan/${this.userFanId}`, {
						params: {
							timezone,
						},
					})
					.then(response => {
						this.payments = response.data
						this.selectedRefundPaymentIndex = 0
						this.selectedFanName = this.payments[this.selectedRefundPaymentIndex]?.fullName
					})
			},
			closeModal() {
				$('#refund-payments-modal').modal('hide')
				this.$emit('close')
			},
		},
	}
</script>

<style lang="scss" scoped>
	td.bolded {
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 19px;
		/* identical to box height */

		letter-spacing: 0.04em;
		&.order-number {
			color: var(--stan-primary-primary-color);
		}
		&.amount {
			color: var(--stan-persian-green-2);
		}
	}

	// RADIO INPUT
	// hide default radio input
	/* Customize the label (the label-container) */
	/* The label-container */
	.label-container {
		display: flex;
		position: relative;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default radio button */
	.label-container input {
		position: absolute;
		left: 9px;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.radio-btn {
		position: relative;
		margin: auto;
		height: 26px;
		width: 26px;
		background-color: var(--stan-boulder-4);
		border-radius: 50%;
	}

	/* On mouse-over, add a grey background color */
	.label-container:hover input ~ .radio-btn {
		background-color: var(--stan-boulder-3);
	}

	/* When the radio button is checked, add a blue background */
	.label-container input:checked ~ .radio-btn {
		background-color: var(--stan-primary-primary-color);
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.radio-btn:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	.label-container input:checked ~ .radio-btn:after {
		display: block;
	}

	/* Style the indicator (dot/circle) */
	.label-container .radio-btn:after {
		top: 9px;
		left: 9px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: white;
	}
</style>
