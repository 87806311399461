var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upgrade-now", class: _vm.buttonPosition },
    [
      _vm.title
        ? _c("h3", { staticClass: "text-center text-bold mb-3" }, [
            _vm._v(_vm._s(_vm.$t(_vm.title)))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.buttonTitle
        ? _c(
            "AppButton",
            {
              staticClass: "align-items-center app-upgrade-button",
              attrs: {
                name: "upgrade-to-creator-pro",
                size: _vm.buttonSize,
                outline: _vm.buttonOutline
              },
              on: { click: _vm.changePlan }
            },
            [
              _c("img", {
                staticClass: "pr-1 lock-img",
                attrs: { src: "/images/premium-features/lock-alt.svg" }
              }),
              _vm._v(_vm._s(_vm.$t(_vm.buttonTitle)))
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }