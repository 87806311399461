var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("h1", { staticClass: "title mb-4" }, [_vm._v(_vm._s(_vm.questionText))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "option my-3" },
      [
        _vm.isSelect
          ? _c(
              "div",
              _vm._l(_vm.options, function(option) {
                return _c(
                  "div",
                  {
                    key: option.id,
                    on: {
                      click: function($event) {
                        return _vm.selectOption(option)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card",
                        class: { selected: _vm.isOptionSelected(option.dbMap) }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(option.displayName) +
                            "\n\t\t\t\t"
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isMultiSelect
          ? _c(
              "div",
              _vm._l(_vm.options, function(option) {
                return _c(
                  "div",
                  {
                    key: option.id,
                    staticClass: "card",
                    class: { selected: _vm.isOptionSelected(option.dbMap) },
                    on: {
                      click: function($event) {
                        return _vm.selectOption(option)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(option.displayName) + "\n\t\t\t"
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isText
          ? _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedValues[0],
                    expression: "selectedValues[0]"
                  }
                ],
                staticClass: "form-control mb-3",
                staticStyle: { height: "80px" },
                attrs: {
                  maxlength: "1000",
                  placeholder: _vm.options[0].displayName
                },
                domProps: { value: _vm.selectedValues[0] },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.selectedValues, 0, $event.target.value)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isOptionSelected("other")
          ? _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.otherInput,
                  expression: "otherInput"
                }
              ],
              staticClass: "form-control mb-3",
              attrs: { rows: "4", placeholder: "Type here..." },
              domProps: { value: _vm.otherInput },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.otherInput = $event.target.value
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.requiredError && _vm.requiredErrorMessage
          ? _c("small", { staticClass: "stan-text-danger error" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.requiredErrorMessage) + "\n\t\t")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.shouldShowNextButton
          ? _c(
              "AppButton",
              {
                staticClass: "w-100 mt-4",
                attrs: { name: "next-app-form-question", size: "lg" },
                on: { click: _vm.submit }
              },
              [_vm._v("Next")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }