var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: { id: "renameModule", title: "Module Name" },
      on: { close: _vm.closeModalResetData, open: _vm.createDataBackup }
    },
    [
      _c(
        "div",
        { staticClass: "form-group mb-4" },
        [
          _c("AppInput", {
            class: { "form-error": _vm.$v.newTitleValue.$invalid },
            attrs: {
              label: _vm.$t("Module Title"),
              placeholder: _vm.$t("Module Name"),
              id: "title",
              maxlength: "100"
            },
            model: {
              value: _vm.newTitleValue,
              callback: function($$v) {
                _vm.newTitleValue = $$v
              },
              expression: "newTitleValue"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap justify-content-end mt-3" },
        [
          _c(
            "AppButton",
            {
              staticClass: "py-2 md-flat ml-2 mt-1",
              staticStyle: { width: "100px" },
              attrs: { name: "save-rename-module-modal" },
              on: { click: _vm.updateModuleNameAndCloseModal }
            },
            [_vm._v(_vm._s(_vm.$t("Confirm")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }