var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative" },
    [
      _c(
        "div",
        {
          class: {
            "creator-pro-feature-not-allowed": !_vm.isAffiliateShareAllowed
          }
        },
        [
          _c("div", { staticClass: "affiliate-link-section" }, [
            _c("div", {
              staticClass: "mb-3 text-sm",
              domProps: { innerHTML: _vm._s(_vm.getHelperText()) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3 text-sm sub-title" }, [
              _vm._v(_vm._s(_vm.$t("Affiliate Commission %")))
            ]),
            _vm._v(" "),
            _vm.value
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.value, function(affiliate_share, i) {
                      return _c(
                        "div",
                        {
                          key: "affiliate_share-" + i,
                          staticClass: "affiliate-share-wrapper mb-5 mb-sm-2"
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: affiliate_share.status !== "deleted",
                                  expression:
                                    "affiliate_share.status !== 'deleted'"
                                }
                              ],
                              staticClass: "row align-items-center flex-nowrap"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-auto" },
                                [
                                  _c("AppInput", {
                                    staticClass:
                                      "mb-1 affiliate_share_percent_input",
                                    class: {
                                      "form-error":
                                        _vm.validation.$each[i].percent.$error
                                    },
                                    attrs: {
                                      id: "affiliate_share_percent_" + i,
                                      disabled:
                                        affiliate_share.status === "active",
                                      type: "number",
                                      placeholder: _vm.$t("ex. 15"),
                                      suffix: "%",
                                      min: _vm.percent_min,
                                      max: _vm.percent_max
                                    },
                                    model: {
                                      value: affiliate_share.percent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          affiliate_share,
                                          "percent",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "affiliate_share.percent"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "align-content-center al-link text-break"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "user-select-none text-truncate"
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.getAffiliateShareLink(
                                              affiliate_share
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.getAffiliateShareLink(affiliate_share) &&
                                  _vm.pageId > 0
                                    ? _c("InlineSvg", {
                                        staticClass: "copy-icon",
                                        attrs: {
                                          role: "button",
                                          src: "/images/icons/ic-copy.svg"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.copyAffiliateUrl(
                                              affiliate_share
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("AppDropdownMenu", {
                                staticClass: "pr-2",
                                attrs: {
                                  keepOpen: "",
                                  rightAlign: "",
                                  options: _vm.dropDownOptions(
                                    affiliate_share,
                                    _vm.pageId
                                  )
                                },
                                on: {
                                  selected: function($event) {
                                    return $event.handler(affiliate_share, i)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", [
                            !_vm.validation.$each[i].percent.between
                              ? _c(
                                  "small",
                                  { staticClass: "stan-text-danger error" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tPercentage must be between " +
                                        _vm._s(_vm.percent_min) +
                                        "% and " +
                                        _vm._s(_vm.percent_max) +
                                        "%\n\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            !_vm.validation.$each[i].percent.isUnique
                              ? _c(
                                  "small",
                                  { staticClass: "stan-text-danger error" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tPercentage must be unique\n\t\t\t\t\t\t"
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "AppButton",
                      {
                        staticClass: "w-100",
                        attrs: {
                          name: "add-affiliate-share-link",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("addAffiliateShare")
                          }
                        }
                      },
                      [
                        _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.$t("+ Add")) + " "),
                        _vm.value.length
                          ? _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("Another")) + " ")
                            ])
                          : _vm._e(),
                        _vm._v(
                          " " + _vm._s(_vm.$t("Affiliate Link")) + "\n\t\t\t\t"
                        )
                      ]
                    )
                  ],
                  2
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.isAffiliateShareAllowed
        ? _c("AppUpgrade", {
            attrs: { title: "Want to create affiliate links for your product?" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }