<template>
	<div class="row add-product-card flex-nowrap">
		<div class="col-auto">
			<div v-if="!iconLoaded" class="icon-placeholder-div" :style="`background-color: ${color} !important;`" />
			<img v-show="iconLoaded" :src="image" :alt="title" @load="handleImageLoad" />
		</div>
		<div class="col pl-1">
			<div class="d-flex flex-column justify-content-center h-100">
				<h5 class="header">{{ title }}</h5>
				<p class="description">{{ description }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['image', 'title', 'description', 'color'],
		data() {
			return {
				iconLoaded: false,
			}
		},
		methods: {
			handleImageLoad() {
				this.iconLoaded = true
			},
		},
	}
</script>
<style lang="scss" scoped>
	img {
		width: 77px;
		max-width: 77px;
		height: 77px;
		max-height: 77px;
		border-radius: 12px;
		object-fit: cover;
	}
	.add-product-card {
		cursor: pointer;
	}
	.header {
		color: var(--stan-primary-dark-color);
		font-style: normal;
		font-weight: bold;
		margin-bottom: 4px;
		font-size: 18px;
		letter-spacing: 0.04em;
		@media (min-width: 992px) and (max-width: 1199px) {
			font-size: 16px;
			line-height: 20px;
		}
	}
	.description {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: #48638c;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.icon-placeholder-div {
		min-width: 77px;
		min-height: 77px;
		border-radius: 12px;
	}
</style>
