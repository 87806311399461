var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("notifications", {
    style: _vm.notificationStyle,
    attrs: {
      position: _vm.notificationPosition,
      "animation-type": "css",
      "animation-name": "v-fade-top",
      width: "auto"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function(ref) {
          var item = ref.item
          var close = ref.close
          return [
            _c("StanNotification", {
              attrs: { data: item },
              on: { close: close }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }