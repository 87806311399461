<template>
	<div class="dropdown-wrapper" :class="{ disabled: disabled }">
		<label v-if="labelText" class="control-label">{{ labelText }}</label>
		<AppDropdownMenu
			class="app-dropdown-menu h-100"
			:class="{ small: small, disabled: disabled, border: border }"
			:options="optionsSubset"
			@selected="selectHandler"
			:imageWidth="imageWidth"
			:label="label"
			:rightAlign="rightAlign"
			:rounded="rounded"
			:roundedIcons="roundedIcons"
			:circleIcons="circleIcons"
			:manual-control="manualControl"
			:menuTriggerClass="menuTriggerClass"
			:dropdownRowComponent="dropdownRowComponent"
			:dropdownAutoClose="dropdownAutoClose"
			ref="app-dropdown-menu"
		>
			<div @click="toggle" class="d-flex align-items-center w-100 h-100 dropdown-toggle" :class="{ 'px-2 py-1': small }" :id="toggleId">
				<AppImage
					class="mr-2 d-flex"
					:class="{ rounded: roundedIcons }"
					v-if="selectedImage"
					:src="selectedImage"
					cover
					:width="imageWidth"
				></AppImage>
				<input
					ref="search-input"
					v-model="searchInput"
					:placeholder="placeholder"
					class="flex-grow-1"
					size="1"
					:readonly="!searchable"
					:class="{ 'cursor-pointer': !searchable }"
					:style="labelStyle"
				/>
			</div>
		</AppDropdownMenu>
	</div>
</template>

<script>
	import { UUID } from '~/store/modules/Global.js'

	export default {
		name: 'AppDropdown',
		props: {
			value: {
				type: [Object, String, Number],
			},
			label: {
				type: String,
				default: 'label',
			},
			labelText: {
				type: String,
				default: '',
			},
			options: Array,
			circleIcons: Boolean,
			roundedIcons: Boolean,
			imageWidth: {
				type: String,
				default: 'auto',
			},
			imageHeight: {
				type: String,
				default: 'auto',
			},
			placeholder: String,
			small: Boolean,
			reduce: null,
			searchable: {
				type: Boolean,
				default: true,
			},
			disabled: Boolean,
			border: {
				type: Boolean,
				default: true,
			},
			rightAlign: {
				type: Boolean,
				default: true,
			},
			rounded: {
				type: Boolean,
				default: false,
			},
			displayLabel: {
				type: String,
				default: undefined,
			},
			manualControl: {
				type: Boolean,
				default: false,
			},
			menuTriggerClass: {
				type: String,
				default: '',
			},
			dropdownRowComponent: {
				type: Object,
				default: null,
			},
			dropdownAutoClose: {
				type: Boolean,
				default: true,
			},
		},
		mounted() {
			this.lookupInput()
		},
		data() {
			return {
				searchInput: '',
				toggleId: `toggle-id-${UUID()}}`,
			}
		},
		methods: {
			lookupInput() {
				if (this.activeValueObject) {
					this.searchInput = this.activeValueObject[this.searchable ? this.label : this.displayLabel || this.label]
				} else {
					// If the activeValueObject switches to null, we want to clear the search input field
					this.searchInput = ''
				}
			},
			selectHandler(option) {
				const emitValue = this.reduce && option ? option[this.reduce] : option
				this.$emit('input', emitValue)
				if (option) {
					this.searchInput = option[this.searchable ? this.label : this.displayLabel || this.label]
					this.$emit('select', emitValue)
				}
				if ((this.manualControl || !this.dropdownAutoClose) && this.isExactMatch) {
					this.hide()
				}
			},
			toggle(e) {
				if (!this.searchable) {
					this.$refs['app-dropdown-menu'].toggle(e)
				}
			},
			show(e) {
				this.$refs['app-dropdown-menu'].show(e)
				if (this.searchable) {
					setTimeout(() => {
						this.$refs['search-input'].focus()
					}, 50)
				}
			},
			hide(e) {
				this.$refs['app-dropdown-menu']?.hide(e)
			},
		},
		computed: {
			activeValueObject() {
				if (this.value !== null && this.value !== undefined) {
					return this.reduce ? this.options.find(option => option[this.reduce] === this.value) : this.value
				}
				return null
			},
			textSearch() {
				return this.searchInput?.toLowerCase()
			},
			selectedLabel() {
				if (!this.value) return null
				return this.value[this.label]
			},
			selectedImage() {
				return this.activeValueObject?.image
			},
			isExactMatch() {
				return this.options.filter(option => option[this.label]?.toLowerCase() === this.textSearch).length > 0
			},
			optionsSubset() {
				let out = this.options
				// Return all options to show full dropdown on exactly match (option already selected)
				if (this.isExactMatch || !this.searchable) return out
				if (this.textSearch !== '') {
					out = this.options.filter(option => option[this.label]?.toLowerCase().indexOf(this.textSearch) !== -1)
				}
				return out
			},
			labelStyle() {
				return this.activeValueObject?.labelStyle
			},
		},
		watch: {
			value() {
				this.lookupInput()
			},
			textSearch() {
				if (!this.isExactMatch && this.searchable) {
					this.selectHandler(null)
				}
			},
			options() {
				// When this component is loaded before options are available
				this.lookupInput()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.app-dropdown-menu::v-deep {
		font-size: 0.875rem;
		.menu-content {
			font-size: inherit;
			width: 100%;
			max-height: 30vh;
			overflow: auto;
		}

		&.small {
			font-size: 90% !important;

			.dropdown-item {
				height: 30px;
			}
		}
		&.disabled {
			pointer-events: none;
		}
	}

	.border {
		border-radius: 5px;
	}

	input {
		border: none;
		height: 100%;
		color: var(--stan-text-dark-color);
		background-color: transparent;
		cursor: pointer;
		&:focus-visible {
			outline: none;
		}
		&:disabled {
			color: var(--stan-text-dark-color);
			-webkit-text-fill-color: var(--stan-text-dark-color);
			-webkit-opacity: 1;
			background-color: inherit;
		}
	}

	.dropdown-wrapper {
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
	}

	img.rounded {
		border-radius: 3px;
	}

	.form-control-padding {
		padding: 0.375rem 0.75rem;
	}
</style>
