var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "anchor" }),
    _vm._v(" "),
    _vm.requirePopupCloseForAction && _vm.renderPopup
      ? _c("div", { staticClass: "popup-cover-dom" })
      : _vm._e(),
    _vm._v(" "),
    _vm.renderPopup
      ? _c(
          "div",
          {
            ref: "popup-content",
            staticClass: "popup-menu",
            attrs: {
              "aria-labelledby": _vm.ariaLabelledby,
              id: "popup-content"
            }
          },
          [_vm._t("default")],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }