<!-- eslint-disable vue/component-name-in-template-casing -->
<template>
	<notifications :position="notificationPosition" :style="notificationStyle" animation-type="css" animation-name="v-fade-top" width="auto">
		<template slot="body" slot-scope="{ item, close }">
			<StanNotification :data="item" @close="close" />
		</template>
	</notifications>
</template>

<script>
	// import commonHelper from '~/global_helper/helpers.js'
	import StanNotification from './StanNotification'

	export default {
		components: {
			StanNotification,
		},
		computed: {
			notificationStyle() {
				return 'padding-top: 32px;'
			},
			notificationPosition() {
				return 'top center'
			},
		},
	}
</script>

<style lang="scss">
	.v-fade-top-enter-active,
	.v-fade-top-leave-active,
	.v-fade-top-move {
		transition: all 0.8s ease;
	}
	.v-fade-top-enter,
	.v-fade-top-leave-to {
		opacity: 0;
		transform: translateY(-200px) scale(0.75);
	}
	.vue-notification-group {
		left: 50%;
		transform: translateX(-50%);
		padding-top: 0px !important;
		margin-top: 32px !important;
	}
</style>
