var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "social-icons" },
    _vm._l(_vm.userSocialIcons, function(val, key) {
      return _c(
        "a",
        {
          directives: [
            { name: "show", rawName: "v-show", value: val, expression: "val" }
          ],
          key: "social-icon-" + key,
          staticClass: "social-icons__icon",
          attrs: {
            href: _vm.socialObjects[key].href
              ? _vm.socialObjects[key].href + _vm.userSocialIcons[key]
              : val,
            target: "_blank"
          }
        },
        [
          _c("svg", {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              alt: "social icon"
            },
            domProps: { innerHTML: _vm._s(_vm.socialObjects[key].image) }
          })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }