var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppModal", {
    attrs: {
      id: "grid-switch-warning-modal",
      title: "Heads up!",
      subheading:
        "Unfortunately, Email Magnets cannot yet be placed inside of Grid sections.",
      action: "OK"
    },
    on: { cta: _vm.closeModal, close: _vm.closeModal }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }