var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: {
        id: "cancel-booking-modal",
        action: "Yes, Cancel Appointment",
        title: "Just Confirming!",
        subheading: _vm.subheadingText,
        actionColor: "danger",
        "secondary-action": "Never Mind",
        secondaryActionColor: "light",
        loading: _vm.loading
      },
      on: {
        close: _vm.closeModal,
        cta: _vm.cancelBooking,
        "secondary-cta": _vm.closeModal
      }
    },
    [
      _vm.loading
        ? _c("div", [_c("div", { staticClass: "importing-animation" })])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }