var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mobile-container" }, [
    _c(
      "div",
      {
        staticClass: "body flex-column flex-grow-1 col-12 col-md-5 px-0 h-100"
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "row align-items-start justify-content-center d-md-flex pb-4 mb-2"
          },
          [
            _c(
              "div",
              {
                staticClass: "emoji p-1 cursor-pointer ",
                class: {
                  grayscale: _vm.satisfaction && _vm.satisfaction != "bad"
                },
                on: {
                  click: function($event) {
                    return _vm.selectSatisfaction("bad")
                  }
                }
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: { "font-size": "60px", "line-height": "60px" }
                  },
                  [_vm._v("🙁")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "emoji p-1 cursor-pointer ",
                class: {
                  grayscale: _vm.satisfaction && _vm.satisfaction != "okay"
                },
                on: {
                  click: function($event) {
                    return _vm.selectSatisfaction("okay")
                  }
                }
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: { "font-size": "60px", "line-height": "60px" }
                  },
                  [_vm._v("😐")]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "emoji p-1 cursor-pointer ",
                class: {
                  grayscale: _vm.satisfaction && _vm.satisfaction != "good"
                },
                on: {
                  click: function($event) {
                    return _vm.selectSatisfaction("good")
                  }
                }
              },
              [
                _c(
                  "h1",
                  {
                    staticStyle: { "font-size": "60px", "line-height": "60px" }
                  },
                  [_vm._v("🙂")]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "py-0 mb-3",
            staticStyle: { width: "min(395px, 100%)" }
          },
          [
            _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.feedback,
                    expression: "feedback"
                  }
                ],
                staticClass: "form-control para-2 styled-input",
                attrs: {
                  rows: "4",
                  name: "feedback",
                  placeholder: "Is there anything that stood out?"
                },
                domProps: { value: _vm.feedback },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.feedback = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.requiredError
              ? _c("small", { staticClass: "stan-text-danger error" }, [
                  _vm._v("\n\t\t\t\tPlease select a rating\n\t\t\t")
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c(
          "AppButton",
          {
            attrs: { name: "save-cancellation-flow-survey", size: "large" },
            on: { click: _vm.nextStep }
          },
          [_vm._v("Share your feedback")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h3", { staticClass: "modal-heading" }, [
        _vm._v("How's the cancellation process?")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }