<template>
	<div class="revenue-chart">
		<div class="text-label">
			<slot name="label"></slot>
		</div>
		<div class="text-number text-bold">
			<slot name="number"></slot>
		</div>
		<VueApexCharts width="100%" :type="type" :options="computedChartOptions" :series="series" :height="height" ref="apexChart" />
	</div>
</template>

<script>
	import VueApexCharts from 'vue-apexcharts'

	export default {
		components: {
			VueApexCharts,
		},
		props: {
			series: {
				type: Array,
				required: true,
			},
			customOptions: {
				type: Object,
				default: () => ({}),
			},
			chartPrefix: {
				type: String,
				default: '',
			},
			dataSuffix: {
				type: String,
				default: '',
			},
			decimals: {
				type: Number,
				default: 2,
			},
			height: {
				type: Number,
				default: 150,
			},
			color: {
				type: String,
				default: '#6355ff',
			},
			type: {
				type: String,
				default: 'area',
			},
			stacked: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {}
		},
		methods: {
			formatTooltip(val) {
				return val ? this.chartPrefix + val.toFixed(this.decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.dataSuffix : 0
			},
			generateHexColorOpacities(hex) {
				// Remove the '#' character from the hex color
				hex = hex.replace('#', '')

				// Create an array to hold the RGBA color strings with different opacities
				const colors = []

				// Loop through the array of opacities, converting each to an RGBA hex color string
				const opacities = [1, 0.85, 0.6, 0.45, 0.3]
				for (const opacity of opacities) {
					// Convert opacity from 0-1 to 0-255 and then to a hex string
					const alphaHex = Math.round(opacity * 255)
						.toString(16)
						.padStart(2, '0')
					const color = `#${hex}${alphaHex}`
					colors.push(color)
				}
				return colors
			},
		},
		computed: {
			options() {
				return {
					customVariables: {
						formatTooltip: this.formatTooltip,
					},
					colors: this.stacked ? this.generateHexColorOpacities(this.color) : [this.color],
					fill: {
						opacity: 1,
						type: this.type === 'area' ? 'gradient' : 'solid',
						gradient: {
							shade: 'light',
							type: 'vertical',
							shadeIntensity: 0.2,
							inverseColors: false,
							opacityFrom: 0.7,
							opacityTo: 0,
							stops: [0, 100],
							colorStops: [],
						},
						colors: this.stacked ? this.generateHexColorOpacities(this.color) : [this.color],
					},
					stroke: {
						show: this.type === 'area',
						width: 2,
						colors: [this.color],
					},
					dataLabels: {
						enabled: false,
					},

					chart: {
						stacked: this.stacked,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
					},
					plotOptions: {
						bar: {
							borderRadius: this.stacked ? 0 : 5,
						},
					},
					tooltip: {
						custom({ series, seriesIndex, dataPointIndex, w }) {
							const formatter = w.config.customVariables.formatTooltip
							return `
								<div class="tooltip-box d-flex flex-wrap align-items-center">
									<img style="height: 18px; margin-right: 5px;" src="https://assets.stanwith.me/logo/logo-icon.svg" /> 
									<span>${w.globals.seriesNames[seriesIndex]}: </span>
									<span>&nbsp<b>${formatter(series[seriesIndex][dataPointIndex])}</b></span>
								</div>`
						},
						y: {
							formatter: val =>
								val ? this.chartPrefix + val.toFixed(this.decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.dataSuffix : 0,
						},
					},
					legend: {
						show: false,
						horizontalAlign: 'left',
						markers: {
							radius: 50,
						},
						onItemClick: {
							toggleDataSeries: false,
						},
						formatter(val) {
							return val.length > 30 ? `${val.substr(0, 27)}...` : val
						},
					},
					xaxis: {
						crosshairs: {
							show: false,
						},
						tickPlacement: 'on',
						lines: {
							show: false,
						},
						labels: {
							rotate: 0,
							hideOverlappingLabels: true,
							formatter: val => `\u00A0${val}\u00A0\u00A0`,
							style: {
								cssClass: 'apexcharts-xaxis-label',
								colors: ['#808eb6'],
							},
						},
						tooltip: {
							enabled: false,
						},
						axisBorder: {
							show: false,
						},
						axisTicks: {
							show: false,
						},
					},
					yaxis: {
						opposite: this.yAxisOnRight,
						showForNullSeries: false,
						lines: {
							show: false,
						},
						labels: {
							rotate: 0,
							hideOverlappingLabels: true,
							formatter: val =>
								`${this.chartPrefix ?? ''}${val.toFixed(this.decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}\u00A0\u00A0`,
							style: {
								cssClass: 'apexcharts-xaxis-label',
								colors: ['#808eb6'],
							},
						},
						tickAmount: 2,
						tooltip: {
							formatter: val => `CAD$\u00A0${val.toFixed(this.decimals)}\u00A0\u00A0`,
						},
					},
					grid: {
						show: false,
					},
				}
			},
			computedChartOptions() {
				return this.$lodash.merge(this.options, this.customOptions)
			},
		},
	}
</script>

<!--Cannot be scoped-->
<style lang="scss">
	.tooltip-box {
		padding: 10px;
	}
</style>

<style lang="scss" scoped>
	.text-number {
		font-family: 'Inter';
		font-style: normal;
		padding-left: 20px;
		font-weight: 700;
		font-size: 60px;
		line-height: 100%;
		/* identical to box height, or 60px */

		letter-spacing: -0.01em;

		@media (max-width: 1280px) {
			font-size: 40px;
			line-height: 44px;
		}

		@media (max-width: 768px) {
			font-size: 32px;
			line-height: 36px;
		}
	}
	.right-border-separator {
		border-right: 1px solid #808eb6;
	}
	.text-label {
		color: var(--stan-text-light-color);
		padding-left: 20px;
		margin-bottom: 8px;
	}
</style>
