var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: {
        title: _vm.$t("Go ahead, we’re listening! 🎤"),
        id: "modal-product-feedback",
        action: "Submit"
      },
      on: { cta: _vm.saveFeedback, close: _vm.closeModal }
    },
    [
      _c("div", { staticClass: "w-full mx-auto py-0" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.feedback,
              expression: "feedback"
            }
          ],
          staticClass: "form-control styled-input",
          attrs: {
            rows: "3",
            name: "feedback",
            placeholder: _vm.$t(
              "What can we do to make your experience better?"
            )
          },
          domProps: { value: _vm.feedback },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.feedback = $event.target.value
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }