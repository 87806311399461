var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      ref: "exportCsvModal",
      attrs: {
        title: _vm.modalTitle,
        staticDataBackdrop: false,
        subheading: _vm.excelFilename,
        "hide-close": true,
        action: "Finish"
      },
      on: { close: _vm.closeModal, cta: _vm.finishDownload }
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "importing-animation" },
            [
              _c("InlineSvg", { attrs: { src: "/images/icons/ic-box.svg" } }),
              _vm._v(" "),
              _c("InlineSvg", { attrs: { src: "/images/icons/ic-box.svg" } }),
              _vm._v(" "),
              _c("InlineSvg", { attrs: { src: "/images/icons/ic-box.svg" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("AppProgress", {
            attrs: {
              min: 0,
              max: _vm.totalExportPages,
              value: _vm.exportingPage
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }