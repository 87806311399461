<template>
	<AppModal
		:title="header"
		:id="id"
		action="Submit"
		@cta="refundOrder"
		:loading="loading"
		:disableAction="insufficientBalanceForRefund"
		:footerNote="footerNote"
		@open="recordAnalytics"
	>
		<div v-if="insufficientBalanceForRefund" class="d-flex flex-column gap-15px">
			<div class="w-100 d-flex text-light justify-content-between align-items-center">
				<span class="para-2 text-light"
					>Uh oh! It looks like your account balance is too low to process this refund. Please reach out to
					<a href="mailto:friends@stanwith.me" class="mailto">friends@stanwith.me</a> for support.</span
				>
			</div>
		</div>
		<div class="d-flex flex-column gap-15px refund-modal-body">
			<div v-if="!insufficientBalanceForRefund" class="d-flex flex-column gap-20px">
				<!-- Header Total -->
				<div class="header-total-container">
					<div class="d-flex flex-column">
						<div class="d-flex flex-row gap-2px">
							<div class="para-2 text-light text-bold">Your Customer Will Receive</div>
							<AppTooltip
								class="d-flex align-items-center"
								click
								tip="<a href='https://help.stan.store/article/43-limit-quantity-discount-codes' target='_blank'>Learn more</a>"
								:html="true"
							>
								<InlineSvg src="./images/income/help.svg" style="height: 12px" />
							</AppTooltip>
						</div>

						<div class="para-4 text-light text-italic">In approximately 5-10 business days</div>
					</div>

					<h2 style="font-weight: 600">{{ orderRefundData.grossAmount }}</h2>
				</div>

				<div v-if="paymentGateway !== 'paypal'" class="d-flex flex-column gap-20px">
					<div class="w-100" style="height: 1px; background-color: var(--stan-gray-strike-color)" />

					<div class="para-2 text-light text-bold">Refund Breakdown</div>

					<div class="d-flex flex-column gap-8px">
						<CashOutTimeLineDateRow :amount="orderRefundData.netRevenue" title="Your Net Revenue" />
					</div>

					<div class="d-flex flex-column gap-8px">
						<CashOutTimeLineDateRow :amount="`+${orderRefundData.processingFeeAmount}`" :title="orderRefundData.refundFeeLabel" />
					</div>
				</div>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex'
	import axios from 'axios'

	import helpers from '~/global_helper/helpers.js'

	import CashOutTimeLineDateRow from '~/pages/income/components/CashOutContainer/CashOutTimelineModal/CashOutTimeLineDateRow'

	export default {
		components: {
			CashOutTimeLineDateRow,
		},
		props: {
			orderId: {
				type: Number,
			},
			orderAmount: {
				type: Number,
			},
			orderRefundData: {
				type: Object,
				default: () => ({
					processingFeeAmount: '',
					refundFeeLabel: '',
					netRevenue: '',
					grossAmount: '',
				}),
			},
			paymentGateway: {
				type: String,
			},
			id: {
				type: String,
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
			...mapGetters('Transactions', ['balance']),
			netBalanceAfterRefund() {
				const difference = this.balance?.stripe_available?.amount + this.balance?.stripe_pending?.amount - this.orderAmount
				return difference
			},
			insufficientBalanceForRefund() {
				if (this.paymentGateway === 'paypal') {
					return false
				}
				return this.netBalanceAfterRefund < this.$constants.MINIMUM_ALLOWED_BALANCE
			},
			footerNote() {
				if (this.paymentGateway === 'paypal' || this.insufficientBalanceForRefund) {
					return ''
				}
				return 'Refunds will be withdrawn from your Account Balance. Conversion rates may apply.'
			},
			adjustmentAmount() {
				return this.netBalanceAfterRefund < 0
					? helpers.formatCurrency(Math.ceil((this.netBalanceAfterRefund * -1) / 5) * 5, this.balance.stripe_available?.currency ?? 'USD')
					: helpers.formatCurrency(0, this.balance.stripe_available?.currency ?? 'USD')
			},
			header() {
				return this.insufficientBalanceForRefund ? 'Insufficient Funds Available' : 'Process a Refund'
			},
		},
		mounted() {
			if (!this.balance.stripe_available) {
				this.fetchBalance()
			}
		},
		data() {
			return {
				loading: false,
			}
		},
		methods: {
			...mapActions('Transactions', ['fetchBalance']),
			recordAnalytics() {
				this.$stanAnalytics('refund-modal-opened', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: {
						payment_id: this.orderId,
						insufficient_balance: this.insufficientBalanceForRefund,
						paymentGateway: this.paymentGateway,
					},
				})
			},
			refundOrder() {
				this.loading = true
				const payload = {
					payment_id: this.orderId,
				}

				axios
					.post('v1/payments/refund', payload)
					.then(() => {
						this.$stanNotify({
							type: 'success', // can be 'info', 'warning', 'success', 'error'
							title: this.$t('Transaction Refund'),
							text: this.$t('This transaction has been successfully refunded.'),
							duration: 2000,
						})
						this.$emit('refunded')
						this.fetchBalance()
					})
					.catch(error => {
						this.$logError(error)
						this.$stanNotify({
							type: 'error', // can be 'info', 'warning', 'success', 'error'
							title: this.$t('Transaction Refund'),
							text: error.response.data.message || this.$t('Something went wrong. Please contact our support.'),
							duration: 5000,
						})
					})
					.finally(() => {
						this.loading = false
						$('#refund-modal').modal('hide')
					})
			},
		},
	}
</script>
<style lang="scss">
	h2 {
		font-family: 'Inter';
	}
	.ic-primary {
		path,
		rect {
			fill: var(--stan-primary-primary-color) !important;
		}
	}
	.icon {
		svg {
			height: 20px;
			width: 20px;
		}
		line-height: 140%;
	}

	.refund-modal-body {
		margin-top: 25px;

		@media (max-width: 575px) {
			margin-top: 0px;
		}
	}
	.header-total-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 575px) {
			flex-direction: column;
			gap: 20px;
			text-align: center;
		}
	}
</style>
