var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.modalTitle,
        subheading: _vm.modalSubheading,
        "aria-labelledby": "audienceBulkTaggingModal",
        overflow: true,
        loading: _vm.loading,
        action: _vm.buttonText,
        "secondary-action": "Cancel"
      },
      on: { cta: _vm.submitModalForm, "secondary-cta": _vm.hide }
    },
    [
      _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.loading
          ? _c(
              "div",
              { key: "loading" },
              [
                _c(
                  "div",
                  { staticClass: "importing-animation" },
                  [
                    _c("InlineSvg", {
                      attrs: { src: "/images/icons/ic-customer.svg" }
                    }),
                    _vm._v(" "),
                    _c("InlineSvg", {
                      attrs: { src: "/images/icons/ic-customer.svg" }
                    }),
                    _vm._v(" "),
                    _c("InlineSvg", {
                      attrs: { src: "/images/icons/ic-customer.svg" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("AppProgress", {
                  attrs: {
                    min: 0,
                    max: _vm.totalCustomers,
                    value: _vm.processedCount
                  }
                })
              ],
              1
            )
          : _c("div", { key: "content" }, [
              _c("form", { attrs: { id: "audience_bulk_tagging_form" } }, [
                _c(
                  "div",
                  {
                    staticClass: "overflow-visible d-flex flex-column gap-4px"
                  },
                  [
                    _c("AppNewMultiSelect", {
                      attrs: {
                        placeholder: "Select a tag",
                        items: _vm.availableFanTags,
                        addNewItemPrefixCopy:
                          "+ Press Enter to create a new tag",
                        canAddNewItem: this.mode !== "delete",
                        singleItemSelect: true,
                        characterLimit: 50,
                        selectedItems: _vm.audienceTag,
                        label: "name",
                        trackBy: "name"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.formErrors && _vm.formErrors.audienceTag,
                            expression: "formErrors && formErrors.audienceTag"
                          }
                        ],
                        staticClass: "para-3 text-danger mb-2"
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.formErrors.audienceTag) +
                            "\n\t\t\t\t\t"
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }