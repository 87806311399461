var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "d-flex justify-content-start align-items-center",
        attrs: { id: "lesson-back-button" },
        on: { click: _vm.reset }
      },
      [
        _c("img", {
          staticClass: "pr-2 cursor-pointer",
          attrs: { src: "/images/back-arrow-chev.svg", alt: "" }
        }),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.$t("Course Home")))])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "editor-wrapper" },
      [
        _c(
          "AppSection",
          {
            attrs: {
              number: "1",
              title: "Page Description",
              subtext: "Start by giving your course a title.",
              "subtext-placement": "bottom"
            }
          },
          [
            _c("div", { staticClass: "offset-section" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("div", { staticClass: "col px-0" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("Image")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("FileDropZone", {
                    staticClass: "py-0 mb-4 new",
                    attrs: {
                      image: _vm.imageUrl,
                      "drop-zone-class": "header-image-drop-zone",
                      "data-test-id": "upload-header-image-button",
                      accept: "image",
                      "crop-aspect-ratio": 16 / 9,
                      "expected-dimension": "1920 x 1080"
                    },
                    on: {
                      "start-upload": function($event) {
                        return _vm.uploadStarted()
                      },
                      "uploaded-all": function($event) {
                        return _vm.uploadCompleted()
                      },
                      uploaded: _vm.setImage,
                      "delete-file": _vm.resetFile,
                      "cancel-file": _vm.resetFile
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group mb-3" },
                [
                  _c("AppInput", {
                    ref: "input-title",
                    attrs: {
                      label: _vm.$t("Title"),
                      placeholder: _vm.$t("Title"),
                      maxlength: "100"
                    },
                    on: { change: _vm.$v.title.$touch },
                    model: {
                      value: _vm.title,
                      callback: function($$v) {
                        _vm.title = $$v
                      },
                      expression: "title"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$v.title.$error,
                          expression: "$v.title.$error"
                        }
                      ],
                      staticClass:
                        "form-error-text para-3 stan-text-danger mb-3 mt-2"
                    },
                    [
                      _c("InlineSvg", {
                        staticClass: "error-icon",
                        attrs: { src: "/images/icons/ic-warning-circle.svg" }
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("Please provide a title")) +
                          "\n\t\t\t\t\t"
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group mb-3" },
                [
                  _c("div", { staticClass: "col px-0" }, [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v(_vm._s(_vm.$t("Description")))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("TipTapEditor", {
                    key: _vm.descriptionEditorCounter,
                    staticClass: "tiptap-editor flexible",
                    on: { focusout: _vm.$v.description.$touch },
                    model: {
                      value: _vm.description,
                      callback: function($$v) {
                        _vm.description = $$v
                      },
                      expression: "description"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$v.description.$error,
                          expression: "$v.description.$error"
                        }
                      ],
                      staticClass:
                        "form-error-text para-3 stan-text-danger mb-3 mt-2"
                    },
                    [
                      _c("InlineSvg", {
                        staticClass: "error-icon",
                        attrs: { src: "/images/icons/ic-warning-circle.svg" }
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("Please provide a description")) +
                          "\n\t\t\t\t\t"
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "AppSection",
          {
            attrs: {
              number: "2",
              title: "Customize Branding",
              subtext:
                "Your store’s branding is automatically applied to your course, but you can create course-specific branding here.",
              "subtext-placement": "bottom"
            }
          },
          [
            _c("div", { staticClass: "offset-section" }, [
              _c(
                "div",
                { staticClass: "form-group mb-3" },
                [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v(_vm._s(_vm.$t("Title Font")))
                  ]),
                  _vm._v(" "),
                  _c("AppDropdown", {
                    staticClass: "form-control",
                    attrs: {
                      options: _vm.fontOptions,
                      clearable: false,
                      searchable: false
                    },
                    model: {
                      value: _vm.chosenTitleFont,
                      callback: function($$v) {
                        _vm.chosenTitleFont = $$v
                      },
                      expression: "chosenTitleFont"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "color-inputs" },
                [
                  _c("ColorInput", {
                    attrs: {
                      label: "Background",
                      "color-suggestions": _vm.backgroundColors
                    },
                    model: {
                      value: _vm.backgroundColor,
                      callback: function($$v) {
                        _vm.backgroundColor = $$v
                      },
                      expression: "backgroundColor"
                    }
                  }),
                  _vm._v(" "),
                  _c("ColorInput", {
                    attrs: {
                      label: "Highlight",
                      "color-suggestions": _vm.highlightColors
                    },
                    model: {
                      value: _vm.highlightColor,
                      callback: function($$v) {
                        _vm.highlightColor = $$v
                      },
                      expression: "highlightColor"
                    }
                  })
                ],
                1
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-row w-100 justify-content-end" },
          [
            _c(
              "AppButton",
              {
                staticClass: "mb-2 ml-2",
                attrs: {
                  name: "course-homepage-save-button",
                  outline: "",
                  disabled: _vm.isUploading
                },
                on: { click: _vm.reset }
              },
              [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "AppButton",
              {
                staticClass: "mb-2 ml-2",
                attrs: {
                  name: "course-homepage-save-button",
                  disabled: _vm.isUploading,
                  loading: _vm.loading
                },
                on: { click: _vm.savePage }
              },
              [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }