var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showEmailFlowBanner
        ? _c("AppBanner", {
            staticClass: "mb-0",
            attrs: {
              title: "Add an Email Flow",
              subtitle:
                "Send automatic emails to your customers after they purchase this product!",
              icon: "/images/icons/ic-send-email.svg",
              buttons: [
                {
                  name: "Add Flow",
                  icon: "/images/icons/ic-plus.svg",
                  click: function() {
                    return _vm.$emit("add-flow")
                  },
                  color: "primary"
                }
              ]
            }
          })
        : _c(
            "div",
            { staticClass: "flows" },
            [
              _vm._l(_vm.relatedFlows, function(flow, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "flow-item para-2 text-bold",
                    on: {
                      click: function($event) {
                        return _vm.$emit("edit-flow", flow.flow_id)
                      }
                    }
                  },
                  [
                    _c("InlineSvg", {
                      staticClass: "flow-icon",
                      attrs: { src: "/images/icons/ic-flows.svg" }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "flow-name" }, [
                      _vm._v(_vm._s(flow.data.name))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flow-action " },
                      [
                        _c("InlineSvg", {
                          attrs: { src: "/images/icons/ic-edit.svg" }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Edit")])
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "AppButton",
                {
                  attrs: {
                    name: "add-flow-email-flow-form",
                    icon: "/images/icons/ic-plus.svg",
                    outline: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-flow")
                    }
                  }
                },
                [_vm._v("Add Flow")]
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }