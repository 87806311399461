var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentPlaceholders",
    { attrs: { rounded: _vm.rounded } },
    [
      _vm.heading
        ? _c("ContentPlaceholdersHeading", { attrs: { img: _vm.img } })
        : _vm._e(),
      _vm._v(" "),
      _c("ContentPlaceholdersText", { attrs: { lines: _vm.lines } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }