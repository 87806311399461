<template>
	<div class="app-tabs" :class="[{ 'has-content': showContent }]">
		<ul class="nav pl-3 pl-sm-0 pr-3 flex-nowrap" :id="id" role="tablist" :class="[sizeClass]">
			<li
				v-for="item in items"
				class="nav-item"
				:class="{ 'nav-item-error': item.error }"
				role="presentation"
				:key="`${item.id}-tab`"
				:id="`${item.id}-tab`"
			>
				<a
					class="nav-link h4 text-bold"
					:class="{ active: selectedTab === item.id }"
					data-toggle="tab"
					:href="`${item.id}`"
					role="tab"
					:aria-controls="`${item.id}`"
					aria-selected="true"
					@click="onTabSelected(item.id)"
				>
					<template v-if="item.icon">
						<inline-svg v-if="loadSvg" :src="item.icon" class="tab-icon mr-2" />
						<img v-else :src="item.icon" class="tab-icon mr-2" />
					</template>
					<span class="text-truncate">{{ item.title }}</span>
				</a>
			</li>
		</ul>
		<div v-if="showContent" class="tab-content" :id="`${id}Content`">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import commonHelper from '~/global_helper/helpers.js'

	export default {
		name: 'AppTabs',
		props: {
			id: {
				type: String,
				require: true,
				default: '',
			},
			items: {
				type: Array,
				require: true,
				default: () => [],
			},
			activeTab: {
				type: String,
				default: undefined,
			},
			loadSvg: {
				type: Boolean,
				default: true,
			},
			showContent: {
				type: Boolean,
				default: false,
			},
			size: {
				type: String,
				default: 'medium',
			},
		},
		emits: ['initialised', 'tabSelected'],
		data() {
			return {
				selectedTab: null,
			}
		},
		computed: {
			sizeClass() {
				switch (this.size) {
					case 'small':
						return 'tabs-small'
					case 'medium':
						return 'tabs-medium'
					case 'large':
						return 'tabs-large'
					default:
						return 'tabs-medium'
				}
			},
		},
		mounted() {
			if (this.activeTab) this.onTabSelected(this.activeTab)
			this.$emit('initialised')
		},
		methods: {
			isMobile() {
				return commonHelper.isMobile()
			},
			onTabSelected(tabId) {
				this.selectedTab = tabId
				this.$emit('tabSelected', tabId)
				if (this.isMobile()) {
					const container = document.getElementById(`${tabId}-tab`)
					container.scrollIntoView({
						behavior: 'smooth', // or "auto" or "instant"
						block: 'nearest', // or "end"
						inline: 'nearest',
					})
				}
			},
		},
		watch: {
			activeTab(newVal) {
				this.selectedTab = newVal
			},
		},
	}
</script>

<style lang="scss" scoped>
	.app-tabs {
		border-bottom: none;
		overflow-x: scroll;
		overflow-y: hidden;

		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		&::-webkit-scrollbar {
			display: none;
		}
		.nav-item {
			min-width: auto;
			font-weight: 400;
			font-size: 0.8rem;
			line-height: 1;
			a.nav-link {
				padding: 0.5rem 1rem;
				border-radius: 30px;
				border: 1.5px solid;
				border-color: transparent;
				display: flex;
				align-items: center;
				color: var(--stan-text-dark-color);

				&::before {
					content: none;
				}
				.tab-icon::v-deep {
					width: 20px;
					height: 20px;
					rect:not(.no-fill),
					path:not(.no-fill) {
						fill: var(--stan-text-dark-color);
					}
				}
				&:hover,
				&.active {
					color: var(--stan-primary-primary-color);
					border-color: var(--stan-primary-primary-color);
					.tab-icon::v-deep {
						rect:not(.no-fill),
						path:not(.no-fill) {
							fill: var(--stan-primary-primary-color);
						}
					}
				}
				&:not(.active):hover {
					border-color: transparent;
				}
			}

			&.nav-item-error {
				a.nav-link {
					color: var(--stan-danger);

					&::before {
						content: none;
					}
					.tab-icon::v-deep {
						rect:not(.no-fill),
						path:not(.no-fill) {
							fill: var(--stan-danger);
						}
					}
					&:hover,
					&.active {
						color: var(--stan-danger);
						border-color: var(--stan-danger);
						.tab-icon::v-deep {
							rect:not(.no-fill),
							path:not(.no-fill) {
								fill: var(--stan-danger);
							}
						}
					}
				}
			}
		}
	}
</style>
