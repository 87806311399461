var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "control-funnel-page-wraper" },
    [
      _c("div", { staticClass: "d-flex flex-wrap stan-feedback my-3" }, [
        _c(
          "a",
          {
            staticClass: "cursor-pointer ml-auto",
            on: { click: _vm.showModalProductFeedback }
          },
          [_vm._v(_vm._s(_vm.$t("Improve this page")))]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-wrap justify-content-between mobile-center-btn-align"
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              !_vm.isNewPage
                ? _c(
                    "AppButton",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        name: "delete-page-button",
                        color: "gray",
                        outline: "",
                        disabled: _vm.publishing,
                        "data-test-id": "delete-button",
                        icon: "/images/icons/ic-trash.svg"
                      },
                      on: { click: _vm.showModalDeletePage }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.$t("Delete")) + "\n\t\t\t"
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "AppButton",
                {
                  staticClass: "mb-2",
                  attrs: {
                    name: "save-draft-button",
                    outline: "",
                    icon: "/images/icons/ic-save.svg",
                    disabled: _vm.isDisabled,
                    "data-test-id": "save-draft-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("save-draft")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t(_vm.saveDraftButtonText)) +
                      "\n\t\t\t"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "AppButton",
                {
                  staticClass: "mb-2 ml-2",
                  attrs: {
                    name: "publish-page-button",
                    disabled: _vm.isDisabled,
                    loading: _vm.publishing,
                    "data-test-id": "save-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("save-page")
                    }
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t(_vm.saveButtonText)) +
                      "\n\t\t\t"
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("ModalDeletePage", {
        attrs: { type: _vm.variantToUse },
        on: {
          "delete-confirm": function($event) {
            return _vm.$emit("delete-page")
          },
          "close-modal": _vm.closeDeleteModal
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }