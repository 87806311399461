var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-container" }, [
    _c(
      "div",
      { staticClass: "course-sidebar", class: { show: _vm.showCoursesList } },
      [
        _vm.course.data.title
          ? _c("div", { staticClass: "title-banner-container" }, [
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.course.data.title))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "progress-bar-container" }, [
          _c("div", { staticStyle: { position: "relative" } }, [
            _c("div", {
              staticClass: "user-progress-bar",
              style: {
                width: "0%"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "progress-bar-background" })
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "para-3 font-semibold",
              staticStyle: { color: "var(--stan-store-custom-highlight-color)" }
            },
            [_vm._v("0% complete")]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-column list-container lesson-container" },
          [
            _c(
              "div",
              {
                staticClass: "home-button",
                on: {
                  click: function($event) {
                    return _vm.handleChooseHome()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "course-home-container" },
                  [
                    _c("InlineSvg", {
                      staticClass: "burger-menu-icon",
                      staticStyle: { fill: "white !important" },
                      attrs: { src: "/images/preview-icons/home.svg" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "para-3 font-semibold" }, [
                  _vm._v("Course Home")
                ])
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.filteredModuleList, function(module, index) {
              return _c("ModuleLessonsContainer", {
                key: module.module_id,
                attrs: {
                  moduleNumber: index + 1,
                  module: module,
                  activeLesson: _vm.activeLesson
                },
                on: { chooseLesson: _vm.chooseLesson }
              })
            })
          ],
          2
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "course-sidebar-mobile-trigger",
        on: { click: _vm.handleShowCoursesList }
      },
      [
        _c("InlineSvg", {
          staticClass: "burger-menu-icon",
          attrs: { src: "/images/preview-icons/menu-burger.svg" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "para-1 text-bold font-ellipsis" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.course.data.title) + "\n\t\t")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }