var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: {
        id: "delete-customer-modal",
        action: "Delete Customer",
        "secondary-action": "Keep them!",
        title: "Are you sure?",
        actionColor: "danger",
        loading: _vm.loading,
        secondaryActionColor: "light"
      },
      on: {
        close: _vm.closeModal,
        "secondary-cta": _vm.closeModal,
        cta: _vm.deleteCustomer
      }
    },
    [
      _c("div", { staticClass: "modal-subheading para-2" }, [
        _vm._v(_vm._s(this.bodyText))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }