<template>
	<content-placeholders :rounded="rounded" :centered="true">
		<content-placeholders-heading v-if="heading" />
		<content-placeholders-img class="calendar-skeleton-image" />
	</content-placeholders>
</template>

<script>
	export default {
		props: {
			rounded: { type: Boolean, default: false },
			heading: { type: Boolean, default: true },
		},
	}
</script>
<style lang="scss">
	.vue-content-placeholders-is-animated::v-deep .vue-content-placeholders-img::before {
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
	}
</style>
