<template>
	<div class="upgrade-now" :class="buttonPosition">
		<h3 v-if="title" class="text-center text-bold mb-3">{{ $t(title) }}</h3>
		<AppButton
			name="upgrade-to-creator-pro"
			class="align-items-center app-upgrade-button"
			v-if="buttonTitle"
			:size="buttonSize"
			:outline="buttonOutline"
			@click="changePlan"
		>
			<img class="pr-1 lock-img" src="/images/premium-features/lock-alt.svg" />{{ $t(buttonTitle) }}</AppButton
		>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'

	export default {
		name: 'AppUpgrade',
		props: {
			title: {
				type: String,
			},
			buttonSize: {
				type: String,
				default: 'md',
			},
			buttonOutline: {
				type: Boolean,
				default: true,
			},
			buttonTitle: {
				type: String,
				default: 'Upgrade Now',
			},
			buttonPosition: {
				type: String,
				default: '',
			},
		},
		computed: {
			...mapGetters('Auth', ['hasActiveSubscription', 'user']),
		},
		methods: {
			changePlan() {
				if (!this.hasActiveSubscription) {
					this.$router.push({ name: 'subscribe' })
				} else {
					$('#change-plan-modal').modal('show')
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.upgrade-now {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		justify-items: center;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.5);
		backdrop-filter: blur(3px);
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		min-height: 110px;
	}
	.top-right {
		bottom: unset;
		left: unset;
		flex-direction: row;
		margin: 15px;
		min-height: unset;
	}
	.lock-img {
		height: 23px;
	}
	.app-upgrade-button {
		font-weight: 600;
	}
</style>
