var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "legend" },
    _vm._l(_vm.labels, function(label, index) {
      return _c("div", { key: index, staticClass: "legend-item" }, [
        _c("div", {
          staticClass: "legend-color",
          style: { backgroundColor: _vm.colors[index] }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "legend-label-and-value" }, [
          _c(
            "label",
            {
              staticClass: "key",
              style: "max-width: " + _vm.titleCharacterLimit + "ch"
            },
            [_vm._v(_vm._s(label))]
          ),
          _vm._v(" "),
          _c("label", { staticClass: "value" }, [
            _c("b", [_vm._v(_vm._s(_vm.series[index]))])
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }