var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tip
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-row tip-container align-items-top p-3 mb-2"
        },
        [
          _c("div", { staticClass: "icon-container" }, [
            _c("img", {
              staticClass: "tip-icon",
              class: { small: _vm.tip.smallIcon },
              attrs: { src: _vm.iconSrc }
            })
          ]),
          _vm._v(" "),
          !_vm.isTemplate
            ? _c(
                "div",
                { staticClass: "body-container d-flex flex-column mx-2" },
                [
                  _vm.tip.title
                    ? _c("div", { staticClass: "body-title" }, [
                        _vm._v(_vm._s(_vm.tip.title))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-nowrap mt-1",
                      class: _vm.bodyDescriptionClass
                    },
                    [
                      _c("div", { staticClass: "body-text" }, [
                        _vm.tip.description
                          ? _c("span", {
                              staticClass: "mr-1",
                              domProps: {
                                innerHTML: _vm._s(_vm.tip.description)
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tip.actionText
                          ? _c(
                              "span",
                              {
                                staticClass: "body-action",
                                on: { click: _vm.doAction }
                              },
                              [_vm._v(_vm._s(_vm.tip.actionText))]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            : _c(
                "div",
                { staticClass: "body-container d-flex flex-column mx-2" },
                [
                  _c(
                    "div",
                    { staticClass: "body-title" },
                    [_vm._t("title")],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-nowrap mt-1",
                      class: _vm.bodyDescriptionClass
                    },
                    [
                      _c("div", { staticClass: "body-text" }, [
                        _c(
                          "span",
                          { staticClass: "mr-1" },
                          [_vm._t("description")],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "body-action",
                            on: { click: _vm.doAction }
                          },
                          [_vm._t("actionText")],
                          2
                        )
                      ])
                    ]
                  )
                ]
              ),
          _vm._v(" "),
          _vm.tip.videoPreviewUrl
            ? _c(
                "div",
                {
                  staticClass: "video-container px-1",
                  on: { click: _vm.playVideo }
                },
                [
                  _c("img", { attrs: { src: _vm.tip.videoPreviewUrl } }),
                  _vm._v(" "),
                  _vm._m(0)
                ]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-overlay" }, [
      _c("img", { attrs: { src: "/images/learning/video-play-icon.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }