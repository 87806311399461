<template>
	<div class="module-form" :key="tick">
		<div class="form-group mb-3" v-if="validation.data.title.$error" :class="{ 'form-error': validation.data.title.$error }"></div>
		<Draggable
			v-model="lessons"
			class="lessons-wrapper"
			v-bind="{ animation: 0, ghostClass: 'ghost' }"
			:options="{ delay: 100, delayOnTouchOnly: true, group: 'lessons' }"
			draggable=".lesson-card"
			handle=".lesson-card"
			@change="onLessonsMoved"
		>
			<div
				class="lesson-card cursor-pointer"
				v-for="(lesson, i) in lessons"
				:key="`lesson-${lesson.lesson_id}`"
				:name="`lesson-${lesson.lesson_id}`"
				@click="selectLesson(i)"
			>
				<div class="lesson-icon">
					<InlineSvg src="/images/six-dots.svg" alt="" style="height:18px" />
				</div>
				<div class="text-truncate col pl-0">
					{{ lesson.data.title || `Lesson ${i + 1}` }}
				</div>
				<div class="col-auto d-flex align-items-center">
					<div class="pr-2" v-if="lesson.status === 2">
						<AppTag label="Draft" :backgroundColor="`rgba(143, 156, 185, 0.2)`" :textColor="`#8F9CB9`" image="/images/draft.svg" />
					</div>
					<div>
						<InlineSvg src="/images/back-arrow.svg" class="forward-arrow" />
					</div>
				</div>
			</div>
		</Draggable>
		<div class="px-3">
			<AppButton name="add-lesson-course-module-form" class="w-100 p-0" outline @click="addLesson(true)">
				{{ $t('+ Add Lesson') }}
			</AppButton>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'ModuleForm',
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			index: {
				type: Number,
				require: true,
			},
			tick: {
				type: Number,
				default: 0,
			},
			defaultLessonDescription: {
				type: String,
			},
		},
		computed: {
			lessons: {
				get() {
					let order = 1
					return this.value.lessons
						.filter(lesson => lesson.status !== 0)
						.map(lesson => {
							lesson.order = order
							order++
							return lesson
						})
						.sort((a, b) => a.order - b.order)
				},
				set(lesson_) {
					this.value.lessons = [...lesson_]
				},
			},
		},
		mounted() {
			if (this.lessons.length === 0) this.addLesson(false)
		},
		watch: {
			lessons: {
				deep: true,
				handler(newVal) {
					if (newVal.length === 0) this.addLesson(false)
				},
			},
		},
		methods: {
			addLesson(autoExpand = false) {
				const newLesson = {
					lesson_id: `new_lesson_${this.value.module_id}_${this.lessons.length}`,
					status: 1,
					order: 999,
					data: {
						title: `Lesson ${this.lessons.length + 1}`,
						description: `${this.defaultLessonDescription}`,
						video: '',
						digital_assets: [],
					},
				}
				this.value.lessons.push(newLesson)
				if (autoExpand) {
					this.selectLesson(this.value.lessons.length - 1)
				}
			},
			selectLesson(lessonIndex) {
				this.$emit('lesson-selected', lessonIndex)
			},
			async onLessonsMoved(data) {
				if (!this.$commonHelper.isUndefined(data.moved) || !this.$commonHelper.isUndefined(data.added)) {
					const lessonOrder = []
					for (let i = 0; i < this.lessons.length; i++) {
						const lesson = this.lessons[i]
						if (Number.isInteger(lesson.lesson_id)) {
							lessonOrder.push({ lesson_id: lesson.lesson_id, order: lesson.order })
						}
					}
					try {
						await this.$axios.patch(`v1/courses/modules/${this.value.module_id}/lessons/order`, lessonOrder)
					} catch (err) {
						this.$logError(err)
					}
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.lessons-wrapper {
		display: flex;
		flex-direction: column;
		gap: 5px;
		margin-bottom: 1rem;
		.lesson-card {
			display: flex;
			padding: 1rem;
			font-size: 14px;
			border-radius: 12px;
			gap: 8px;
			&:hover {
				background-color: var(--stan-blue-8);
				.lesson-icon {
					display: flex;
					align-items: center;
					flex: 0 0 auto;
					width: auto;
					max-width: 100%;
				}
			}
		}
	}
	small {
		color: var(--stan-blue-5);
	}
	.remove-module-wrapper {
		button {
			width: 100%;
			@media (min-width: 576px) {
				width: auto;
			}
		}
	}
	.btn-off-gray {
		background-color: var(--stan-blue-8);
		color: var(--stan-blue-6);
		line-height: 1;
		&::v-deep {
			svg {
				path {
					fill: var(--stan-blue-6);
				}
			}
		}
	}

	.forward-arrow {
		transform: rotate(180deg);
		height: 15px;
		&::v-deep {
			path {
				stroke: var(--stan-blue-6) !important;
			}
			width: 13px;
		}
	}
</style>
