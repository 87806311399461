var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.showButtonLayout
        ? _c(
            "AppSection",
            { attrs: { number: _vm.root, title: "Pick a style" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "button-layout-wrapper mb-3 flex-wrap justify-content-center justify-content-md-start"
                },
                [
                  _c("div", { staticClass: "col-auto p-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.layout,
                          expression: "value.layout"
                        }
                      ],
                      attrs: { type: "radio", id: "button", value: "button" },
                      domProps: { checked: _vm._q(_vm.value.layout, "button") },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.value, "layout", "button")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "para-2", attrs: { for: "button" } },
                      [
                        _c("InlineSvg", {
                          attrs: { src: "/images/icons/ic-button.svg" }
                        }),
                        _vm._v(_vm._s(_vm.$t("Button")) + " ")
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-auto p-0" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.value.layout,
                          expression: "value.layout"
                        }
                      ],
                      attrs: { type: "radio", id: "callout", value: "callout" },
                      domProps: {
                        checked: _vm._q(_vm.value.layout, "callout")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.value, "layout", "callout")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "para-2", attrs: { for: "callout" } },
                      [
                        _c("InlineSvg", {
                          attrs: { src: "/images/icons/ic-callout.svg" }
                        }),
                        _vm._v(_vm._s(_vm.$t("Callout")) + " ")
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showPreview
                    ? _c("div", { staticClass: "col-auto p-0" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.value.layout,
                              expression: "value.layout"
                            }
                          ],
                          attrs: {
                            type: "radio",
                            id: "proview",
                            value: "preview"
                          },
                          domProps: {
                            checked: _vm._q(_vm.value.layout, "preview")
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.value, "layout", "preview")
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticClass: "para-2", attrs: { for: "proview" } },
                          [
                            _c("InlineSvg", {
                              attrs: { src: "/images/icons/ic-video.svg" }
                            }),
                            _vm._v(_vm._s(_vm.$t("Preview")) + " ")
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "AppSection",
        { attrs: { number: _vm.root + 1, title: "Select image" } },
        [
          _c("FileDropZone", {
            staticClass: "new",
            attrs: {
              image: _vm.value.image,
              "drop-zone-class": "header-image-drop-zone",
              "drop-zone-placeholder": "Thumbnail",
              "expected-dimension": "400x400",
              "data-test-id": "upload-button-file-button",
              "crop-aspect-ratio": 1,
              accept: "image"
            },
            on: {
              "start-upload": function(payload) {
                return _vm.$emit("start-upload", payload)
              },
              uploaded: function(payload) {
                return _vm.$emit("imageUploaded", payload)
              },
              "uploaded-all": function(payload) {
                return _vm.$emit("uploaded-all", payload)
              },
              "delete-file": function(payload) {
                return _vm.$emit("delete-file", payload)
              },
              "cancel-file": function(payload) {
                return _vm.$emit("cancel-file", payload)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "AppSection",
        { attrs: { number: _vm.root + 2, title: "Add text" } },
        [
          ["callout", "preview"].includes(_vm.value.layout)
            ? _c(
                "div",
                { staticClass: "c" },
                [
                  _c("AppInput", {
                    staticClass: "form-group mb-3 pb-2",
                    attrs: {
                      label: _vm.$t("Title"),
                      maxlength: "50",
                      placeholder: _vm.$t("Input Heading Here"),
                      required: ""
                    },
                    model: {
                      value: _vm.value.heading,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "heading", $$v)
                      },
                      expression: "value.heading"
                    }
                  }),
                  _vm._v(" "),
                  _c("AppInput", {
                    staticClass: "form-group mb-3 pb-2",
                    attrs: {
                      label: _vm.$t("Subtitle"),
                      maxlength: "100",
                      placeholder: _vm.$t(
                        "Enter a description for your followers here"
                      )
                    },
                    model: {
                      value: _vm.value.tagline,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "tagline", $$v)
                      },
                      expression: "value.tagline"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("AppInput", {
            staticClass: "form-group mb-0",
            class: { "form-error": _vm.validation.title.$error },
            attrs: {
              label: _vm.$t("Button*"),
              maxlength: "30",
              placeholder: _vm.$t("Button Text Here...")
            },
            model: {
              value: _vm.value.title,
              callback: function($$v) {
                _vm.$set(_vm.value, "title", $$v)
              },
              expression: "value.title"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }