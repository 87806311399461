var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showPreview
        ? _c("CoursePageDesktopPreview", {
            staticClass: "course-preview-wrapper",
            attrs: { course: _vm.coursePreview, user: _vm.user, hideVideo: "" },
            on: { close: _vm.closePreview }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.messages, function(message) {
        return _c(
          "div",
          { key: message.id },
          [
            message.author === "stanley"
              ? _c(
                  "div",
                  [
                    message.template === "intake"
                      ? _c("StanleyTyping", { attrs: { text: message.text } })
                      : _vm._e(),
                    _vm._v(" "),
                    message.template === "generating"
                      ? _c(
                          "StanleyTyping",
                          {
                            attrs: { text: message.text },
                            on: { doneTyping: _vm.scrollToBottom }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("InfiniteProgressBar", {
                                  staticClass: "loading-bar my-3",
                                  class: {
                                    "opacity-20": message.status === "done"
                                  },
                                  attrs: {
                                    estimatedSeconds: 180,
                                    done: message.status === "done"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    message.template === "preview"
                      ? _c(
                          "StanleyTyping",
                          {
                            attrs: { text: message.text, typing: false },
                            on: { doneTyping: _vm.nextStanleyAction }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "course-preview-container p-3 mt-2"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-wrap justify-items-start"
                                  },
                                  [
                                    _c("div", { staticClass: "px-2" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "http://localhost:8080/images/add-section/add-product-course.svg",
                                          alt: ""
                                        }
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "px-2" }, [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "header mb-3 text-truncate",
                                          staticStyle: { width: "250px" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(message.course.data.title)
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex flex-wrap" },
                                        [
                                          _c(
                                            "AppButton",
                                            {
                                              staticClass: "mr-2 mb-2",
                                              on: {
                                                click: function($event) {
                                                  return _vm.createProduct(
                                                    message.course
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Edit Course")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "AppButton",
                                            {
                                              staticClass: "mr-2 mb-2",
                                              attrs: { outline: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleCoursePreview(
                                                    message.course
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Preview")]
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    message.template === "feedback"
                      ? _c("StanleyTyping", { attrs: { text: message.text } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-wrap justify-content-start mt-2"
                            },
                            [
                              _c(
                                "AppButton",
                                {
                                  staticClass: "mr-2 mb-2",
                                  attrs: { outline: "", size: "md" },
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStanleyAction("exit")
                                    }
                                  }
                                },
                                [_vm._v("Maybe next time! I'm done.")]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    message.template === "exit"
                      ? _c("StanleyTyping", { attrs: { text: message.text } })
                      : _vm._e()
                  ],
                  1
                )
              : _c("StanleyUserMessage", [_vm._v(_vm._s(message.text))])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }