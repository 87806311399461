var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("file-drop-zone", {
        ref: "order-file-upload",
        attrs: {
          options: _vm.uploadOptions,
          ghost: "",
          "object-type": "only_max"
        },
        on: {
          "start-upload": _vm.uploadingFile,
          uploaded: function(data) {
            return _vm.fileUploaded(data)
          },
          progress: function(payload) {
            return _vm.onUploadProgress(payload)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "AppModal",
        {
          attrs: {
            id: _vm.id,
            title: _vm.$t("Fulfill Your Order"),
            disableAction: _vm.flag === 1,
            action: _vm.$t("Fulfill Order")
          },
          on: { close: _vm.closeModal, cta: _vm.fulfill_order }
        },
        [
          _vm.transaction_data && _vm.transaction_data.data.title != ""
            ? _c("div", { staticClass: "model-subheadar w-full" }, [
                _c("h6", [_vm._v(_vm._s(_vm.transaction_data.data.title))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "user-box w-full" }, [
            _vm.transaction_data && _vm.transaction_data.data.name != ""
              ? _c("span", { staticClass: "user_name" }, [
                  _c("img", {
                    staticClass: "mr-2",
                    attrs: { src: "/images/balence-user.svg" }
                  }),
                  _vm._v(_vm._s(_vm.transaction_data.data.name) + "\n\t\t\t")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.transaction_data &&
            _vm.transaction_data.data.note != "" &&
            _vm.transaction_data.data.note != null
              ? _c("span", { staticClass: "user_name" }, [
                  _c("img", {
                    staticClass: "mr-2",
                    attrs: { src: "/images/icon-null.svg" }
                  }),
                  _vm._v(_vm._s(_vm.transaction_data.data.note) + "\n\t\t\t")
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3 upload-file-box w-100" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap justify-content-center" },
              [
                _c("div", [
                  _c("div", { staticClass: "mt-4" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "upload-btn btn d-inline-flex align-items-center px-2 py-0 d-flex align-items-center",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.addFile }
                      },
                      [
                        _c("img", {
                          staticClass: "mr-2",
                          attrs: { src: "/images/icon-laptop.svg" }
                        }),
                        _vm._v(_vm._s(_vm.$t("Upload Files")))
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "w-75 mx-auto mb-2",
                      staticStyle: { "text-align": "center" }
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.$t(
                              "If you are uploading multiple files for this order, please combine them into a single .zip file and then upload it!"
                            )
                          ) +
                          "\n\t\t\t\t\t"
                      )
                    ]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _vm.flag > 0
            ? _c(
                "div",
                {
                  staticClass:
                    "pt-1 progress-bar-wrapper d-flex align-items-center w-100 p-2"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "file-name w-100 text-left d-flex align-items-center"
                    },
                    [
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: { src: "/images/icon-frame.svg" }
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.fileName))])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.flag === 1
                    ? _c(
                        "div",
                        { staticClass: "circle-progress-bar text-right" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-full d-flex align-items-center float-right justify-self-end"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-2",
                                  attrs: { slot: "legend-value" },
                                  slot: "legend-value"
                                },
                                [_vm._v(_vm._s(_vm.fileUploadProgress) + "%")]
                              ),
                              _vm._v(" "),
                              _c("vue-ellipse-progress", {
                                attrs: {
                                  progress: _vm.fileUploadProgress,
                                  legend: false,
                                  thickness: 3,
                                  color: "#3A36DB",
                                  legendFormatter: function(ref) {
                                    var currentValue = ref.currentValue

                                    return currentValue + "%"
                                  },
                                  legendClass: "legend-custom-style",
                                  size: 26
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.flag === 2
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "circle-progress-bar d-flex align-items-center col-auto px-0"
                        },
                        [
                          _c("span", { staticClass: "text-green" }, [
                            _vm._v(_vm._s(_vm.$t("Uploaded")))
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "ml-2 cursor-pointer",
                            attrs: { src: "/images/icon-delete.svg" },
                            on: { click: _vm.removeDownload }
                          })
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }