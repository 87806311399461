var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative" }, [
    _c("div", { staticClass: "order-bump-section" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-start enable-community-integration"
        },
        [
          _c(
            "div",
            { staticClass: "enable-toggle" },
            [
              _c("AppToggle", {
                model: {
                  value: _vm.value.enabled,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "enabled", $$v)
                  },
                  expression: "value.enabled"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [_c("InlineSvg", { attrs: { src: "/images/email-icon.svg" } })],
            1
          ),
          _vm._v(" "),
          _vm._m(0)
        ]
      ),
      _vm._v(" "),
      _vm.value.enabled
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-start community-settings flex-row mr-3"
              },
              [
                _c(
                  "div",
                  [
                    _c("InlineSvg", {
                      attrs: { src: "/images/icons/ic-check-circle.svg" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-column details" }, [
                  _vm.communityName
                    ? _c("div", [
                        _vm._v(
                          "\n\t\t\t\t\t\tAll Customers who purchase this product will receive an invite email to join your Community: "
                        ),
                        _c("b", [_vm._v(_vm._s(_vm.communityName))])
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n\t\t\t\t\t\tAll Customers who purchase this product will receive an invite email to join your Community!\n\t\t\t\t\t"
                        )
                      ]),
                  _vm._v(" "),
                  _vm._m(1)
                ])
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("label", { staticClass: "text-bold mr-2 mb-0" }, [
        _vm._v("Invite Customers to Community")
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "w-100 d-block mb-2 hint-text" }, [
        _vm._v(
          "Upon purchase, customer will also receive an invite to join your Community."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "diagram" }, [
      _c("img", {
        attrs: { src: "/images/community-product-integration-diagram.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }