var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-multiselect", class: { "form-error": _vm.error } },
    [
      _c("Multiselect", {
        attrs: {
          id: _vm.id,
          caret: true,
          mode: _vm.type,
          "close-on-select": _vm.closeOnSelect,
          searchable: _vm.searchable,
          options: _vm.options,
          placeholder: _vm.placeholder,
          label: _vm.label,
          "track-by": _vm.trackBy,
          disabled: _vm.disabled,
          "value-prop": _vm.trackBy,
          object: _vm.object,
          createTag: _vm.taggable
        },
        on: {
          tag: _vm.tag,
          deselect: _vm.remove,
          select: _vm.select,
          close: function($event) {
            return _vm.$emit("close")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "singlelabel",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("div", { staticClass: "multiselect-single-label" }, [
                  value.icon
                    ? _c("img", {
                        staticClass: "select-label-icon",
                        attrs: { src: value.icon }
                      })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(value.name || value[""]) + "\n\t\t\t")
                ])
              ]
            }
          },
          {
            key: "option",
            fn: function(ref) {
              var option = ref.option
              return [
                option.icon
                  ? _c("img", {
                      staticClass: "select-option-icon",
                      attrs: { src: option.icon }
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(option.name || option[""]) + "\n\t\t")
              ]
            }
          }
        ]),
        model: {
          value: _vm.localValue,
          callback: function($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }