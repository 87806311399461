var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.$t(_vm.modalTitle),
        subheading: _vm.$t(_vm.modalSubheading),
        "aria-labelledby": "audienceBulkAddToProductModal",
        overflow: true,
        loading: _vm.loading,
        action: "Grant Product Access",
        "secondary-action": "Cancel"
      },
      on: { cta: _vm.submitModalForm, "secondary-cta": _vm.hide }
    },
    [
      _c("Transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.loading
          ? _c(
              "div",
              { key: "loading" },
              [
                _c(
                  "div",
                  { staticClass: "importing-animation" },
                  [
                    _c("InlineSvg", {
                      attrs: { src: "/images/icons/ic-box.svg" }
                    }),
                    _vm._v(" "),
                    _c("InlineSvg", {
                      attrs: { src: "/images/icons/ic-box.svg" }
                    }),
                    _vm._v(" "),
                    _c("InlineSvg", {
                      attrs: { src: "/images/icons/ic-box.svg" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("AppProgress", {
                  attrs: {
                    min: 0,
                    max: _vm.totalCustomers,
                    value: _vm.processedCount
                  }
                })
              ],
              1
            )
          : _c("div", { key: "content" }, [
              _c("form", { attrs: { id: "audience_bulk_tagging_form" } }, [
                _c("div", { staticClass: "overflow-visible" }, [
                  _c(
                    "div",
                    { staticClass: "overflow-visible border" },
                    [
                      _c("AppDropdown", {
                        staticClass: "product-dropdown",
                        attrs: {
                          value: _vm.selectedProduct,
                          options: _vm.availableProducts,
                          searchable: true,
                          placeholder: "Select a product",
                          "image-width": "23px",
                          "right-align": false,
                          "display-label": "name",
                          label: "name",
                          AppDropdown: true,
                          small: false,
                          "rounded-icons": true
                        },
                        on: { select: _vm.selectProduct }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.formErrors &&
                            _vm.formErrors.productSelectionErrors,
                          expression:
                            "formErrors && formErrors.productSelectionErrors"
                        }
                      ],
                      staticClass: "para-3 text-danger mb-2"
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.formErrors.productSelectionErrors) +
                          "\n\t\t\t\t\t"
                      )
                    ]
                  )
                ])
              ])
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }