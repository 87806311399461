var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      staticClass: "manage-payment-plan-modal",
      attrs: {
        id: "manage-payment-plan-modal",
        action: "Cancel Payment Plan",
        actionColor: "danger",
        secondaryAction: "Keep It!",
        secondaryActionColor: "light",
        title: _vm.modalTitle,
        loading: _vm.loading
      },
      on: {
        cta: _vm.updatePaymentPlan,
        secondaryCtaClick: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "d-flex my-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("p", { staticClass: "para-2 text-center text-light" }, [
            _vm._v(
              "\n\t\t\t\tCancelling this plan will stop all future payments. This does not affect any previous payments.\n\t\t\t"
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }