var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-check" }, [
    _c(
      "ul",
      { staticClass: "form-group mb-0" },
      _vm._l(_vm.choices, function(item, index) {
        return _c(
          "li",
          { key: "item_" + index, staticClass: "d-flex align-items-center" },
          [
            _c("input", {
              attrs: {
                type: "radio",
                name: "cancel_reason",
                id: "item_" + index
              },
              domProps: { value: item },
              on: {
                change: function($event) {
                  return _vm.onCheck($event)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "item_" + index }
              },
              [_vm._v(" " + _vm._s(item) + " ")]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }