<template>
	<div class="form-check">
		<ul class="form-group mb-0">
			<li v-for="(item, index) in choices" :key="'item_' + index" class="d-flex align-items-center">
				<input type="radio" name="cancel_reason" :value="item" :id="'item_' + index" @change="onCheck($event)" />
				<label class="form-check-label" :for="'item_' + index"> {{ item }} </label>
			</li>
		</ul>
	</div>
</template>
<script>
	export default {
		name: 'AppRadioButton',
		props: {
			choices: {
				required: true,
				type: Array,
			},
			btnDisable: Boolean,
		},
		methods: {
			onCheck(e) {
				this.$emit('input', e)
				if (e.target.checked) {
					try {
						// this.$parent.changeBtnStatus(false)
						this.$emit('change', false)
					} catch (er) {
						console.log('')
					}
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.form-group {
		list-style: none;
		padding: 1px 0px;
	}
	.form-group li {
		margin: 15px 0px;
	}
	[type='radio']:checked,
	[type='radio']:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	[type='radio']:checked + label,
	[type='radio']:not(:checked) + label {
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		line-height: 20px;
		display: inline-block;
		color: #666;
	}
	[type='radio']:checked + label:before,
	[type='radio']:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 2px solid var(--stan-gray-dark-color);
		border-radius: 100%;
		background: transparent;
	}
	[type='radio']:checked + label:before {
		border: 2px solid #6200ee;
	}
	[type='radio']:checked + label:after,
	[type='radio']:not(:checked) + label:after {
		content: '';
		width: 10px;
		height: 10px;
		background: #6200ee;
		position: absolute;
		top: 5px;
		left: 5px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type='radio']:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	[type='radio']:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
		border: 2px solid #6200ee;
	}
</style>
