var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-price" }, [
    _vm.saleAmount && _vm.saleAmount > 0
      ? _c("span", { staticClass: "sale-amount" }, [
          _vm._v(_vm._s(_vm.formattedSaleAmount) + " ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.amount && _vm.amount > 0
      ? _c("span", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm.formattedAmount) + " ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.interval
      ? _c("span", { staticClass: "price-interval" }, [
          _vm._v("/ " + _vm._s(_vm.capitalizedInterval))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }