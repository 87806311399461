var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: { id: _vm.id, title: _vm.$t("Add Product Link"), hideClose: "" },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { staticClass: "overflow-visible" }, [
        _c(
          "div",
          { staticClass: "mb-3 mt-3" },
          [
            _c("AppInput", {
              attrs: {
                id: "searchInput",
                placeholder: "Search...",
                icon: "/images/icons/ic-search.svg"
              },
              model: {
                value: _vm.searchInput,
                callback: function($$v) {
                  _vm.searchInput = $$v
                },
                expression: "searchInput"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "products-container overflow-visible" }, [
          _c(
            "div",
            { staticStyle: { height: "200px", overflow: "auto" } },
            _vm._l(_vm.productOptions, function(product) {
              return _c(
                "div",
                {
                  key: product.id,
                  on: {
                    click: function($event) {
                      return _vm.confirmSelection(product)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-wrap justify-content-start product-container py-2 para-2 align-items-center"
                    },
                    [
                      _c("img", {
                        staticClass: "product-icon",
                        attrs: { src: product.image }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "text-truncate pl-3",
                          staticStyle: { "max-width": "70%" }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(product.label) +
                              "\n\t\t\t\t\t\t"
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }