var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppModal", {
    attrs: {
      id: _vm.id,
      title: _vm.contentUse.title,
      subheading: _vm.contentUse.subheading,
      action: _vm.contentUse.actionLabel,
      actionColor: "danger",
      secondaryAction: _vm.contentUse.secondaryActionLabel,
      secondaryActionColor: "light"
    },
    on: {
      cta: _vm.deletePage,
      close: _vm.closeModal,
      "secondary-cta": _vm.closeModal
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }