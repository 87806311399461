var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "content-placeholders",
    { attrs: { rounded: _vm.rounded, centered: true } },
    [
      _vm.heading ? _c("content-placeholders-heading") : _vm._e(),
      _vm._v(" "),
      _c("content-placeholders-img", { staticClass: "calendar-skeleton-image" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }