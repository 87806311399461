var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("StanleyTyping", {
        attrs: { text: _vm.texts[0] },
        on: {
          doneTyping: function($event) {
            _vm.counter++
          }
        }
      }),
      _vm._v(" "),
      _vm.counter >= 1
        ? _c("StanleyTyping", {
            attrs: { text: _vm.texts[1] },
            on: {
              doneTyping: function($event) {
                _vm.counter++
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.counter >= 2
        ? _c("StanleyTyping", { attrs: { text: _vm.texts[2] } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap mt-2" },
              [
                _c(
                  "AppButton",
                  {
                    staticClass: "mr-3 mb-2",
                    attrs: {
                      name: "thread-onboarding-welcome-yes-button",
                      size: "md",
                      outline: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.counter++
                      }
                    }
                  },
                  [_vm._v("Yes! How do I monetize?")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.counter >= 3
        ? _c("StanleyTyping", { attrs: { text: _vm.texts[3] } }, [
            _c(
              "div",
              {
                staticClass:
                  "embed-responsive embed-responsive-16by9 video-frame mt-2",
                staticStyle: { "max-width": "100%", width: "400px" },
                attrs: { id: "video-play-wrapper" }
              },
              [
                _c("iframe", {
                  staticClass: "embed-responsive-item",
                  attrs: {
                    src: _vm.video_uri,
                    frameborder: "0",
                    allow: "encrypted-media; gyroscope; picture-in-picture",
                    allowfullscreen: ""
                  }
                })
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }