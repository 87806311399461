var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { key: _vm.filtersKey, staticClass: "chips-container" },
      [
        _vm._l(_vm.filters, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "chip-wrapper" },
            [
              _vm.checkShow(item)
                ? _c("AppFilterChip", {
                    attrs: {
                      label: item.label,
                      filterItem: item,
                      id: "filter-" + index,
                      type: item.type,
                      customDropdownOptions: item.customDropdownOptions,
                      customDropdownReduce: item.customDropdownReduce,
                      customOperators: item.customOperators,
                      disabled: item.disabled
                    },
                    on: {
                      addFilter: _vm.addFilter,
                      removeFilter: _vm.removeFilter
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        _vm._v(" "),
        _vm.showClearFliters
          ? _c(
              "AppButton",
              {
                staticClass: "m-2 w-auto",
                attrs: { name: "clear-filters", outline: "", size: "small" },
                on: { click: _vm.clearFilters }
              },
              [_vm._v("Clear Filters")]
            )
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _vm.showCsvDownload
          ? _c(
              "AppButton",
              {
                staticClass: "m-2 w-auto",
                attrs: {
                  name: "download-csv-app-filter",
                  outline: "",
                  size: "small"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("downloadCsv")
                  }
                }
              },
              [_vm._v("Download CSV")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }