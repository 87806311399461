<template>
	<AppModal id="createModal" title="Edit Customer">
		<form id="creator_form" @submit.prevent="submitModalForm">
			<AppInput
				v-model="form.username"
				class="form-group"
				:label="$t('Username')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.username"
				:warning="ajax_warnings && ajax_warnings.username"
				:placeholder="$t('Username')"
				name="username"
				@change="onBlurChangeWarning('username')"
			/>
			<AppInput
				v-model="form.full_name"
				class="form-group"
				:label="$t('Full Name')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.full_name"
				:warning="ajax_warnings && ajax_warnings.full_name"
				:placeholder="$t('Your Name')"
				name="full_name"
				@change="onBlurChangeWarning('full_name')"
			/>
			<AppInput
				v-model="form.email"
				class="form-group"
				:label="$t('Email')"
				:error="ajax_error.errors && ajax_error.errors.email"
				:warning="ajax_warnings && ajax_warnings.email"
				:placeholder="$t('Email')"
				name="email"
				@change="onBlurChangeWarning('email')"
			/>
			<AppInput
				v-model="form.phone_number"
				class="form-group has-country align-items-center"
				:label="$t('Phone Number')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.phone_number"
				:warning="ajax_warnings && ajax_warnings.phone_number"
				:placeholder="$t('Phone')"
				name="phone_number"
				@change="onBlurChangeWarning('phone_number')"
			/>
			<AppInput
				v-model="form.ref"
				class="form-group align-items-center"
				:label="$t('Referrer')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.ref"
				:placeholder="$t('Referrer')"
				name="ref"
			/>
			<AppInput
				v-model="form.password"
				class="form-group"
				:label="$t('Password')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.password"
				:placeholder="$t('Password')"
				type="password"
				name="password"
			/>
			<AppInput
				v-model="form.password_confirmation"
				class="form-group"
				:label="$t('Confirm Password')"
				:error="ajax_error && ajax_error.errors && ajax_error.errors.password_confirmation"
				:placeholder="$t('Confirm Password')"
				type="password"
				name="password_confirmation"
			/>
			<div class="d-flex flex-wrap justify-content-end mt-3">
				<AppButton
					name="close-modal-edit-creator"
					outline
					class="py-2 md-flat ml-2 mt-1"
					style="width: 100px"
					type="button"
					data-dismiss="modal"
					>{{ $t('Close') }}</AppButton
				>
				<AppButton name="save-modal-edit-creator" class="py-2 md-flat ml-2 mt-1" style="width: 100px" type="Submit">{{
					$t('Save')
				}}</AppButton>
			</div>
		</form>
	</AppModal>
</template>
<script>
	import helper from '~/global_helper/helpers.js'

	export default {
		props: {
			userId: {
				type: Number,
				required: true,
			},
		},
		data() {
			return {
				editCreatorData: {},
				ajax_warnings: {},
				ajax_error: {
					message: '',
					status: null,
					errors: {},
				},
				form: {},
				loading: false,
			}
		},
		mounted() {
			this.resetForm()
			this.getFormData()
		},
		methods: {
			getFormData() {
				this.resetForm()
				this.fetchUserData()
				this.ajax_error.errors = []
				this.ajax_warnings = {}
			},
			resetForm() {
				this.form = {
					id: '',
					full_name: '',
					username: '',
					email: '',
					password: '',
					password_confirmation: '',
					phone_number: '',
					country_code: '',
					ref: '',
				}
			},
			submitModalForm() {
				this.loading = true
				const status = this.validateForm()
				if (status) {
					const payload = {
						id: this.form.id,
						full_name: this.form.full_name,
						username: this.form.username,
						email: this.form.email,
						password: this.form.password,
						password_confirmation: this.form.password_confirmation,
						phone_number: this.form.phone_number,
						country_code: this.form.country_code,
						ref: this.form.ref,
					}
					this.$axios
						.put('v1/admin/creator', payload)
						.then(response => {
							if (response && response.data && response.data.status === 'error') {
								Object.assign(this.ajax_error, response.data)
							} else {
								this.editCreatorData = null
								this.$emit('updated')
								$('#createModal').modal('hide')
								this.$stanNotify({
									type: 'success', // can be 'info', 'warning', 'success', 'error'
									title: this.$t('Creator'),
									text: `${response.data.message}`,
									duration: 2000,
								})
							}
						})
						.catch(error => {
							this.$logError(error)
							Object.assign(this.ajax_error, error.response.data)
						})
						.finally(() => {
							this.loading = false
						})
				} else {
					this.loading = false
				}
			},
			onBlurChangeWarning(field) {
				if (this.editCreatorData && this.form && this.editCreatorData[field] !== this.form[field]) {
					const merged = {}
					merged[field] = this.$t('The field has changed.')
					this.ajax_warnings = { ...this.ajax_warnings, ...merged }
				} else {
					const merged = { ...this.ajax_warnings }
					merged[field] = null
					Object.assign(this.ajax_warnings, { ...merged })
				}

				Object.assign(this.ajax_warnings, { ...this.ajax_warnings })
			},
			onSelect({ dialCode }) {
				this.form.country_code = dialCode
			},
			fetchUserData() {
				this.$axios
					.get(`v1/admin/get-user/${this.userId}`)
					.then(response => {
						this.editCreatorData = response.data
						Object.keys(this.form).forEach(key => {
							this.form[key] = this.editCreatorData[key]
						})
					})
					.catch(error => {
						this.ajax_error = error
						this.$logError(error)
					})
			},
			phoneValidation(e) {
				if (e && e.country !== undefined && e.number !== undefined && e.valid === true) {
					this.form.phone_number = e.number
				} else {
					this.form.phone_number = ''
				}
			},
			validateForm() {
				let formStatus = true
				const errorObject = {
					status: 'error',
					message: this.$t('The given data was invalid.'),
					errors: {},
				}

				if (helper.stringLength(this.form.username) === 0) {
					Object.assign(errorObject.errors, {
						username: [this.$t('Please enter a username!')],
					})
					formStatus = false
				}

				if (!helper.validateUsername(this.form.username)) {
					Object.assign(errorObject.errors, {
						username: [this.$t('Your username may only contain letters, numbers, dashes & underscores!')],
					})
					formStatus = false
				}

				if (!helper.validateUsernameLength(this.form.username)) {
					Object.assign(errorObject.errors, {
						username: [this.$t('Please ensure your username is 5 - 48 characters long')],
					})
					formStatus = false
				}

				if (helper.stringLength(this.form.full_name) === 0) {
					Object.assign(errorObject.errors, {
						full_name: [this.$t('Please enter Your Name!')],
					})
					formStatus = false
				}
				if (helper.stringLength(this.form.email) === 0) {
					Object.assign(errorObject.errors, {
						email: [this.$t('Please enter a valid Email!')],
					})
					formStatus = false
				}
				if (helper.stringLength(this.form.email) !== 0 && !helper.validateEmail(this.form.email)) {
					Object.assign(errorObject.errors, {
						email: [this.$t('Please enter a valid Email!')],
					})
					formStatus = false
				}
				if (!helper.validatePhone(this.form.phone_number) || helper.stringLength(this.form.phone_number) === 0) {
					Object.assign(errorObject.errors, {
						phone_number: [this.$t('Please enter a valid Phone Number!')],
					})
					formStatus = false
				}

				if (helper.stringLength(this.form.password) > 0) {
					if (helper.stringLength(this.form.password) < 6) {
						Object.assign(errorObject.errors, {
							password: [this.$t('Password must be at least 6 characters!')],
						})
						formStatus = false
					} else if (helper.stringLength(this.form.password) > 48) {
						Object.assign(errorObject.errors, {
							password: [this.$t('Password must be less than 48 characters!')],
						})
						formStatus = false
					}

					if (helper.stringLength(this.form.password_confirmation) === 0) {
						Object.assign(errorObject.errors, {
							password_confirmation: [this.$t('Please enter a matching password!')],
						})
						formStatus = false
					}

					if (!helper.compareStrings(this.form.password, this.form.password_confirmation)) {
						Object.assign(errorObject.errors, {
							password: [this.$t('Password and confirm password does not match!')],
						})
						formStatus = false
					}
				}

				Object.assign(this.ajax_error, errorObject)
				return formStatus
			},
		},
	}
</script>

<style lang="scss" scoped>
	label {
		color: var(--stan-primary-dark-color);
		font-size: 14px;
	}
</style>
