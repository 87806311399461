var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "example-questions", staticStyle: { "margin-top": "7%" } },
    [
      _c(
        "h3",
        {
          staticClass:
            "d-md-flex d-none text-bold text-center align-items-center justify-content-center gap mb-5"
        },
        [
          _c("InlineSvg", { attrs: { src: "/images/icons/ic-lightning.svg" } }),
          _vm._v("Example Questions\n\t")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "h4",
        {
          staticClass:
            "d-md-none text-bold text-center d-flex align-items-center justify-content-center mb-4"
        },
        [
          _c("InlineSvg", { attrs: { src: "/images/icons/ic-lightning.svg" } }),
          _vm._v("Example Questions\n\t")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "question-chooser mb-4 mb-md-5" },
        _vm._l(_vm.options, function(question, index) {
          return _c(
            "button",
            {
              key: index,
              staticClass: "question-choice",
              on: {
                click: function($event) {
                  return _vm.userPrompt(question)
                }
              }
            },
            [
              _c("span", { staticClass: "para-3 text-bold" }, [
                _vm._v(_vm._s(question.text))
              ]),
              _vm._v(" "),
              _c("InlineSvg", {
                staticClass: "question-arrow-image",
                attrs: { src: "/images/icons/ic-arrow-right.svg" }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "alpha-note para-3 mb-4 mb-md-5 d-flex flex-column gap-8px"
        },
        [
          _c("span", [
            _vm._v(
              "\n\t\t\tNeed more information about the platform? Visit our\n\t\t\t"
            ),
            _c(
              "a",
              {
                staticClass: "mailto",
                on: {
                  click: function($event) {
                    return _vm.clickMoreInformationLink(
                      "https://help.stan.store/"
                    )
                  }
                }
              },
              [_vm._v("Help Center")]
            ),
            _vm._v(" or email\n\t\t\t"),
            _c(
              "a",
              {
                staticClass: "mailto",
                on: {
                  click: function($event) {
                    return _vm.clickMoreInformationLink(
                      "mailto:friends@stanwith.me"
                    )
                  }
                }
              },
              [_vm._v("friends@stanwith.me")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }