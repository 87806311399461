var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress" }, [
    _c("div", {
      staticClass: "progress-bar",
      style: { width: _vm.progress + "%" },
      attrs: { role: "progressbar" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }