var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cancellation-modal-overlay" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-content-center h-100" },
      [
        _c(
          "div",
          { staticClass: "cancellation-modal" },
          [
            _c(
              "div",
              {
                staticClass: "close close-modal",
                attrs: { "data-dismiss": "modal", "aria-label": "Close" },
                on: {
                  click: function($event) {
                    return _vm.flowAbort()
                  }
                }
              },
              [
                _c("InlineSvg", {
                  staticClass: "close-icon",
                  attrs: { src: "/images/icons/ic-close.svg" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "Transition",
              { attrs: { name: "slide", mode: "out-in" } },
              [
                _vm.step == _vm.freeOneMonthOfferStep &&
                !_vm.offerFreeMonthAccepted
                  ? _c("OfferOneFreeMonth", {
                      attrs: {
                        cancelPredefinedReason: _vm.cancelPredefinedReason,
                        cancelReasonExtraDetail: _vm.cancelReasonExtraDetail,
                        cancelFeedback: _vm.cancelFeedback
                      },
                      on: {
                        start: function(payload) {
                          return this$1.stepStart(payload)
                        },
                        "next-step": _vm.nextStep,
                        "free-one-month-offer-accepted": function(payload) {
                          return this$1.freeOneMonthOfferAccepted(payload)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == _vm.freeOneMonthOfferStep &&
                _vm.offerFreeMonthAccepted
                  ? _c("OneFreeMonthOfferAccepted", {
                      on: {
                        start: function(payload) {
                          return this$1.stepStart(payload)
                        },
                        "next-step": _vm.flowEnd
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 2
                  ? _c("CancellationSurvey", {
                      on: {
                        start: function(payload) {
                          return this$1.stepStart(payload)
                        },
                        "next-step": function(payload) {
                          return this$1.cancelConfirmation(payload)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 3
                  ? _c("CancelSubscriptionConfirmation", {
                      on: {
                        start: function(payload) {
                          return this$1.stepStart(payload)
                        },
                        "next-step": _vm.nextStep
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step == 4
                  ? _c("CancellationFlowSurvey", {
                      on: {
                        start: function(payload) {
                          return this$1.stepStart(payload)
                        },
                        "log-analytics": function(payload) {
                          return _vm.log(_vm.build_step_props(payload))
                        },
                        "next-step": _vm.flowEnd
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }