var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("label", { staticClass: "control-label" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "color-input form-control",
        on: { focusout: _vm.$v.$touch }
      },
      [
        _c("div", {
          staticClass: "color-preview",
          style: { background: _vm.previewColor }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.previewColor,
              expression: "previewColor"
            }
          ],
          domProps: { value: _vm.previewColor },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.previewColor = $event.target.value
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$v.previewColor.$error,
            expression: "$v.previewColor.$error"
          }
        ],
        staticClass: "form-error-text para-3 stan-text-danger mb-3 mt-2"
      },
      [
        _c("InlineSvg", {
          staticClass: "error-icon",
          attrs: { src: "/images/icons/ic-warning-circle.svg" }
        }),
        _vm._v(_vm._s(_vm.$t("Please provide a valid hex code")) + "\n\t")
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "color-suggestions" },
      _vm._l(_vm.colorSuggestions, function(color) {
        return _c("div", {
          key: color,
          staticClass: "color-suggestion",
          class: { selected: _vm.isColorSelected(color) },
          style: { background: color },
          on: {
            click: function() {
              return _vm.setPreviewColour(color)
            }
          }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }