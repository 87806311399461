var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("span", {
      attrs: { id: "calendar-events-popup", "aria-expanded": "false" }
    }),
    _vm._v(" "),
    _c(
      "div",
      { ref: "cell", staticClass: "cell-event-wrapper", class: _vm.type },
      [
        _c("div", { staticClass: "event-dot" }),
        _vm._v(" "),
        _c("div", { staticClass: "event-display-time" }, [
          _vm._v(_vm._s(_vm.count))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "event-name" }, [_vm._v(_vm._s(_vm.title))])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }