/* eslint-disable import/extensions */
import Vue from 'vue'
import VueGlobalVar from 'vue-global-var'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'
import VTooltip from 'v-tooltip'
import Vuelidate from 'vuelidate'

// Flickering registration
import VueFlicking from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'

import lodash from 'lodash'
import Clipboard from 'v-clipboard'
import VueSocialSharing from 'vue-social-sharing'
import Notifications from 'vue-notification'
import JsonExcel from 'vue-json-excel'
import VueMeta from 'vue-meta'
import VueCarousel from '@chenfengyuan/vue-carousel'
import VueCompositionAPI from '@vue/composition-api'
import Vue2TouchEvents from 'vue2-touch-events'
import VueMouseflow from 'vue-mouseflow'
import * as Sentry from '@sentry/vue'
import Axios from 'axios'
import VueGtm from '@gtm-support/vue2-gtm'
import InlineSvg from 'vue-inline-svg'
import Draggable from 'vuedraggable'
import countTo from 'vue-count-to'
import VueI18n from 'vue-i18n'
import VueContentPlaceholders from 'vue-content-placeholders'

import store from '~/store'
import router from '~/router'
import App from '~/components/App'
// eslint-disable-next-line import/named
import { configSettings } from '~/plugins/config-settings'
import '~/plugins'
import 'css_path/index.scss'
import './plugins/setGlobalComponents'
import commonHelper from '~/global_helper/helpers.js'
import constants from '~/global_helper/constants.js'
import LoadScript from '~/plugins/vue-plugin-load-script'
import '~/assets/css/variables.css'
import callAnalytics from '~/plugins/stan-analytics'
import experiment from '~/plugins/experiments'
import { isFlag, getFlags } from '~/plugins/feature-flags'
import AdsTracking from '~/plugins/AdsTracking.js'
import EventBus from '~/plugins/event-bus'
import appVersion from '../app-version.json'
import { hexToRGB, PRODUCT_TYPES } from './stan-vue-shared/components/utils.js'

window.$ = require('jquery')
window.JQuery = require('jquery')
window._ = require('lodash')

Vue.config.productionTip = false

Vue.use(VueFlicking)
Vue.use(Clipboard)
Vue.use(VueSocialSharing)
Vue.component('DownloadExcel', JsonExcel)
Vue.use(VueContentPlaceholders)
Vue.use(
	VueGtag,
	{
		config: { id: 'G-FPXVPVPGVK' },
		appName: 'Stan Admin',
		pageTrackerScreenviewEnabled: true,
		params: { send_page_view: false },
	},
	router
)

Vue.use(VueGlobalVar, {
	globals: {
		siteSettings: configSettings,
	},
})

Object.defineProperty(Vue.prototype, '$lodash', { value: lodash })

Vue.use(VueCookies)
Vue.use(VTooltip)
Vue.use(Vuelidate)
Vue.use(Vue2TouchEvents)

Vue.config.optionMergeStrategies.validations = (toVal, fromVal) => {
	if (typeof toVal === 'undefined' && typeof fromVal !== 'function') {
		return fromVal
	}
	if (typeof toVal === 'undefined' && typeof fromVal === 'function') {
		return fromVal()
	}
	if (typeof toVal !== 'undefined' && typeof fromVal === 'function') {
		return lodash.mergeWith(toVal, fromVal())
	}
	return toVal
}

Vue.use(Notifications)
Vue.component(VueCarousel.name, VueCarousel)
Vue.use(VueCompositionAPI)
Vue.use(VueMeta)
Vue.use(LoadScript)
Vue.component('InlineSvg', InlineSvg)
Vue.component('Draggable', Draggable)
Vue.component('CountTo', countTo)

if (configSettings.mouseflowAnalyticsTrackingCode !== null) {
	Vue.use(VueMouseflow, { tracking_key: configSettings.mouseflowAnalyticsTrackingCode })
}

document.getElementsByTagName('HTML')[0].setAttribute('dir', 'ltr')

Vue.use(VueGtm, {
	id: 'GTM-M6DZST9', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
	defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
	compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
	nonce: '2726c7f26c', // Will add `nonce` to the script tag
	enabled: configSettings.env_name === 'production', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
})

Vue.use(VueI18n)
const defaultLocale = 'en'
Vue.$cookies.set('language_code', defaultLocale)
const i18n = new VueI18n({
	locale: defaultLocale,
})

/* eslint-disable no-new */
const Instance = new Vue({
	el: '#app',
	i18n,
	store,
	router,
	...App,
})

const notify = opts => {
	Instance.$notify({
		title: opts.title,
		text: opts.text,
		duration: opts.duration,
		data: {
			type: opts.type,
		},
	})
}

const logError = errors => {
	// console.error(errors)
	if (configSettings.env_name === 'staging' || configSettings.env_name === 'production') {
		Sentry.captureException(errors)
	}
}

const emptyImage =
	'data:image/gif;base64,R0lGODlhngHgAoABAAAAAP///yH5BAEKAAEALAAAAACeAeACQAL+jI+py+0Po5y02ouz3rz7D4biSJbmiabqyrbuC8fyTNf2jef6zvf+DwwKh8Si8YhMKpfMpvMJjUqn1Kr1is1qt9yu9wsOi8fksvmMTqvX7Lb7DY/L5/S6/Y7P6/f8vv8PGCg4SFhoeIiYqLjI2Oj4CBkpOUlZaXmJmam5ydnp+QkaKjpKWmp6ipqqusra6voKGys7S1tre4ubq7vL2+v7CxwsPExcbHyMnKy8zNzs/AwdLT1NXW19jZ2tvc3d7f0NHi4+Tl5ufo6err7O3u7+Dh8vP09fb3+Pn6+/z9/v/w8woMCBBAsaPIgwocKFDBs6fAgxosSJFCtavIgxo8b+jRw7evwIMqTIkSRLmjyJMqXKlSxbunwJM6bMmTRr2ryJM6fOnTx7+vwJNKjQoUSLGj2KNKnSpUybOn0KNarUqVSrWr2KNavWrVy7ev0KNqzYsWTLmj2LNq3atWzbun0LN67cuXTr2r2LN6/evXz7+v0LOLDgwYQLGz6MOLHixYwbO34MObLkyZQrW76MObPmzZw7e/4MOrTo0aRLmz6NOrXq1axbu34NO7bs2bRr276NO7fu3bx7+/4NPLjw4cSLGz+OPLny5cybO38OPbr06dSrW7+OPbv27dy7e/8OPrz48eTLmz+PPr369ezbu38PP778+fTr27+PP7/+/fz++/v/D2CAAg5IYIEGHohgggouyGCDDj4IYYQSTkhhhRZeiGGGGm7IYYcefghiiCKOSGKJJp6IYooqrshiiy6+CGOMMs5IY4023ohjjjruyGOPPv4IZJBCDklkkUYeiWSSSi7JZJNOPglllFJOSWWVVl6JZZZabslll15+CWaYYo5JZplmnolmmmquyWabbr4JZ5xyzklnnXbeiWeeeu7JZ59+/glooIIOSmihhh6KaKKKLspoo44+Cmmkkk5KaaWWXopppppuymmnnn4Kaqiijkpqqaaeimqqqq7KaquuvgprrLLOSmuttt6Ka6667sprr77+Cmywwg5LbLHGHotebLLKLstss84+C2200k5LbbXWXottttpuy2233n4Lbrjijktuueaei2666q7LbrvuvgtvvPLOS2+99t6Lb7767stvv/7+C3DAAg9McMEGH4xwwgovzHDDDj8MsR0FAAA7'

Object.defineProperty(Vue.prototype, '$stanAnalytics', { value: callAnalytics })
Object.defineProperty(Vue.prototype, '$emptyImage', { value: emptyImage })
Object.defineProperty(Vue.prototype, '$stanNotify', { value: notify })
Object.defineProperty(Vue.prototype, '$logError', { value: logError })
Object.defineProperty(Vue.prototype, '$assignExperimentGroups', { value: experiment })
Object.defineProperty(Vue.prototype, '$isFlag', { value: isFlag })
Object.defineProperty(Vue.prototype, '$getFlags', { value: getFlags })
Object.defineProperty(Vue.prototype, '$configSettings', { value: configSettings })
Object.defineProperty(Vue.prototype, '$commonHelper', { value: commonHelper })
constants.PRODUCT_TYPES = PRODUCT_TYPES
Object.defineProperty(Vue.prototype, '$constants', { value: constants })
Object.defineProperty(Vue.prototype, '$appVersion', { value: appVersion })
Object.defineProperty(Vue.prototype, '$AdsTracking', { value: AdsTracking })
Object.defineProperty(Vue.prototype, '$globalEvents', { value: EventBus })
Object.defineProperty(Vue.prototype, '$hexToRGB', { value: hexToRGB })

Vue.prototype.$axios = Axios
AdsTracking('Initialize')

Vue.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyAjpZDyGwZdJHHCMQZ8CSRz3Si3qjQOrqU&libraries=places', {})

// Added Status page
Vue.loadScript('https://status.stan.store/embed/script.js', {})

// Add Google Recaptcha

Vue.loadScript(`https://www.google.com/recaptcha/enterprise.js?render=${configSettings.captchaSiteKey}`, {})

export const { $gtag, $store, $router } = Instance
