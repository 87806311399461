<template>
	<AppModal large ref="unsplashImagePickerModal" :title="$t('Choose Your Image')" @close="closeModal">
		<div class="full-height-container">
			<div class="my-2">
				<AppInput icon="/images/search.svg" iconWidth="16px" :placeholder="$t('Search Keywords')" name="search" v-model="search" />
			</div>
			<div class="overflow-auto img-container">
				<div
					@dragover.prevent="dragOver"
					@dragleave.prevent="dragLeave"
					@drop.prevent="drop($event)"
					draggable
					class="thumbnail-container image-uploader d-flex flex-column align-items-center justify-content-center px-3"
					:class="{ 'dropZone-over': isDragging }"
				>
					<div v-if="isDragging" class="blue-circle d-flex justify-content-center align-items-center">
						<img src="/images/down-arrow.svg" :alt="$t('down arrow')" />
					</div>
					<div v-else class="d-flex flex-column align-items-center">
						<div class="text-center d-none d-sm-block d-none pb-1">
							<div>
								<div class="placeholder-value pb-1">
									{{ $t('Drag Image Here') }}
								</div>
								<div class="placeholder-value ">{{ $t('OR') }}</div>
							</div>
						</div>
						<button class="d-flex upload-btn align-items-center" @click="uploadImage">
							<img :src="'/images/add-image-icon.svg'" class="mr-1 mr-sm-2 " />
							<p class="mb-0">{{ $t('Upload Image') }}</p>
						</button>
					</div>
				</div>
				<div v-for="image in unsplashImages" :key="image.id" class="thumbnail-container">
					<img :src="image.urls.small" class="thumbnail" @click.self="imageSelected(image)" />
					<div class="user-name-text" @click="visitUser(image)">{{ image.user.first_name }} {{ image.user.last_name }}</div>
				</div>
				<div class="my-2 loading-container">
					<AppButton
						name="load-more-unsplash-image-picker-modal"
						v-if="hasMore && !loading"
						outline
						class="mx-auto"
						@click="getNextPage()"
						>{{ $t('Load More') }}</AppButton
					>
					<HollowDotsSpinner
						v-if="loading"
						:animation-duration="1000"
						:dot-size="10"
						class="d-flex align-items-center mx-auto my-3"
						:dots-num="3"
						color="#3a36db"
					/>
				</div>
			</div>
		</div>
	</AppModal>
</template>
<script>
	import { mapGetters } from 'vuex'
	import { debounce as _debounce } from 'lodash'
	import axios from 'axios'

	export default {
		data() {
			return {
				images: [],
				search: '',
				lastSearch: '',
				page: 1,
				loading: false,
				hasMore: true,
				isDragging: false,
				choosingImage: false,
			}
		},
		computed: {
			modal() {
				return $(this.$refs.unsplashImagePickerModal.$el)
			},
			unsplashImages() {
				return this.images.slice(0, this.images.length - 1)
			},
			...mapGetters('Auth', ['user']),
		},
		methods: {
			init() {
				this.search = ''
				this.lastSearch = ''
				this.page = 1
				this.loading = false
				this.modal.modal('show')
				this.getImages()
			},
			getImages(search = '', page = 1) {
				const clientId = this.siteSettings.unsplashApiClientId
				if (!clientId) return
				this.loading = true
				if (page === 1) {
					this.images = [] // new search
					this.hasMore = true
				}

				const searchTerm = search || 'all'

				// use search endpoint
				const url = `https://api.unsplash.com/search/photos/?client_id=${clientId}&query=${searchTerm}&page=${page}&per_page=30`
				axios.get(url).then(response => {
					if (response.data.total_pages <= page) this.hasMore = false
					this.images.push(...response.data.results)
					this.loading = false
				})
			},
			getNextPage() {
				if (this.loading) return
				this.page++
				this.getImages(this.search, this.page)
			},
			imageSelected(image) {
				this.choosingImage = true
				const clientId = this.siteSettings.unsplashApiClientId
				if (!clientId) return
				const downloadEventUrl = `${image.links.download_location}&client_id=${clientId}`
				axios.get(downloadEventUrl)
				const name = `unsplash-${image.id}`
				this.$emit('unsplashImagePicked', { src: image.urls.regular, name, metadata: { id: name } })
				this.$stanAnalytics('unsplash-image-picked', {
					meta: { user_id: this.user.user_id, username: this.user.username },
					props: { unsplash_id: image.id },
				})
				this.closeModal()
			},
			visitUser(image) {
				window.open(`${image.user.links.html}?utm_source=Stan&utm_medium=referral`)
			},
			closeModal() {
				this.modal.modal('hide')
				if (!this.choosingImage) {
					this.$emit('close-modal')
				}
			},
			dragOver() {
				this.isDragging = true
			},
			dragLeave() {
				this.isDragging = false
			},
			drop(e) {
				this.choosingImage = true
				const files = e.dataTransfer.files
				const file = files[0]
				const type = file.type.split('/')
				const fileType = type[0]
				this.isDragging = false
				if (fileType !== 'image') return
				this.closeModal()
				this.$emit('imageDropped', e)
			},
			uploadImage() {
				this.choosingImage = true
				this.isDragging = false
				this.closeModal()
				this.$emit('uploadSelected', 'upload')
			},
		},
		watch: {
			// eslint-disable-next-line func-names
			search: _debounce(function(value) {
				if (value !== this.lastSearch) {
					this.lastSearch = value
					this.getImages(value)
				}
			}, 1000),
		},
	}
</script>

<style lang="scss" scoped>
	.full-height-container {
		height: 100vh;
	}

	.img-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
		& > :last-child {
			grid-column: span 2;
		}
		@media (min-width: 992px) {
			grid-template-columns: 1fr 1fr 1fr;
			& > :last-child {
				grid-column: span 3;
			}
		}
	}
	.thumbnail-container {
		margin: 0px;
		position: relative;
		overflow: hidden;
		height: 166px;
		max-height: 166px;

		.thumbnail {
			height: 100%;
			width: 100%;
			object-fit: cover;
			cursor: pointer;
		}

		.user-name-text {
			text-align: center;
			font-size: 0.75rem;
			color: var(--stan-white);
			position: absolute;
			bottom: 4px;
			left: 8px;
			cursor: pointer;
		}
	}

	.loading-container {
		height: 50px;
	}
	.image-uploader {
		border: 1px solid var(--stan-grey);
	}
	.upload-btn {
		border: 1px solid var(--stan-primary-primary-color);
		color: var(--stan-primary-primary-color);
		border-radius: 4px;
		p {
			font-size: 0.75rem;
			line-height: 1.3;
		}
	}
	.placeholder-value {
		opacity: 0.5;
	}
	.blue-circle {
		background: var(--stan-primary-primary-color);
		width: 46px;
		height: 46px;
		color: var(--stan-white);
		border-radius: 50%;
		pointer-events: none;
	}
	.dropZone-over {
		background-color: var(--stan-blue-8);
	}
</style>
