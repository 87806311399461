<template>
	<div class="form-group mb-1" :key="fields.length">
		<span class="control-label">{{ $t('Fields') }}</span>
		<div class="stan-form-card mb-3 mt-2 shadow-none">
			<AppInput class="input-no-border" icon="/images/form-fields/text.svg" type="text" :value="$t('Name')" disabled="" />
		</div>
		<div class="stan-form-card mb-3 shadow-none">
			<AppInput class="input-no-border" icon="/images/form-fields/email.svg" type="text" :value="$t('Email')" disabled="" />
		</div>
		<div
			v-if="phoneField"
			class="stan-form-card mb-3 shadow-none phone-bg disabled"
			:class="{
				'phone-disabled': phoneField && !phoneField.enabled,
				'form-error': phoneFieldValidation && phoneFieldValidation.$error,
			}"
		>
			<div class="d-flex align-items-center header">
				<div class="form-details">
					<AppInput
						icon="/images/form-fields/phone.svg"
						class="col px-0 input-no-border"
						input-class="mr-3"
						v-model="phoneField.content"
						:disabled="true"
					/>
				</div>
				<div class="action-items align-items-center d-none d-md-flex mr-3 pr-0">
					<div class="d-flex align-items-center justify-content-end mr-3">
						<span class="stan-label pr-1"> {{ $t('Required') }} </span>
						<AppToggle id="checkbox-phone" v-model="phoneField.required" :disabled="!phoneField.enabled" :small="true" />
					</div>
					<div class="d-flex align-items-center justify-content-end">
						<button class="p-0" type="button" @click="togglePhone">
							<InlineSvg v-if="phoneField.enabled" src="/images/form-fields/visible.svg" class="cursor-pointer delete-icon" />
							<InlineSvg v-if="!phoneField.enabled" src="/images/form-fields/not-visible.svg" class="cursor-pointer delete-icon" />
						</button>
					</div>
				</div>
			</div>
			<div class="action-items d-flex align-items-center mb-2 d-md-none pr-3">
				<div class="d-flex align-items-center justify-content-end pr-3">
					<span class="stan-label pr-1"> {{ $t('Required') }} </span>
					<AppToggle id="checkbox-phone-mob" v-model="phoneField.required" :disabled="!phoneField.enabled" :small="true" />
				</div>
				<div class="d-flex align-items-center justify-content-end">
					<button class="p-0" type="button" @click="togglePhone">
						<InlineSvg v-if="phoneField.enabled" src="/images/form-fields/visible.svg" class="cursor-pointer delete-icon" />
						<InlineSvg v-if="!phoneField.enabled" src="/images/form-fields/not-visible.svg" class="cursor-pointer delete-icon" />
					</button>
				</div>
			</div>
		</div>
		<Draggable
			v-model="fields"
			v-bind="{ animation: 0, ghostClass: 'ghost' }"
			:options="{ delay: 250, delayOnTouchOnly: true }"
			draggable=".draggable-section-node"
			handle=".drag-handle"
		>
			<div
				class="d-flex align-items-start draggable-section-node"
				v-for="(field, i) in fields.filter(field => field.type !== 'phone')"
				:key="`field-${i}`"
			>
				<div class="drag-handle d-flex align-items-center cursor-pointer">
					<InlineSvg src="/images/six-dots.svg" />
				</div>
				<div
					class="stan-form-card mb-3 shadow-none col"
					:class="{
						'form-error': fieldsValidation[i] && fieldsValidation[i].$error,
					}"
				>
					<div class="d-flex align-items-center header">
						<div class="form-details">
							<AppInput
								v-if="field.type === 'text'"
								icon="/images/form-fields/text.svg"
								class="col px-0 input-no-border form-field-type-input"
								input-class="mr-3"
								:placeholder="$t('Short answer title...')"
								v-model="field.content"
							/>
							<!-- Leaving the below for future use -->
							<AppInput
								v-if="field.type === 'phone'"
								icon="/images/form-fields/phone.svg"
								class="col px-0 input-no-border form-field-type-input"
								input-class="mr-3"
								v-model="field.content"
								:disabled="field.type === 'phone'"
							/>
							<AppInput
								v-if="field.type === 'dropdown'"
								icon="/images/form-fields/dropdown.svg"
								class="col px-0 input-no-border form-field-type-input"
								input-class="mr-3"
								:placeholder="$t('Dropdown title...')"
								v-model="field.content"
							/>
							<AppInput
								v-if="field.type === 'checkbox'"
								icon="/images/form-fields/checkbox.svg"
								class="col px-0 input-no-border form-field-type-input"
								input-class="mr-3"
								:placeholder="$t('Checkbox title...')"
								v-model="field.content"
							/>
							<AppInput
								v-if="field.type === 'radio'"
								icon="/images/form-fields/radio.svg"
								class="col px-0 input-no-border form-field-type-input"
								input-class="mr-3"
								:placeholder="$t('Multiple choice title...')"
								v-model="field.content"
							/>
						</div>
						<div class="action-items align-items-center d-none d-md-flex mr-3 pr-0">
							<div class="d-flex align-items-center justify-content-end mr-3">
								<span class="stan-label pr-1"> {{ $t('Required') }} </span>
								<AppToggle :id="'checkbox' + i" v-model="field.required" :small="true" />
							</div>
							<div class="d-flex align-items-center justify-content-end">
								<button class="p-0" type="button" @click="removeField(i)">
									<InlineSvg src="/images/form-fields/delete.svg" class="cursor-pointer delete-icon" />
								</button>
							</div>
						</div>
					</div>
					<div class="d-flex" v-if="field.type === 'radio' || field.type === 'checkbox' || field.type === 'dropdown'">
						<div class="options px-3 pb-3 w-100">
							<Draggable
								v-model="field.options"
								v-bind="{ animation: 0, ghostClass: 'ghost' }"
								:options="{ delay: 100, delayOnTouchOnly: true }"
								draggable=".option"
								handle=".option-handle"
								class="w-100"
							>
								<div class="option d-flex stan-option-card mt-2" v-for="(option, o) in field.options" :key="`option-${o}`">
									<div class="option-handle d-flex align-items-center ml-2 cursor-pointer">
										<InlineSvg src="/images/six-dots.svg" />
									</div>
									<AppInput
										class="op-test px-0 input-no-border flex-grow-1"
										input-class="mr-3"
										:placeholder="`Option ${o + 1}`"
										v-model="option.value"
									/>
									<div class="option-action-items d-flex align-items-center">
										<button class="p-0" type="button" @click="removeOption(field, o)">
											<InlineSvg src="/images/close-icon.svg" class="cursor-pointer option-close-icon" />
										</button>
									</div>
								</div>
								<div class="add-option d-flex justify-content-end">
									<AppButton name="add-option-form-fields" outline class="w-100 mt-2" @click="addOption(field)">{{
										$t('+ Add Option')
									}}</AppButton>
								</div>
							</Draggable>
						</div>
					</div>
					<div class="action-items d-flex align-items-center mb-2 d-md-none pr-3">
						<div class="d-flex align-items-center justify-content-end pr-3">
							<span class="stan-label pr-1"> {{ $t('Required') }} </span>
							<AppToggle :id="'checkbox' + i" v-model="field.required" :small="true" />
						</div>
						<div class="d-flex align-items-center justify-content-end">
							<button class="p-0" type="button" @click="removeField(i)">
								<InlineSvg src="/images/form-fields/delete.svg" class="cursor-pointer delete-icon" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</Draggable>
		<div>
			<AppDropdownMenu class="add-field-menu">
				<AppButton name="add-fields-form-field" outline class="w-100">{{ $t('+ Add Field') }}</AppButton>
				<template v-slot:content>
					<div class="dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center" @click="addField('text')">
						<InlineSvg class="mr-2" src="/images/form-fields/text.svg" />
						<span>Text</span>
					</div>
					<div class="dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center" @click="addField('radio')">
						<InlineSvg class="mr-2" src="/images/form-fields/radio.svg" />
						<span>Multiple choice</span>
					</div>
					<div class="dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center" @click="addField('dropdown')">
						<InlineSvg class="mr-2" src="/images/form-fields/dropdown.svg" />
						<span>Dropdown</span>
					</div>
					<div class="dropdown-item cursor-pointer d-flex flex-wrap p-2 align-items-center" @click="addField('checkbox')">
						<InlineSvg class="mr-2" src="/images/form-fields/checkbox.svg" />
						<span class="p2">Checkboxes</span>
					</div>
				</template>
			</AppDropdownMenu>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			value: { type: Array, default: () => [] },
			validation: { type: Object, default: () => {} },
		},
		data() {
			return {
				fields: [],
				fieldsValidation: [],
				formFields: {
					text: { required: false, content: '', type: 'text' },
					phone: { required: true, content: 'Phone Number', type: 'phone', enabled: true },
					radio: { required: false, content: '', type: 'radio', options: [{ value: '' }, { value: '' }] },
					dropdown: { required: false, content: '', type: 'dropdown', options: [{ value: '' }, { value: '' }] },
					checkbox: { required: false, content: '', type: 'checkbox', options: [{ value: '' }, { value: '' }] },
				},
			}
		},
		computed: {
			phoneFieldIndex() {
				return this.value.findIndex(field => field.type === 'phone')
			},
			phoneField() {
				return this.value[this.phoneFieldIndex]
			},
			phoneFieldValidation() {
				return this.validation.$each[this.phoneFieldIndex]
			},
		},
		methods: {
			togglePhone() {
				this.phoneField.enabled = !this.phoneField?.enabled
				if (!this.phoneField?.enable) {
					this.phoneField.required = false
				}
			},
			removeField(index) {
				if (index < this.fields.length) {
					this.fields = this.fields.filter((field, i) => i !== index)
				}
			},
			addField(type) {
				const cf = this.$lodash.cloneDeep(this.formFields[type])
				this.fields = [...this.fields, cf]
			},
			addOption(field) {
				field.options.push({ value: '' })
			},
			removeOption(field, index) {
				field.options.splice(index, 1)
			},
		},
		mounted() {
			if (this.value.filter(field => field.type === 'phone').length === 0) {
				// Add Disabled Phone
				const cf = this.$lodash.cloneDeep(this.formFields.phone)
				cf.enabled = false
				this.$emit('input', [cf, ...this.value])
			} else {
				this.value
					.filter(field => field.type === 'phone')
					.map((question, index) => {
						if (!('enabled' in this.value[index])) {
							this.$set(this.value[index], 'enabled', true)
						}
						return question
					})
			}
		},
		watch: {
			value: {
				deep: true,
				immediate: true,
				handler(val) {
					this.fields = val.filter(field => field.type !== 'phone')
				},
			},
			validation: {
				deep: true,
				immediate: true,
				handler(val) {
					const ret = []
					this.value.forEach((field, index) => {
						if (index !== this.phoneFieldIndex) ret.push(val.$each[index])
					})
					this.fieldsValidation = ret
				},
			},
			fields: {
				deep: true,
				handler(val, oldVal) {
					if (JSON.stringify(val) !== JSON.stringify(oldVal)) this.$emit('input', [this.phoneField, ...val])
				},
			},
		},
	}
</script>
<style lang="scss" scoped>
	.phone-bg {
		background: var(--stan-blue-8);
	}
	.phone-disabled {
		opacity: 0.5;
	}
	.option {
		background: var(--stan-white);
		flex-direction: row !important;
	}
	.option-close-icon {
		width: 12px;
		height: 12px;
		stroke: 1;
		margin-right: 0.5rem;
	}
	.drag-handle {
		height: 48px;
	}
	.option-handle,
	.drag-handle {
		svg {
			height: 18px;
		}
	}
	.input-no-border::v-deep {
		input {
			border: 0px !important;
		}
		img {
			height: 18px;
			margin-right: 0.25rem;
			padding-right: 0.25rem;
		}
	}
	.stan-form-card {
		box-shadow: var(--stan-box-shadow);
		background-color: var(--stan-white);
		border-radius: 7px;
		padding: 0px;
		flex-direction: column;
		overflow: hidden;
		.header {
			background-color: var(--stan-gray-light-color);
		}
		&.disabled {
			.header {
				background-color: var(--stan-primary-light-color);
			}
		}
		.form-field-type-input::v-deep {
			.input-icon {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	.stan-option-card {
		background-color: var(--stan-gray-light-color);
		border: 1px solid var(--stan-gray-strike-color);
		border-radius: 7px;
		padding: 0px;
		flex-direction: column;
	}
	.form-details {
		flex-grow: 1;
		width: 100%;
	}
	.action-items {
		width: 100%;
		justify-content: flex-end;
		padding: 0.25rem;
		padding-bottom: 0.5rem;
		.stan-label {
			font-size: 12px;
			line-height: 1;
			letter-spacing: 0.02em;
		}
		.delete-icon {
			height: 18px;
			width: 18px;
			margin-bottom: 1px;
		}
	}
	.option-action-items {
		width: auto;
		justify-content: flex-end;
		padding: 0.25rem;
		padding-bottom: 0.5rem;
		.delete-icon {
			height: 18px;
			width: 18px;
		}
		@media (min-width: 576px) {
			padding: 0.25rem;
		}
	}

	@media (min-width: 576px) {
		.stan-form-card {
			flex-direction: row;
		}
		.action-items {
			width: auto;
			padding: 0.25rem;
		}
	}
	.add-field-menu::v-deep {
		.dropdown-menu {
			width: 100%;
			z-index: 2;
			padding: 0.5rem;
			margin-top: 0.25rem;
			margin-bottom: 0.25rem;
		}
	}
	.form-error {
		border-color: var(--stan-danger);

		.draggable-section-node .stan-form-card .header,
		.stan-option-card {
			background-color: var(--stan-negative-light-color);
		}
	}
</style>
