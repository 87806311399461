var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.countryDropdown
      ? _c("div", { staticClass: "layout-rect" }, [
          _c("div", { staticClass: "phone-holder" }, [
            _c(
              "div",
              {
                staticClass:
                  "input-bar phone-country dropbtn justify-content-center",
                class: { disabled: _vm.disabled },
                on: { click: _vm.switchCountryDropdown }
              },
              [
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap align-items-center" },
                    [
                      _vm.selectedCountryCodeItem.country != "Other"
                        ? _c("div", { staticClass: "country-2-letter-code" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.selectedCountryCodeItem.code) +
                                "\n\t\t\t\t\t\t"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedCountryCodeItem.country != "Other"
                        ? _c("div", [
                            _vm._v(
                              _vm._s(_vm.selectedCountryCodeItem.countryCode)
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localPhone,
                  expression: "localPhone"
                }
              ],
              ref: "inputPhone",
              staticClass: "input-bar phone-number",
              attrs: {
                id: "inputPhoneId",
                type: "tel",
                placeholder: "Enter your phone number",
                disabled: _vm.disabled,
                maxlength: "13"
              },
              domProps: { value: _vm.localPhone },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.localPhone = $event.target.value
                }
              }
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.countryDropdown
      ? _c("div", { ref: "countryDropdown", staticClass: "country-menu" }, [
          _c(
            "div",
            {
              staticClass: "dropdown-content",
              attrs: { id: "phoneCountryDropdown" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-wrap justify-content-between align-items-center",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.switchCountryDropdown }
                },
                [
                  _c("div", { staticClass: "country-back-button" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 11.95 11",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          stroke: "black"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M7.05852 9.5L2.94138 5L7.05852 0.5",
                            fill: "none",
                            "stroke-width": "1"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("div", { staticClass: "country-back-button" })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px", "margin-bottom": "10px" }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchCountry,
                        expression: "searchCountry"
                      }
                    ],
                    staticClass: "input-bar",
                    attrs: {
                      type: "text",
                      autofocus: "",
                      placeholder: "Search country"
                    },
                    domProps: { value: _vm.searchCountry },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchCountry = $event.target.value
                      }
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.clearCountryCodes, function(item, index) {
                return _c("div", [
                  _vm.isShowCountry(item)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-wrap flag-selector-item align-items-center",
                          on: {
                            click: function($event) {
                              return _vm.selectCountry(index)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "country text-truncate",
                              staticStyle: {
                                "margin-right": "auto",
                                "margin-left": "5px"
                              }
                            },
                            [_vm._v(_vm._s(item.country))]
                          ),
                          _vm._v(" "),
                          item.country != "Other"
                            ? _c("div", [_vm._v(_vm._s(item.countryCode))])
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ])
              })
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [_c("b", [_vm._v("Select Country")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }