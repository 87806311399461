<template>
	<div class="app-multiselect" :class="{ 'form-error': error }">
		<Multiselect
			:id="id"
			:caret="true"
			v-model="localValue"
			:mode="type"
			:close-on-select="closeOnSelect"
			:searchable="searchable"
			:options="options"
			:placeholder="placeholder"
			:label="label"
			:track-by="trackBy"
			:disabled="disabled"
			:value-prop="trackBy"
			:object="object"
			:createTag="taggable"
			@tag="tag"
			@deselect="remove"
			@select="select"
			@close="$emit('close')"
		>
			<template v-slot:singlelabel="{ value }">
				<div class="multiselect-single-label">
					<img v-if="value.icon" class="select-label-icon" :src="value.icon" /> {{ value.name || value[''] }}
				</div>
			</template>

			<template v-slot:option="{ option }">
				<img v-if="option.icon" class="select-option-icon" :src="option.icon" /> {{ option.name || option[''] }}
			</template>
		</Multiselect>
	</div>
</template>

<script>
	import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'

	export default {
		name: 'AppMultiSelect',
		components: {
			Multiselect,
		},
		props: {
			id: {
				type: String,
				require: true,
				default: '',
			},
			value: {
				type: [Object, String, Array],
				require: true,
			},
			type: {
				type: String,
				require: false,
				default: 'tags',
			},
			options: {
				type: Array,
				require: true,
				default: () => [],
			},
			searchable: {
				type: Boolean,
				require: false,
				default: true,
			},
			closeOnSelect: {
				type: Boolean,
				require: false,
				default: true,
			},
			placeholder: {
				type: String,
				require: false,
				default: '',
			},
			label: {
				type: String,
				require: false,
				default: '',
			},
			trackBy: {
				type: String,
				require: false,
				default: '',
			},
			object: {
				type: Boolean,
				require: false,
				default: false,
			},
			disabled: {
				type: Boolean,
				require: false,
				default: false,
			},
			taggable: {
				// Can create new tags?
				type: Boolean,
				require: false,
				default: false,
			},
			error: {
				type: Boolean,
				default: false,
			},
		},
		model: {
			prop: 'value',
			event: 'valueChange',
		},
		computed: {
			localValue: {
				get: function getter() {
					return this.value
				},
				set: function setter(value) {
					this.$emit('valueChange', value)
				},
			},
		},
		mounted() {
			this.$emit('initialised')
		},
		methods: {
			tag(i) {
				this.$emit('tag', i)
			},
			select(value, option) {
				this.$emit('select', option)
			},
			remove(value, option) {
				this.$emit('remove', option)
			},
		},
	}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
	.app-multiselect::v-deep {
		height: 100%;
		font-family: inherit;
		.multiselect {
			height: 100%;
			&.is-open {
				.multiselect-input {
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 0px;
				}
			}

			.multiselect-input {
				height: 100%;
				border-radius: 8px;
				border: 1px solid #e8e8e8;
				.multiselect-tags {
					padding: 10px;
					border-radius: 8px;
					padding-bottom: 10px;
					column-gap: 10px;
					row-gap: 10px;
					margin-top: 0px;
					span {
						max-width: 100%;
					}
					.multiselect-tag {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						background-color: var(--stan-primary-light-color);
						color: var(--stan-primary-primary-color);
						border-radius: 8px;
						font-size: 0.875rem;
						line-height: 1.25;
						margin: 0;
						padding: 8px 10px;
						font-weight: 400;
						font-family: Inter;
					}
					.multiselect-tag i:before {
						color: var(--stan-primary-primary-color);
					}
					.multiselect-search {
						max-width: 100%;
						input {
							max-width: 100%;
							margin-bottom: 0px;
							font-family: Inter, sans;
							font-size: 0.875rem;
						}
					}
				}
				.multiselect-clear {
					display: none !important;
				}
				.multiselect-placeholder {
					font-size: 0.875rem;
					line-height: 1.25rem;
					color: var(--stan-text-light-color) !important;
				}
			}

			.multiselect-options {
				box-shadow: var(--stan-box-shadow);
				background: #fff;
				width: 100%;
				max-height: 240px;
				overflow: auto;
				border: 1px solid #e8e8e8;
				border-top: none;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				margin-top: 0px;

				.multiselect-option {
					font-family: 'Inter', sans;
					font-size: 0.875rem;
					display: block;
					padding: 12px;
					min-height: 48px;
					line-height: 24px;
					text-decoration: none;
					text-transform: none;
					vertical-align: middle;
					position: relative;
					cursor: pointer;
					background-color: #fff;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: 100%;
					color: var(--stan-text-dark-color);

					&.is-pointed:hover {
						background: var(--stan-primary-primary-color);
						color: #fff;
					}
				}
			}

			.select-option-icon {
				min-width: 24px;
				max-width: 24px;
				border-radius: 4px;
				aspect-ratio: 1;
				margin-right: 8px;
			}
		}
	}

	.form-error::v-deep {
		.multiselect-tags {
			border: none;
			background-color: var(--stan-negative-light-color);
		}
		.multiselect .multiselect-input {
			border: 1px solid var(--stan-danger);
		}
	}
</style>
