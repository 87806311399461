var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 zapier-connect d-flex align-items-center" },
    [
      _c("img", {
        staticClass: "col-auto px-0",
        attrs: { src: "/images/zapier_logo.png" }
      }),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0 ml-2 col px-0" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm.$t("Connect to hundreds of other platforms using")) +
            "\n\t\t"
        ),
        _c("span", { on: { click: _vm.connectZapier } }, [_vm._v("Zapier")])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }