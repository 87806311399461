var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stan-device course-page-preview" }, [
    _c(
      "div",
      {
        staticClass:
          "site-preview-section mx-auto funnel-page-preview store-theme-page"
      },
      [
        _c("div", { staticClass: "site__prev__masking" }, [
          _c("div", { staticClass: "store-layout" }, [
            _c("div", { key: "basic", staticClass: "course-wrapper" }, [
              _vm.showOutline
                ? _c("div", { staticClass: "outline-wrapper" }, [
                    _vm.bannerImage
                      ? _c(
                          "div",
                          { staticClass: "image-wrapper" },
                          [
                            _c("AppImage", {
                              staticClass: "banner-image",
                              attrs: { src: _vm.bannerImage }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.course.data.title
                      ? _c("h1", { staticClass: "course-title" }, [
                          _vm._v(_vm._s(_vm.course.data.title))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.course.data.description
                      ? _c(
                          "div",
                          { staticClass: "description" },
                          [
                            _vm.course.data.description
                              ? _c("TipTapViewer", {
                                  staticClass: "px-5-mod ",
                                  attrs: { "read-only": "" },
                                  model: {
                                    value: _vm.course.data.description,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.course.data,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression: "course.data.description"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _c(
                    "div",
                    {
                      staticClass: "lesson-details",
                      class: { "module-type-video": _vm.lessonHasVideo }
                    },
                    [
                      _vm.lessonHasVideo
                        ? _c("Video", {
                            key: "video",
                            staticClass: "pages-block py-6",
                            attrs: {
                              "video-src": _vm.currentLesson.data.video,
                              "fcp-complete": true,
                              videoWidth: 410,
                              videoHeight: 248
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.hideVideo
                        ? _c("div", { staticClass: "video-placeholder mb-6" }, [
                            _vm._m(0)
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("h1", { staticClass: "lesson-title" }, [
                        _vm._v(_vm._s(_vm.currentLesson.data.title))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "description" },
                        [
                          _vm.currentLesson.data.description
                            ? _c("TipTapViewer", {
                                staticClass: "mb-5 px-5-mod ",
                                attrs: { "read-only": "" },
                                model: {
                                  value: _vm.currentLesson.data.description,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.currentLesson.data,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "currentLesson.data.description"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentLesson.data.digital_assets.length
                            ? _c(
                                "div",
                                { staticClass: "lesson-support-wrapper" },
                                [
                                  _c(
                                    "h3",
                                    { staticClass: "lesson-support-title" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("Support Materials"))
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.currentLesson.data.digital_assets,
                                      function(asset, index) {
                                        return _c("li", { key: index }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "asset.url",
                                                download: "true"
                                              }
                                            },
                                            [_vm._v(_vm._s(asset.name))]
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "course-sidebar-mobile-trigger" },
                [
                  _c("InlineSvg", {
                    staticClass: "burger-menu-icon",
                    attrs: { src: "/images/preview-icons/menu-burger.svg" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "para-1 text-bold font-ellipsis" },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.showOutline
                              ? _vm.course.data.title
                              : _vm.currentLesson.data.title
                          ) +
                          "\n\t\t\t\t\t\t"
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video-button-wrapper" }, [
      _c("div", { staticClass: "video-icon" }, [
        _c("img", {
          attrs: { src: "https://assets.stanwith.me/graphics/video-play.svg" }
        })
      ]),
      _vm._v(" "),
      _c("span", [_vm._v("No Video Yet")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }