var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "creator-pro-modal-overlay" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-wrap justify-content-center h-100" },
      [
        _c("div", { staticClass: "creator-pro-modal container" }, [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-content-center flex-wrap h-100"
            },
            [
              _c(
                "p",
                { staticClass: "modal-heading", attrs: { align: "center" } },
                [_vm._v("Welcome to Creator Pro! 🚀")]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "modal-subheading", attrs: { align: "center" } },
                [
                  _vm._v(
                    "\n\t\t\t\t\tFor the next 14 days you will have access to all of Stan's features! At the end of the trial your account will automatically\n\t\t\t\t\tgo back to Creator. You can upgrade to Creator Pro anytime!\n\t\t\t\t"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "AppButton",
                {
                  staticClass: "button-wrapper mx-auto",
                  attrs: { name: "close-creator-pro-trial-started-modal" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Get back to it!")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "close close-modal",
                  attrs: { "data-dismiss": "modal", "aria-label": "Close" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [
                  _c("InlineSvg", {
                    staticClass: "close-icon",
                    attrs: { src: "/images/icons/ic-close.svg" }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }