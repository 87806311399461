var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.tick, staticClass: "module-form" },
    [
      _vm.validation.data.title.$error
        ? _c("div", {
            staticClass: "form-group mb-3",
            class: { "form-error": _vm.validation.data.title.$error }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Draggable",
        _vm._b(
          {
            staticClass: "lessons-wrapper",
            attrs: {
              options: { delay: 100, delayOnTouchOnly: true, group: "lessons" },
              draggable: ".lesson-card",
              handle: ".lesson-card"
            },
            on: { change: _vm.onLessonsMoved },
            model: {
              value: _vm.lessons,
              callback: function($$v) {
                _vm.lessons = $$v
              },
              expression: "lessons"
            }
          },
          "Draggable",
          { animation: 0, ghostClass: "ghost" },
          false
        ),
        _vm._l(_vm.lessons, function(lesson, i) {
          return _c(
            "div",
            {
              key: "lesson-" + lesson.lesson_id,
              staticClass: "lesson-card cursor-pointer",
              attrs: { name: "lesson-" + lesson.lesson_id },
              on: {
                click: function($event) {
                  return _vm.selectLesson(i)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "lesson-icon" },
                [
                  _c("InlineSvg", {
                    staticStyle: { height: "18px" },
                    attrs: { src: "/images/six-dots.svg", alt: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-truncate col pl-0" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(lesson.data.title || "Lesson " + (i + 1)) +
                    "\n\t\t\t"
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-auto d-flex align-items-center" }, [
                lesson.status === 2
                  ? _c(
                      "div",
                      { staticClass: "pr-2" },
                      [
                        _c("AppTag", {
                          attrs: {
                            label: "Draft",
                            backgroundColor: "rgba(143, 156, 185, 0.2)",
                            textColor: "#8F9CB9",
                            image: "/images/draft.svg"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("InlineSvg", {
                      staticClass: "forward-arrow",
                      attrs: { src: "/images/back-arrow.svg" }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "px-3" },
        [
          _c(
            "AppButton",
            {
              staticClass: "w-100 p-0",
              attrs: { name: "add-lesson-course-module-form", outline: "" },
              on: {
                click: function($event) {
                  return _vm.addLesson(true)
                }
              }
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.$t("+ Add Lesson")) + "\n\t\t")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }