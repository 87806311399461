var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { preview: _vm.preview },
      attrs: { id: "digital-download-preview-wrapper" }
    },
    [
      _c("div", { staticClass: "content-list" }, [
        _c("div", { staticClass: "content-item" }, [
          _c(
            "div",
            { staticClass: "item-icon" },
            [
              _c("Icon", {
                staticClass: "icon-svg",
                attrs: { name: "digital-download", scale: 0.5 }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-title" }, [
            _vm._v(_vm._s(_vm.fileName))
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }