var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: {
        id: "booking-reschedule-modal",
        action: _vm.actionText,
        title: _vm.headingText,
        subheading: _vm.subheadingText,
        "secondary-action": _vm.secondaryActionText,
        secondaryActionColor: "light",
        overflow: true,
        loading: _vm.loading
      },
      on: {
        close: _vm.closeModal,
        cta: _vm.nextStep,
        "secondary-cta": _vm.closeModal
      }
    },
    [
      _c(
        "Transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.step === 1
            ? [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "slot-section d-flex flex-wrap gap w-100 justify-content-start align-items-start pb-3"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-50 " },
                        [
                          _c("label", { staticClass: "control-label mb-1" }, [
                            _vm._v("Date")
                          ]),
                          _vm._v(" "),
                          _c("AppFlatPickr", {
                            ref: "calendar",
                            attrs: {
                              custom_class: "form-control",
                              placeholder: "Select Date",
                              disableMobile: true,
                              enable_dates: _vm.availableDates,
                              loading: _vm.loadingAvailabilty
                            },
                            on: {
                              onMonthChange: _vm.onMonthChange,
                              onDateSelect: _vm.onDateSelected
                            },
                            model: {
                              value: _vm.date,
                              callback: function($$v) {
                                _vm.date = $$v
                              },
                              expression: "date"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "para-3",
                              staticStyle: { "min-width": "85px" }
                            },
                            [
                              _vm.errors.date
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "var(--stan-text-negative-color)"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.errors.date))]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "slot-section d-flex flex-wrap gap w-100 justify-content-start align-items-start pb-3"
                    },
                    [
                      _c("div", { staticClass: "flex-50" }, [
                        _c("label", { staticClass: "control-label mb-1" }, [
                          _vm._v("From")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "datetime-dropdown new" },
                          [
                            _c("img", {
                              staticClass: "input-icon mr-2",
                              attrs: { src: "/images/balance-time.svg" }
                            }),
                            _vm._v(" "),
                            _c("AppDropdown", {
                              staticClass: "form-control",
                              attrs: {
                                placeholder: "Start Time",
                                options: _vm.fromTimeOptions,
                                searchable: false,
                                label: "name",
                                reduce: "value",
                                rightAlign: false,
                                disabled: _vm.fromTimeOptions.length < 1
                              },
                              on: { select: _vm.onTimeSlotSelected },
                              model: {
                                value: _vm.start_time,
                                callback: function($$v) {
                                  _vm.start_time = $$v
                                },
                                expression: "start_time"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "para-3",
                            staticStyle: { "min-width": "85px" }
                          },
                          [
                            _vm.errors.time
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--stan-text-negative-color)"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.errors.time))]
                                )
                              : _vm._e()
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex-50" }, [
                        _c("label", { staticClass: "control-label mb-1" }, [
                          _vm._v("To")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "datetime-dropdown new" },
                          [
                            _c("img", {
                              staticClass: "input-icon mr-2",
                              attrs: { src: "/images/balance-time.svg" }
                            }),
                            _vm._v(" "),
                            _c("AppDropdown", {
                              staticClass: "form-control",
                              attrs: {
                                placeholder: "End Time",
                                searchable: false,
                                options: _vm.toTimeOptions,
                                label: "name",
                                reduce: "value",
                                rightAlign: false,
                                disabled: _vm.toTimeOptions.length < 2
                              },
                              model: {
                                value: _vm.end_time,
                                callback: function($$v) {
                                  _vm.end_time = $$v
                                },
                                expression: "end_time"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "para-3",
                            staticStyle: { "min-width": "85px" }
                          },
                          [
                            _vm.errors.time
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "var(--stan-text-negative-color)"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.errors.time))]
                                )
                              : _vm._e()
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { staticClass: "control-label" }, [
                      _vm._v("Reason (optional)")
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reason,
                          expression: "reason"
                        }
                      ],
                      staticClass: "form-control mb-2 mb-xl-0",
                      attrs: { placeholder: "Reason...", rows: "3" },
                      domProps: { value: _vm.reason },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.reason = $event.target.value
                        }
                      }
                    })
                  ])
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }