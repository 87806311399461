<template>
	<div>
		<file-drop-zone
			ref="order-file-upload"
			:options="uploadOptions"
			@start-upload="uploadingFile"
			@uploaded="data => fileUploaded(data)"
			@progress="payload => onUploadProgress(payload)"
			ghost
			object-type="only_max"
		/>
		<AppModal
			:id="id"
			:title="$t('Fulfill Your Order')"
			@close="closeModal"
			:disableAction="flag === 1"
			:action="$t('Fulfill Order')"
			@cta="fulfill_order"
		>
			<div class="model-subheadar w-full" v-if="transaction_data && transaction_data.data.title != ''">
				<h6>{{ transaction_data.data.title }}</h6>
			</div>
			<div class="user-box w-full">
				<span class="user_name" v-if="transaction_data && transaction_data.data.name != ''">
					<img src="/images/balence-user.svg" class="mr-2" />{{ transaction_data.data.name }}
				</span>
				<span class="user_name" v-if="transaction_data && transaction_data.data.note != '' && transaction_data.data.note != null">
					<img src="/images/icon-null.svg" class="mr-2" />{{ transaction_data.data.note }}
				</span>
			</div>
			<div class="mb-3 upload-file-box w-100">
				<div class="d-flex flex-wrap justify-content-center">
					<div>
						<div class="mt-4">
							<a
								class="upload-btn btn d-inline-flex align-items-center px-2 py-0 d-flex align-items-center"
								style="cursor: pointer"
								@click="addFile"
								><img src="/images/icon-laptop.svg" class="mr-2" />{{ $t('Upload Files') }}</a
							>
						</div>
						<div class="w-75 mx-auto mb-2" style="text-align: center">
							{{
								$t(
									'If you are uploading multiple files for this order, please combine them into a single .zip file and then upload it!'
								)
							}}
						</div>
					</div>
				</div>
			</div>
			<div class="pt-1 progress-bar-wrapper d-flex align-items-center w-100 p-2" v-if="flag > 0">
				<span class="file-name w-100 text-left d-flex align-items-center">
					<img src="/images/icon-frame.svg" class="mr-2" /><span>{{ fileName }}</span>
				</span>
				<div class="circle-progress-bar text-right" v-if="flag === 1">
					<div class="w-full d-flex align-items-center float-right justify-self-end">
						<span slot="legend-value" class="mr-2">{{ fileUploadProgress }}%</span>
						<vue-ellipse-progress
							:progress="fileUploadProgress"
							:legend="false"
							:thickness="3"
							color="#3A36DB"
							:legendFormatter="({ currentValue }) => currentValue + '%'"
							legendClass="legend-custom-style"
							:size="26"
						/>
					</div>
				</div>
				<div class="circle-progress-bar d-flex align-items-center col-auto px-0" v-if="flag === 2">
					<span class="text-green">{{ $t('Uploaded') }}</span>
					<img src="/images/icon-delete.svg" class="ml-2 cursor-pointer" @click="removeDownload" />
				</div>
			</div>
		</AppModal>
	</div>
</template>
<script>
	import Vue from 'vue'
	import VueEllipseProgress from 'vue-ellipse-progress'
	import axios from 'axios'
	import FileDropZone from '~/components/uploads/FileDropZone'

	Vue.use(VueEllipseProgress)

	export default {
		props: ['transaction_id', 'transaction_data', 'id'],
		name: 'FulfillOrderModal',
		components: {
			FileDropZone,
		},
		data() {
			return {
				flag: 0,
				fileUploadProgress: 0,
				fileData: null,
				fileName: '',
			}
		},
		computed: {
			uploadOptions() {
				return { scope: 'fulfillment', transaction_id: this.transaction_id }
			},
		},
		methods: {
			addFile() {
				this.flag = 0
				this.fileUploadProgress = 0
				this.$refs['order-file-upload'].addFile()
			},
			uploadingFile() {
				this.flag = 1
			},
			fileUploaded(fileData) {
				this.fileData = fileData
				this.flag = 2
				this.fileName = fileData.name
			},
			removeDownload() {
				this.flag = 0
				this.fileData = null
				this.fileUploadProgress = 0
			},
			onUploadProgress(payload) {
				this.flag = 1
				this.fileName = payload.name
				this.fileUploadProgress = payload.progress
			},
			fulfill_order() {
				const payload = { transaction_id: this.uploadOptions.transaction_id }
				if (this.fileData) {
					payload.file_name = this.fileData.name
					payload.file_url = this.fileData.src
				} else {
					payload.no_attachments = true
				}
				$(`#${this.id}`).modal('hide')
				axios
					.patch('v1/orders/attachments', payload)
					.then(() => {
						this.removeDownload()
						this.$emit('fulfill_order')
						this.$stanNotify({
							type: 'success',
							title: this.$t('Great!'),
							time: 2000,
							text: `${this.$t('This order is on its way to')} ${this.transaction_data.data.name}`,
						})
					})
					.catch(error => {
						this.$logError(error)
						this.$stanNotify({
							type: 'error',
							title: this.$t('Something went wrong'),
							time: 2000,
							text: this.$t('Please reach out to us at friends@stanwith.me.'),
						})
					})
			},
			resetData() {
				this.countfile = 0
				this.flag = 0
				this.fileUploadProgress = 0
			},
			closeModal() {
				this.resetData()
				this.$emit('closeFulFill')
			},
		},
	}
</script>

<style lang="scss" scoped>
	.modal {
		@media (max-width: 1024px) {
			padding-right: 0;
		}
		.modal-dialog {
			.modal-content {
				padding: 25px;
				.model-subheadar {
					h6 {
						font-style: normal;
						font-weight: bold;
						font-size: 14px;
						line-height: 19px;
						color: #314057;
					}
				}
				.user-box {
					.user_name,
					.text_msg {
						font-style: normal;
						font-weight: 600;
						font-size: 12px;
						line-height: 16px;
						color: var(--stan-blue-5);
						display: block;
						margin-bottom: 9px;
					}
				}
				.upload-file-box {
					border: 1px dashed var(--stan-gray-dark-color);
					box-sizing: border-box;
					box-shadow: 0px 1px 10px -2px rgba(62, 98, 134, 0.1);
					border-radius: 5px;
					padding: 32px;
					margin: 20px auto;
					text-align: center;
					font-style: normal;
					font-weight: 600;
					font-size: 11px;
					line-height: 15px;
					text-align: center;
					color: var(--stan-blue-5);
					.upload-btn {
						border: 2px solid var(--stan-primary-primary-color);
						border-radius: 8px;
						font-size: 15px;
						color: var(--stan-primary-primary-color);
						margin-bottom: 20px;
						font-weight: 700;
						i {
							font-weight: bold;
							font-size: 18px;
						}
					}
				}
			}
			.progress-bar-wrapper {
				border: 1px solid var(--stan-gray-dark-color);
				border-radius: 10px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				color: #3b485a;
				.file-name {
					i {
						font-size: 24px;
						color: var(--stan-primary-primary-color);
					}
				}
				.circle-progress-bar {
					.text-green {
						color: var(--stan-persian-green-3);
					}
					.text-red {
						color: var(--stan-danger);
					}
					.div-hide {
						display: none;
					}
					.legend-custom-style {
						left: -45px !important;
					}
					.cursor-pointer {
						cursor: pointer;
					}
					img {
						margin-right: 10px;
					}
				}
			}
		}
	}
</style>
