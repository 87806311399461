var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.message.response_id && _vm.message.response_id !== "def"
      ? _c(
          "div",
          {
            staticClass: "feedback-wrapper d-flex",
            class: {
              "has-feedback":
                _vm.message.feedback_status === -1 ||
                _vm.message.feedback_status === 1
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "feedback-button mr-1",
                class: { selected: _vm.message.feedback_status === 1 },
                on: {
                  click: function($event) {
                    return _vm.sendMessageFeedback(_vm.message, 1)
                  }
                }
              },
              [
                _c("InlineSvg", {
                  staticClass: "no-feedback",
                  attrs: { src: "/images/icons/ic-thumbs-up-outline.svg" }
                }),
                _vm._v(" "),
                _c("InlineSvg", {
                  staticClass: "chosen",
                  attrs: { src: "/images/icons/ic-thumbs-up-solid.svg" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "feedback-button mr-1",
                class: { selected: _vm.message.feedback_status === -1 },
                on: {
                  click: function($event) {
                    return _vm.sendMessageFeedback(_vm.message, -1)
                  }
                }
              },
              [
                _c("InlineSvg", {
                  staticClass: "no-feedback",
                  attrs: { src: "/images/icons/ic-thumbs-down-outline.svg" }
                }),
                _vm._v(" "),
                _c("InlineSvg", {
                  staticClass: "chosen",
                  attrs: { src: "/images/icons/ic-thumbs-down-solid.svg" }
                })
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }