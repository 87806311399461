var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: {
        id: "modal-audience-additional-details",
        title: "Additional Details"
      },
      on: { close: _vm.closeModal }
    },
    [
      _vm.additionalDetails.questions
        ? _c("div", [
            _c(
              "div",
              { staticClass: "color-gray" },
              _vm._l(_vm.additionalDetails.questions, function(row) {
                return _c("div", { key: row.id, staticClass: "mb-3 w-100" }, [
                  _c("h4", [_vm._v(_vm._s(row.question))]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(row.answer))])
                ])
              }),
              0
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }