var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column gap-8px w-100" }, [
    _c(
      "div",
      {
        staticClass: "w-100 d-flex justify-content-between align-items-center"
      },
      [
        _c("span", { staticClass: "para-3 text-bold text-dark" }, [
          _vm._v(_vm._s(_vm.header))
        ]),
        _vm._v(" "),
        typeof _vm.characterLimit === "number"
          ? _c("span", { staticClass: "para-3 text-light" }, [
              _vm._v(
                _vm._s(_vm.searchQuery.length) +
                  " / " +
                  _vm._s(_vm.characterLimit)
              )
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "w-100 position-relative d-flex flex-col gap-12px",
        on: {
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.handleKeyDown("down")
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.handleKeyDown("up")
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.handleEnterKey.apply(null, arguments)
            }
          ]
        }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "w-100 d-flex flex-row gap-8px flex-wrap align-items-center input-container",
            class: { disabled: _vm.disabled }
          },
          [
            _vm._l(_vm.selectedItems, function(item, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticClass:
                    "para-3 text-bold item d-flex gap-4px align-items-center"
                },
                [
                  _vm._v("\n\t\t\t\t" + _vm._s(item[_vm.label]) + "\n\t\t\t\t"),
                  !_vm.disabled
                    ? _c("InlineSvg", {
                        staticClass: "remove-tag-icon",
                        attrs: { src: "/images/icons/ic-close.svg" },
                        on: {
                          click: function($event) {
                            return _vm.handleRemoveSelectedItem(i)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery"
                }
              ],
              ref: "inputElement",
              staticClass: "para-3 flex-grow-1 input-text",
              staticStyle: { outline: "none" },
              attrs: {
                type: "text",
                placeholder: _vm.placeholder,
                disabled: _vm.disabled
              },
              domProps: { value: _vm.searchQuery },
              on: {
                focus: _vm.handleInputFocus,
                blur: _vm.handleInputBlur,
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k(
                      $event.keyCode,
                      "backspace",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  return _vm.handleBackspace.apply(null, arguments)
                },
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchQuery = $event.target.value
                  },
                  _vm.handleInputChange
                ]
              }
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("Transition", { attrs: { name: "fade" } }, [
          _vm.isInputFocused
            ? _c(
                "div",
                {
                  ref: "dropdownContainer",
                  staticClass: "dropdown",
                  style: {
                    height: _vm.dropdownHeight,
                    "overflow-y":
                      _vm.filteredItems.length > 3 ? "scroll" : "auto"
                  }
                },
                [
                  _vm.filteredItems.length === 0
                    ? _c("div", { staticClass: "d-flex flex-column " }, [
                        _vm.canAddNewItem &&
                        !_vm.filteredItems.find(function(item) {
                          return item.label === _vm.searchQuery
                        }) &&
                        _vm.searchQuery.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "para-3 d-flex gap-4px justify-content-center add-new-container",
                                attrs: { disabled: _vm.disabled },
                                on: { click: _vm.handleAddNewTag }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.addNewItemPrefixCopy) +
                                    " "
                                ),
                                _c("span", { staticClass: "text-bold" }, [
                                  _vm._v('"' + _vm._s(_vm.searchQuery) + '"')
                                ])
                              ]
                            )
                          : _vm._e()
                      ])
                    : _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _vm._l(_vm.filteredItems, function(item, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                ref: "listItemRefs",
                                refInFor: true,
                                staticClass:
                                  "para-3 text-bold list-item-container d-flex justify-content-between align-items-center",
                                class: {
                                  "is-pointed": i === _vm.selectedIndex
                                },
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function($event) {
                                    return _vm.handleSelectItem(item)
                                  },
                                  mouseenter: function($event) {
                                    return _vm.handleMouseEnter(i)
                                  },
                                  mouseleave: _vm.handleMouseLeave
                                }
                              },
                              [
                                _c("div", { staticClass: "item" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(item[_vm.label]) +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.selectedIndex === i
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "para-4 d-flex gap-4px align-items-center enter-to-select"
                                      },
                                      [
                                        _c("InlineSvg", {
                                          staticClass: "enter-icon",
                                          attrs: {
                                            src:
                                              "/images/icons/ic-enter-press.svg"
                                          }
                                        }),
                                        _vm._v(" Enter to select\n\t\t\t\t\t\t")
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.canAddNewItem &&
                          !_vm.filteredItems.find(function(item) {
                            return item[_vm.label] === _vm.searchQuery
                          }) &&
                          _vm.searchQuery.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "para-3 d-flex justify-content-center text-primary-color gap-4px add-new-container",
                                  attrs: { disabled: _vm.disabled },
                                  on: { click: _vm.handleAddNewTag }
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.addNewItemPrefixCopy) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "text-bold" }, [
                                    _vm._v('"' + _vm._s(_vm.searchQuery) + '"')
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                ]
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }