var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "account-disable-alert account-disable-warning" },
    [
      _c("h5", { staticClass: "account-disable-alert-text" }, [
        _c("span", [
          _c("b", [
            _vm._v(_vm._s(_vm.$t("Your account has been deactivated.")))
          ]),
          _vm._v(
            " " +
              _vm._s(_vm.$t("To reactivate your account, please contact")) +
              "\n\t\t\t"
          ),
          _c(
            "a",
            {
              staticClass: "mailto",
              attrs: { href: "mailto:friends@stanwith.me" }
            },
            [_vm._v("friends@stanwith.me")]
          ),
          _vm._v(".\n\t\t")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }