var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: {
        title: _vm.header,
        id: _vm.id,
        action: "Submit",
        loading: _vm.loading,
        disableAction: _vm.insufficientBalanceForRefund,
        footerNote: _vm.footerNote
      },
      on: { cta: _vm.refundOrder, open: _vm.recordAnalytics }
    },
    [
      _vm.insufficientBalanceForRefund
        ? _c("div", { staticClass: "d-flex flex-column gap-15px" }, [
            _c(
              "div",
              {
                staticClass:
                  "w-100 d-flex text-light justify-content-between align-items-center"
              },
              [
                _c("span", { staticClass: "para-2 text-light" }, [
                  _vm._v(
                    "Uh oh! It looks like your account balance is too low to process this refund. Please reach out to\n\t\t\t\t"
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "mailto",
                      attrs: { href: "mailto:friends@stanwith.me" }
                    },
                    [_vm._v("friends@stanwith.me")]
                  ),
                  _vm._v(" for support.")
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column gap-15px refund-modal-body" },
        [
          !_vm.insufficientBalanceForRefund
            ? _c("div", { staticClass: "d-flex flex-column gap-20px" }, [
                _c("div", { staticClass: "header-total-container" }, [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row gap-2px" },
                      [
                        _c(
                          "div",
                          { staticClass: "para-2 text-light text-bold" },
                          [_vm._v("Your Customer Will Receive")]
                        ),
                        _vm._v(" "),
                        _c(
                          "AppTooltip",
                          {
                            staticClass: "d-flex align-items-center",
                            attrs: {
                              click: "",
                              tip:
                                "<a href='https://help.stan.store/article/43-limit-quantity-discount-codes' target='_blank'>Learn more</a>",
                              html: true
                            }
                          },
                          [
                            _c("InlineSvg", {
                              staticStyle: { height: "12px" },
                              attrs: { src: "./images/income/help.svg" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "para-4 text-light text-italic" },
                      [_vm._v("In approximately 5-10 business days")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticStyle: { "font-weight": "600" } }, [
                    _vm._v(_vm._s(_vm.orderRefundData.grossAmount))
                  ])
                ]),
                _vm._v(" "),
                _vm.paymentGateway !== "paypal"
                  ? _c("div", { staticClass: "d-flex flex-column gap-20px" }, [
                      _c("div", {
                        staticClass: "w-100",
                        staticStyle: {
                          height: "1px",
                          "background-color": "var(--stan-gray-strike-color)"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "para-2 text-light text-bold" },
                        [_vm._v("Refund Breakdown")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column gap-8px" },
                        [
                          _c("CashOutTimeLineDateRow", {
                            attrs: {
                              amount: _vm.orderRefundData.netRevenue,
                              title: "Your Net Revenue"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column gap-8px" },
                        [
                          _c("CashOutTimeLineDateRow", {
                            attrs: {
                              amount:
                                "+" + _vm.orderRefundData.processingFeeAmount,
                              title: _vm.orderRefundData.refundFeeLabel
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }