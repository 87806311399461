var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppDropdownMenu",
    {
      ref: "main-dropdown",
      staticClass: "m-2",
      class: { disabled: _vm.disabled },
      attrs: { keepOpen: "" },
      on: {
        showDropdown: _vm.showFilter,
        hideDropdown: _vm.applyFilter,
        bgClick: _vm.bgClick
      },
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "justify-content-between px-3 mx-0 align-items-center py-1 my-0 dropdown-container"
                },
                [
                  ["text", "number"].includes(_vm.type)
                    ? [
                        _c(
                          "div",
                          { staticClass: "w-100 my-2" },
                          [
                            _c("AppDropdown", {
                              ref: "inner-dropdown",
                              staticClass: "form-control",
                              attrs: {
                                options: _vm.operators,
                                placeholder: "Select",
                                searchable: false,
                                "manual-control": true
                              },
                              model: {
                                value: _vm.inputOperator,
                                callback: function($$v) {
                                  _vm.inputOperator = $$v
                                },
                                expression: "inputOperator"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.type === "text"
                          ? _c("AppInput", {
                              attrs: { placeholder: _vm.label },
                              on: {
                                blur: _vm.bgClick,
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.applyFilter(true)
                                }
                              },
                              model: {
                                value: _vm.inputValue,
                                callback: function($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type === "number"
                          ? _c("AppInput", {
                              attrs: { placeholder: _vm.label, type: "number" },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.applyFilter(true)
                                }
                              },
                              model: {
                                value: _vm.inputValue,
                                callback: function($$v) {
                                  _vm.inputValue = $$v
                                },
                                expression: "inputValue"
                              }
                            })
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "date"
                    ? _c(
                        "div",
                        { staticClass: "stan-flatpickr-theme" },
                        [
                          _c("FlatPickr", {
                            staticClass: "form-control datepicker w-100 my-2",
                            attrs: {
                              placeholder: "Start Date",
                              disableMobile: true,
                              config: {
                                monthSelectorType: "static",
                                static: true,
                                disableMobile: true,
                                altInput: true,
                                altFormat: "M j, Y"
                              }
                            },
                            model: {
                              value: _vm.inputValue,
                              callback: function($$v) {
                                _vm.inputValue = $$v
                              },
                              expression: "inputValue"
                            }
                          }),
                          _vm._v(" "),
                          _c("FlatPickr", {
                            staticClass: "form-control datepicker w-100",
                            attrs: {
                              placeholder: "End Date",
                              disableMobile: true,
                              config: {
                                monthSelectorType: "static",
                                static: true,
                                disableMobile: true,
                                altInput: true,
                                altFormat: "M j, Y"
                              }
                            },
                            model: {
                              value: _vm.secondInputValue,
                              callback: function($$v) {
                                _vm.secondInputValue = $$v
                              },
                              expression: "secondInputValue"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "select"
                    ? _c(
                        "div",
                        { staticClass: "w-100 my-2" },
                        [
                          _c("AppDropdown", {
                            key: _vm.dropdownKey,
                            ref: "inner-select-dropdown",
                            staticClass: "form-control",
                            attrs: {
                              options: _vm.customDropdownOptions,
                              placeholder: "Select " + _vm.label,
                              searchable: true,
                              "manual-control": true
                            },
                            on: {
                              select: function($event) {
                                return _vm.applyFilter(true)
                              }
                            },
                            model: {
                              value: _vm.dropdownSelection,
                              callback: function($$v) {
                                _vm.dropdownSelection = $$v
                              },
                              expression: "dropdownSelection"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type !== "select"
                    ? _c(
                        "AppButton",
                        {
                          staticClass: "my-2 w-100",
                          attrs: { name: "apply-app-filter-" + _vm.label },
                          on: {
                            click: function($event) {
                              return _vm.applyFilter(true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" + _vm._s(_vm.$t("Apply")) + "\n\t\t\t"
                          )
                        ]
                      )
                    : _vm._e()
                ],
                2
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { staticClass: "chip", class: { selected: _vm.value } }, [
        _c("img", {
          staticClass: "icon",
          class: { rotate: !!_vm.isComplete },
          attrs: { src: "/images/icons/ic-integrations.svg" },
          on: { click: _vm.reset }
        }),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "chip-text para-2 text-truncate d-inline-block" },
          [
            _vm._v("\n\t\t\t" + _vm._s(_vm.label) + "\n\t\t\t"),
            _vm.isComplete
              ? _c("span", { staticClass: "separator" }, [_vm._v(" | ")])
              : _vm._e(),
            _vm._v(" "),
            _vm.isComplete
              ? _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.displayValue))
                ])
              : _vm._e()
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }