import root from './root.js'
import auth from '../pages/auth/routes.js'
import onboarding from '../pages/onboarding/routes.js'
import general from '../pages/general/routes.js'
import customer from '../pages/creator/routes.js'
import settings from '../pages/settings/routes.js'
import stores from '../pages/stores/routes.js'
import bookings from '../pages/appointments/routes.js'
import income from '../pages/income/routes.js'
import analytics from '../pages/analytics/routes.js'
import audience from '../pages/audience/routes.js'
import referrals from '../pages/referrals/routes.js'
import marketing from '../pages/marketing/routes.js'
import flows from '../pages/flows/routes.js'
import widgets from '../pages/widgets/routes.js'
import affiliate from '../pages/affiliate/routes.js'

const routes = root.concat(
	auth,
	onboarding,
	general,
	customer,
	settings,
	stores,
	bookings,
	income,
	analytics,
	audience,
	referrals,
	marketing,
	flows,
	widgets,
	affiliate
)

export default routes
