var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "typing-animation" }, [
      _c("div", { staticClass: "dot-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot-2" }),
      _vm._v(" "),
      _c("div", { staticClass: "dot-3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }