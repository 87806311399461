/* eslint-disable no-param-reassign */
/* eslint-disable linebreak-style */
/* eslint-disable no-shadow */
/* eslint-disable linebreak-style */
import axios from 'axios'

const MULTI_ACTION = 'MULTI_ACTION'
const AJAX_ERROR = 'AJAX_ERROR'

export const state = {
	integrations: {},
	userData: {},
	ajax_error: { message: '', errors: [] },
	isStripeConnected: undefined,
	isStripeAccountCreated: undefined,
	isStripeAccountRestricted: undefined,
	StripeRestrictedReason: undefined,
	isStripeChargesEnabled: false,
	isStripePayoutsEnabled: false,
	isStripeInstantPayoutEligible: false,
	stripeDefaultCurrency: undefined,
	isStripeAfterpayEnabled: false,
	stripeAfterpayDisabledReason: undefined,
	productFeedbackArea: undefined,
	videoPlayer: undefined,
	isPaypalConnected: undefined,
	isPaypalChargesEnabled: undefined,
	isStripeAllowedToRestart: undefined,
	stripeConnectedAccountStatus: undefined,
	isStripeMarketplaceConnected: undefined,
	isStanPaymentConnected: undefined,
	stanPaymentStatus: undefined,
}

export const getters = {
	getIntegrations: state => state.integrations,
	getUserData: state => state.userData,
	ajax_error: state => state.ajax_error,
	isStripeConnected: state => state.isStripeConnected,
	isStripeAccountCreated: state => state.isStripeAccountCreated,
	isStripeAccountRestricted: state => state.isStripeAccountRestricted,
	StripeRestrictedReason: state => state.StripeRestrictedReason,
	isStripeChargesEnabled: state => state.isStripeChargesEnabled,
	isStripePayoutsEnabled: state => state.isStripePayoutsEnabled,
	isStripeInstantPayoutEligible: state => state.isStripeInstantPayoutEligible,
	isStripeAllowedToRestart: state => state.isStripeAllowedToRestart,
	stripeDefaultCurrency: state => state.stripeDefaultCurrency,
	isStripeAfterpayEnabled: state => state.isStripeAfterpayEnabled,
	stripePaymentPlanDisabledReason: state => state.stripePaymentPlanDisabledReason,
	stripeCountryDefaultCurrency: state => state.stripeCountryDefaultCurrency,
	getProductFeedbackArea: state => state.productFeedbackArea,
	getVideoPlayer: state => state.videoPlayer,
	isPaypalConnected: state => state.isPaypalConnected,
	isPaypalChargesEnabled: state => state.isPaypalChargesEnabled,
	isPaypalEmailConfirmed: state => state.isPaypalChargesEnabled,
	getStripeConnectedAccountStatus: state => state.stripeConnectedAccountStatus,
	isStripeMarketplaceConnected: state => state.isStripeMarketplaceConnected,
	isStanPaymentConnected: state => state.isStanPaymentConnected,
	getStanPaymentStatus: state => state.stanPaymentStatus,
}

export const mutations = {
	setIntegrations(state, integrations) {
		state.integrations = integrations
		state.isPaypalConnected = integrations.paypal?.status
		state.isPaypalChargesEnabled = integrations.paypal?.data?.payments_receivable && integrations.paypal?.data?.primary_email_confirmed
		state.isPaypalEmailConfirmed = integrations.paypal?.data?.primary_email_confirmed
		state.isStripeMarketplaceConnected = integrations['stripe-marketplace']?.status
		state.isStanPaymentConnected = integrations['stan-payment']?.status
	},
	setStanPaymentStatus(state, stanPaymentStatus) {
		state.stanPaymentStatus = stanPaymentStatus
	},
	setStripeStatus(state, stripeStatus) {
		state.isStripeConnected = stripeStatus.connected
		state.isStripeAccountCreated = stripeStatus.created
		state.isStripeChargesEnabled = stripeStatus.charges_enabled
		state.StripeRestrictedReason = stripeStatus.account_restricted_reason
		state.isStripePayoutsEnabled = stripeStatus.payouts_enabled
		state.isStripeInstantPayoutEligible = stripeStatus.instant_payout_eligible
		state.stripeDefaultCurrency = stripeStatus.default_currency
		state.isStripeAfterpayEnabled = stripeStatus.capabilities.afterpay_enabled
		state.stripePaymentPlanDisabledReason = stripeStatus.payment_plan_disabled_reason
		state.stripeCountryDefaultCurrency = stripeStatus.country_default_currency
		state.isStripeAccountRestricted = stripeStatus.account_restricted
		state.isStripeAllowedToRestart = stripeStatus.allow_to_restart
		state.stripeConnectedAccountStatus = stripeStatus.account_status
	},
	setProductFeedbackArea(state, productFeedbackArea) {
		state.productFeedbackArea = productFeedbackArea
	},

	setVideoPlayer(state, payload) {
		state.videoPlayer = {
			uri: payload.uri,
			title: payload.title,
			placement: payload.placement,
		}
	},

	[MULTI_ACTION](state, payload) {
		state.userData = payload
	},

	[AJAX_ERROR](state, payload) {
		const message = payload?.message || payload?.response?.data?.message || ''
		const errors = payload?.errors || payload?.response?.data?.errors || []
		state.ajax_error = {
			message,
			errors,
		}
	},
}

export const actions = {
	fetchIntegrations: async ({ commit }) => {
		try {
			const res = await axios.get('v1/integrations')
			commit('setIntegrations', res?.data)
		} catch (error) {
			commit(AJAX_ERROR, error)
		}
	},
	fetchStripeConnected: async ({ commit }) => {
		try {
			const res = await axios.get('v1/integrations/stripe/connect')
			commit('setStripeStatus', res?.data)
		} catch (error) {
			commit(AJAX_ERROR, error)
		}
	},
	fetchStanPayment: async ({ commit }) => {
		try {
			const res = await axios.get('v1/integrations/stan-payment')
			commit('setStanPaymentStatus', res?.data)
		} catch (error) {
			commit(AJAX_ERROR, error)
		}
	},
	submitFormSetting: async ({ commit }, payload) => {
		try {
			const res = await axios.post('v1/users/profile-page-setup', payload)

			commit(MULTI_ACTION, res?.data)
		} catch (error) {
			commit(AJAX_ERROR, error)
		}
	},
	updateProductFeedbackArea: ({ commit }, productFeedbackArea) => {
		commit('setProductFeedbackArea', productFeedbackArea)
	},
}
