var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppModal", {
    staticClass: "delete-modal",
    attrs: {
      id: "delete-product-modal",
      action: "Yes, Delete Product",
      title: "Just Confirming!",
      subheading: _vm.subheading
    },
    on: { close: _vm.closeModal, cta: _vm.deleteProduct }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }