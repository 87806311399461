var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mobile-apps-wrapper flex-column d-none",
        class: { "d-flex": _vm.showNavigation }
      },
      [
        _vm.filterAppItems.length
          ? _c("div", { staticClass: "mobile-apps-header h3 text-bold" }, [
              _vm._v("\n\t\t\tStan Apps\n\t\t")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.filterAppItems.length
          ? _c(
              "div",
              { staticClass: "mobile-apps" },
              _vm._l(_vm.filterAppItems, function(app) {
                return _c(
                  "div",
                  {
                    key: app.slug,
                    staticClass: "app",
                    class: { open: _vm.activeRoute == app.route }
                  },
                  [
                    _c(
                      "RouterLink",
                      {
                        staticClass: "cursor-pointer",
                        attrs: {
                          to: app.href ? app.href : { name: app.route },
                          "data-test-id": "left-menu-" + app.title,
                          target: app.href ? "_blank" : ""
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.resetMenu.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "app-icon" },
                          [
                            _c("InlineSvg", {
                              staticClass: "menu-item-icon",
                              attrs: {
                                id: app.icon,
                                src: "/images/left-menu/" + app.icon + ".svg"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "app-text para-2 text-bold" },
                          [_vm._v(_vm._s(app.title))]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mobile-apps-header h3 text-bold mobile-apps-more-header"
          },
          [_vm._v("\n\t\t\tMore\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mobile-admin-apps" },
          [
            _vm._l(_vm.filterAdminAppItems, function(app) {
              return _c(
                "div",
                {
                  key: app.slug,
                  staticClass: "app",
                  class: { open: _vm.activeRoute == app.route }
                },
                [
                  _c(
                    "RouterLink",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        to: app.href ? app.href : { name: app.route },
                        "data-test-id": "left-menu-" + app.title,
                        target: app.href ? "_blank" : ""
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.resetMenu.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "app-icon" },
                        [
                          _c("InlineSvg", {
                            staticClass: "menu-item-icon",
                            attrs: {
                              id: app.icon,
                              src: "/images/icons/" + app.icon + ".svg"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "app-text para-2 text-bold" }, [
                        _vm._v(_vm._s(app.title))
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.filterAcconutAppItems, function(app) {
              return _c(
                "div",
                {
                  key: app.slug,
                  staticClass: "app",
                  class: { open: _vm.activeRoute == app.route }
                },
                [
                  _c(
                    "RouterLink",
                    {
                      staticClass: "cursor-pointer",
                      attrs: {
                        to: app.href ? app.href : { name: app.route },
                        "data-test-id": "left-menu-" + app.title,
                        target: app.href ? "_blank" : ""
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.resetMenu.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "app-icon" },
                        [
                          _c("InlineSvg", {
                            staticClass: "menu-item-icon",
                            attrs: {
                              id: app.icon,
                              src: "/images/icons/" + app.icon + ".svg"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "app-text para-2 text-bold" }, [
                        _vm._v(_vm._s(_vm.renderAppTitle(app.title)))
                      ])
                    ]
                  )
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("AccountMenu", { staticClass: "w-100" })
          ],
          2
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "desktop-apps-wrapper d-none",
        class: { "d-lg-flex": _vm.showDesktop }
      },
      [
        _c(
          "div",
          { staticClass: "stan-apps" },
          _vm._l(_vm.filterAppItems, function(app) {
            return _c(
              "div",
              { key: app.slug, staticClass: "app" },
              [
                _c(
                  "RouterLink",
                  {
                    staticClass: "cursor-pointer",
                    class: { open: _vm.activeRoute == app.route },
                    attrs: {
                      to: app.href ? app.href : { name: app.route },
                      "data-test-id": "left-menu-" + app.title,
                      target: app.href ? "_blank" : ""
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.resetMenu.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "app-icon-text" }, [
                      _c(
                        "div",
                        { staticClass: "app-icon" },
                        [
                          _c("InlineSvg", {
                            staticClass: "menu-item-icon",
                            attrs: {
                              id: app.icon,
                              src: "/images/left-menu/" + app.icon + ".svg"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "app-text" }, [
                        _c(
                          "div",
                          { staticClass: "h3 text-bold text-dark" },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(app.title) +
                                "\n\t\t\t\t\t\t\t\t"
                            ),
                            _vm.isCurrentItemPinned(app)
                              ? _c("InlineSvg", {
                                  staticStyle: {
                                    width: "16px",
                                    height: "16px"
                                  },
                                  attrs: {
                                    src: "/images/icons/ic-pin-filled.svg"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            app.new
                              ? _c(
                                  "span",
                                  { staticClass: "new para-3 text-bold" },
                                  [_vm._v("NEW")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "para-2 text-light" }, [
                          _vm._v(_vm._s(app.subTitle))
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "app-dropdown" },
                  [
                    _c("AppToggle", {
                      key: _vm.toggleKey,
                      staticClass: "mr-2",
                      attrs: {
                        val: _vm.isCurrentItemPinned(app),
                        disabled: _vm.getToggleDisabled(app)
                      },
                      on: {
                        valueChange: function($event) {
                          return _vm.toggleApp(app)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.filterAdminAppItems.length > 0
          ? _c("div", { staticClass: "admin-apps-header h2 text-bold" }, [
              _vm._v("\n\t\t\tStan Admin\n\t\t")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "admin-apps" },
          _vm._l(_vm.filterAdminAppItems, function(app) {
            return _c(
              "div",
              { key: app.slug, staticClass: "app" },
              [
                _c(
                  "RouterLink",
                  {
                    staticClass: "cursor-pointer",
                    class: { open: _vm.activeRoute == app.route },
                    attrs: {
                      to: app.href ? app.href : { name: app.route },
                      "data-test-id": "left-menu-" + app.title,
                      target: app.href ? "_blank" : ""
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.resetMenu.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "app-icon-text" }, [
                      _c(
                        "div",
                        { staticClass: "app-icon" },
                        [
                          _c("InlineSvg", {
                            staticClass: "menu-item-icon",
                            attrs: {
                              id: app.icon,
                              src: "/images/left-menu/" + app.icon + ".svg"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "app-text" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h3 text-bold text-dark d-flex align-items-center"
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" + _vm._s(app.title) + " "
                            ),
                            _vm.isCurrentItemPinned(app)
                              ? _c("InlineSvg", {
                                  attrs: {
                                    src: "/images/icons/ic-pin-filled.svg"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "para-2 text-light" }, [
                          _vm._v(_vm._s(app.subTitle))
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "app-dropdown" },
                  [
                    _c("AppToggle", {
                      key: _vm.toggleKey,
                      staticClass: "mr-2",
                      attrs: {
                        val: _vm.isCurrentItemPinned(app),
                        disabled: _vm.getToggleDisabled(app)
                      },
                      on: {
                        valueChange: function($event) {
                          return _vm.toggleApp(app)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }