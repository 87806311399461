var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-wrapper" }, [
    _c("div", { staticClass: "table-container" }, [
      _c("table", [_vm._t("default")], 2)
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "paginate-container",
        class: { "bg-white border-top": _vm.$slots.paginate }
      },
      [_vm._t("paginate")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }