var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hollow-dots-spinner", style: _vm.spinnerStyle },
    _vm._l(_vm.dotsStyles, function(ds, index) {
      return _c("div", { key: index, staticClass: "dot", style: ds })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }