import Image from '@tiptap/extension-image'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import ResizableImageTemplate from './ResizableImageTemplate'
// ...
export default Image.extend({
	name: 'ResizableImage',
	addAttributes() {
		return {
			// Inherit all the attrs of the Image extension
			...this.parent?.(),
			style: {
				default: 'max-width: 100%; display: block; margin: 10px auto 10px 0px;',
				renderHTML: attributes => ({
					style: attributes.style,
				}),
			},
		}
	},
	addNodeView() {
		return VueNodeViewRenderer(ResizableImageTemplate)
	},
})
