var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AppModal", {
    attrs: {
      id: "grid-add-warning-modal",
      title: "Uh-oh!",
      subheading:
        "Unfortunately, Email Magnets cannot yet be placed inside of Grid sections. We suggest changing the layout to Stack, or moving the product to a different section!",
      action: "OK"
    },
    on: { cta: _vm.closeModal, close: _vm.closeModal }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }