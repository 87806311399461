var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "AppModal",
        {
          attrs: {
            id: "username-update-warning",
            ariaLabelledby: "userUpdateWarningModal",
            title: _vm.$t("Let's confirm!")
          }
        },
        [
          _c("div", { staticClass: "px-3 pb-3" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap justify-content-center" },
              [
                _c(
                  "p",
                  {
                    staticClass: "subheader mx-auto",
                    staticStyle: { "max-width": "400px" },
                    attrs: { align: "center" }
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.$t("You're about to change your username.")
                        ) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Make sure to update your Stan Store URL and your Affiliate Link:"
                        )
                      ) + "\n\t\t\t\t"
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-wrap justify-content-center mt-3"
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.$t("Stan Store")))])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap justify-content-center" },
                  [
                    _c("span", { staticClass: "link" }, [
                      _vm._v(_vm._s(_vm.storeLink))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-wrap justify-content-center mt-2"
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.$t("Affiliate Link")))])]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap justify-content-center" },
                  [
                    _c("span", { staticClass: "link" }, [
                      _vm._v(_vm._s(_vm.affiliateLink))
                    ])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _c(
                  "AppButton",
                  {
                    staticClass: "w-100",
                    attrs: {
                      name: "save-username-change-modal",
                      size: "large",
                      type: "submit",
                      "data-test-id": "submit-button"
                    },
                    on: { click: _vm.cta }
                  },
                  [_vm._v(_vm._s(_vm.$t("Update")))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }