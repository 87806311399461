var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        "min-height": "62px",
        "border-radius": "8px",
        display: "flex",
        "justify-content": "space-between",
        padding: "15px",
        "align-items": "center"
      },
      style: {
        "background-color": _vm.uploadFailed
          ? "var(--stan-negative-light-color)"
          : "var(--stan-gray-soft-color)"
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: { display: "flex", gap: "10px", "align-items": "center" }
        },
        [
          _vm.isUploading
            ? _c("InlineSvg", {
                staticClass: "status-icon rotating",
                attrs: { src: "/images/icons/ic-reload.svg" }
              })
            : _vm.isErrored
            ? _c("InlineSvg", {
                staticClass: "status-icon",
                attrs: {
                  src: "/images/icons/ic-warning-circle-filled-negative.svg"
                }
              })
            : _c("InlineSvg", {
                staticClass: "status-icon",
                attrs: {
                  src: "/images/icons/ic-check-circle-filled-positive.svg"
                }
              }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "para-2 text-bold",
              class: {
                "text-light": _vm.isUploading,
                "stan-text-danger": _vm.uploadFailed
              }
            },
            [_vm._v(_vm._s(_vm.fileName))]
          ),
          _vm._v(" "),
          _vm.isUploading
            ? _c("InlineSvg", {
                staticClass: "delete-file-icon",
                attrs: { src: "/images/icons/ic-trash.svg" },
                on: { click: _vm.deleteFile }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.isUploading
        ? _c("div", { staticClass: "para-3 text-light" }, [
            _c("span", { staticClass: "text-bold" }, [_vm._v("Uploading...")]),
            _vm._v(" (" + _vm._s(_vm.uploadProgress) + "%)")
          ])
        : _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                gap: "10px",
                "align-items": "center"
              }
            },
            [
              _vm.uploadFailed
                ? _c(
                    "span",
                    { staticClass: "para-3 stan-text-danger text-bold" },
                    [_vm._v("\n\t\t\tFailed to upload\n\t\t")]
                  )
                : _c(
                    "AppButton",
                    {
                      attrs: {
                        name: "download-uploaded-product",
                        size: "sm",
                        outline: true,
                        icon: "/images/icons/ic-digital-download.svg"
                      },
                      on: { click: _vm.downloadFile }
                    },
                    [_vm._v("\n\t\t\tDownload\n\t\t")]
                  ),
              _vm._v(" "),
              _c("InlineSvg", {
                staticClass: "delete-file-icon",
                attrs: { src: "/images/icons/ic-trash.svg" },
                on: { click: _vm.deleteFile }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }