var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      ref: "unsplashImagePickerModal",
      attrs: { large: "", title: _vm.$t("Choose Your Image") },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { staticClass: "full-height-container" }, [
        _c(
          "div",
          { staticClass: "my-2" },
          [
            _c("AppInput", {
              attrs: {
                icon: "/images/search.svg",
                iconWidth: "16px",
                placeholder: _vm.$t("Search Keywords"),
                name: "search"
              },
              model: {
                value: _vm.search,
                callback: function($$v) {
                  _vm.search = $$v
                },
                expression: "search"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "overflow-auto img-container" },
          [
            _c(
              "div",
              {
                staticClass:
                  "thumbnail-container image-uploader d-flex flex-column align-items-center justify-content-center px-3",
                class: { "dropZone-over": _vm.isDragging },
                attrs: { draggable: "" },
                on: {
                  dragover: function($event) {
                    $event.preventDefault()
                    return _vm.dragOver.apply(null, arguments)
                  },
                  dragleave: function($event) {
                    $event.preventDefault()
                    return _vm.dragLeave.apply(null, arguments)
                  },
                  drop: function($event) {
                    $event.preventDefault()
                    return _vm.drop($event)
                  }
                }
              },
              [
                _vm.isDragging
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "blue-circle d-flex justify-content-center align-items-center"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/images/down-arrow.svg",
                            alt: _vm.$t("down arrow")
                          }
                        })
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "d-flex flex-column align-items-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-center d-none d-sm-block d-none pb-1"
                          },
                          [
                            _c("div", [
                              _c(
                                "div",
                                { staticClass: "placeholder-value pb-1" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.$t("Drag Image Here")) +
                                      "\n\t\t\t\t\t\t\t"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "placeholder-value " }, [
                                _vm._v(_vm._s(_vm.$t("OR")))
                              ])
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "d-flex upload-btn align-items-center",
                            on: { click: _vm.uploadImage }
                          },
                          [
                            _c("img", {
                              staticClass: "mr-1 mr-sm-2 ",
                              attrs: { src: "/images/add-image-icon.svg" }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(_vm.$t("Upload Image")))
                            ])
                          ]
                        )
                      ]
                    )
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.unsplashImages, function(image) {
              return _c(
                "div",
                { key: image.id, staticClass: "thumbnail-container" },
                [
                  _c("img", {
                    staticClass: "thumbnail",
                    attrs: { src: image.urls.small },
                    on: {
                      click: function($event) {
                        if ($event.target !== $event.currentTarget) {
                          return null
                        }
                        return _vm.imageSelected(image)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "user-name-text",
                      on: {
                        click: function($event) {
                          return _vm.visitUser(image)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(image.user.first_name) +
                          " " +
                          _vm._s(image.user.last_name)
                      )
                    ]
                  )
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-2 loading-container" },
              [
                _vm.hasMore && !_vm.loading
                  ? _c(
                      "AppButton",
                      {
                        staticClass: "mx-auto",
                        attrs: {
                          name: "load-more-unsplash-image-picker-modal",
                          outline: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.getNextPage()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Load More")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loading
                  ? _c("HollowDotsSpinner", {
                      staticClass: "d-flex align-items-center mx-auto my-3",
                      attrs: {
                        "animation-duration": 1000,
                        "dot-size": 10,
                        "dots-num": 3,
                        color: "#3a36db"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }