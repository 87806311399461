import { event as gtagEvent } from 'vue-gtag'
/* eslint-disable import/extensions */
import { configSettings } from '~/plugins/config-settings.js'

import store from '~/store'
import { getFlags } from '~/plugins/feature-flags.js'
import { getSessionUUID, getCookieUUID } from '../store/modules/Global'

const apiUrl = configSettings.analyticsApi

function trigger(eventName, options) {
	const isAdminLoggedInAsUser = store.getters['Auth/isAdminLoggedInAsUser']
	if (isAdminLoggedInAsUser) return
	const payload = {}
	payload.n = eventName
	payload.u = window.location.href
	payload.r = window.document.referrer || null
	payload.w = window.innerWidth
	if (options && options.meta) {
		payload.m = options.meta
	}
	if (!('m' in payload)) {
		payload.m = {}
	}
	payload.m.platform = {
		oscpu: navigator.oscpu,
		vendor: navigator.vendor,
		platform: navigator.platform,
		cpuClass: navigator.cpuClass,
		appName: navigator.appName,
		appVersion: navigator.appVersion,
		codeName: navigator.appCodeName,
		userAgent: navigator.userAgent,
	}
	if (options && options.props) {
		payload.p = options.props
	}

	const request = new XMLHttpRequest()
	request.open('POST', apiUrl, true)
	request.setRequestHeader('Content-Type', 'application/json')
	request.send(JSON.stringify(payload))

	// google analytics
	const flattenOptions = { ...payload.m, ...payload.p }
	Object.keys(flattenOptions).forEach(v => {
		if (v.includes('-')) {
			flattenOptions[v.replace('-', '_')] = flattenOptions[v]
			delete flattenOptions[v]
		}
	})
	gtagEvent(eventName, flattenOptions)

	request.onreadystatechange = () => {
		if (request.readyState === 4) {
			options && options.callback && options.callback()
		}
	}

	if (window.clarity) {
		window.clarity('set', eventName, '1')
	}
}

export default async function stanAnalytics(name, opts = {}) {
	const meta = opts.meta || {}
	const props = opts.props || {}
	// Plugin default metadata
	meta.ip = store.getters['Global/getIPAddress']
	meta.sessionUUID = getSessionUUID()
	meta.cookieUUID = getCookieUUID()

	props.experiments = getFlags()
	// Disabled Fingerprint until figure out the payment and plan details
	// meta.visitorId = await getVisitorID()
	// eslint-disable-next-line no-undef
	return trigger(name, { meta, props })
}
