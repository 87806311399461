var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "menu-wrapper w-100 h-100 rounded desktop-menu d-none d-lg-block",
        attrs: { id: "dropdown-wrapper-account-desktop" }
      },
      [
        _c(
          "AppPopup",
          {
            ref: "popup",
            attrs: {
              "aria-labelledby": "dropdownMenuLink-account",
              triggerRef: "popup-trigger"
            }
          },
          [
            _c("div", { staticClass: "menu-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-start align-items-center h4 text-bold current-account"
                },
                [
                  _c("img", {
                    staticClass: "mr-3 menu-item-icon",
                    attrs: { src: _vm.getProfileImage(_vm.profileImage) }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c("p", { staticClass: "col pl-0 m-0 username" }, [
                      _vm._v(_vm._s(_vm.username))
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "col pl-0 m-0 email para-3" }, [
                      _vm._v(_vm._s(_vm.email))
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "other-accounts-wrapper" },
                [
                  _vm._l(_vm.accounts, function(data, key) {
                    return [
                      key != _vm.current_account_key
                        ? _c(
                            "div",
                            {
                              key: key,
                              staticClass:
                                "dropdown-item d-flex justify-content-start align-items-center my-0 h4 text-bold other-accounts  mb-4",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.switchAccounts(key)
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "mr-3 menu-item-icon",
                                attrs: { src: _vm.getProfileImage(data.image) }
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "col pl-0 m-0 username" },
                                  [_vm._v(_vm._s(data.username))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "col pl-0 m-0 email para-3" },
                                  [_vm._v(_vm._s(data.email))]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-item d-flex justify-content-start align-items-center my-0 h4 text-bold p-0"
                    },
                    [
                      _c("InlineSvg", {
                        staticClass: "mr-2 menu-item-icon",
                        attrs: { src: "/images/icons/ic-integrations.svg" }
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "col pl-0",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.addAccount.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v("Add Account")]
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "mb-4 mt-4" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-item d-flex justify-content-start align-items-center my-0 h4 text-bold p-0"
                },
                [
                  _c("InlineSvg", {
                    staticClass: "mr-2 menu-item-icon",
                    attrs: { src: "/images/icons/ic-logout.svg" }
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "col pl-0",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.logoutText))]
                  )
                ],
                1
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "popup-trigger",
            staticClass: "dropdown h-100 dropup",
            attrs: {
              id: "dropdownMenuLink-account",
              "aria-haspopup": "true",
              "aria-expanded": "false"
            },
            on: { click: _vm.togglePopup }
          },
          [
            _c("div", { staticClass: "my-0 menu-item-link h4 text-bold" }, [
              _c("img", {
                staticClass: "mr-2 menu-item-icon",
                attrs: { src: _vm.getProfileImage(_vm.profileImage) }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "col pl-0 m-0" }, [
                _vm._v(_vm._s(_vm.username))
              ])
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mobile-menu w-100" },
      [
        _c(
          "div",
          {
            staticClass: "mobile-apps-header h3 text-bold w-100",
            staticStyle: { "margin-top": "60px" }
          },
          [_vm._v("\n\t\t\tAccounts\n\t\t")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "app current-account-mobile" }, [
          _c(
            "a",
            {
              staticClass:
                "d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold"
            },
            [
              _c("img", {
                staticClass: "mr-2 menu-item-icon",
                attrs: { src: _vm.getProfileImage(_vm.profileImage) }
              }),
              _vm._v(" "),
              _c("div", [
                _c("p", { staticClass: "col pl-0 m-0 username app-text" }, [
                  _vm._v(_vm._s(_vm.username))
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "col pl-0 m-0 email app-text para-3" }, [
                  _vm._v(_vm._s(_vm.email))
                ])
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm._l(_vm.accounts, function(data, key) {
          return [
            key != _vm.current_account_key
              ? _c(
                  "div",
                  {
                    key: key,
                    staticClass: "app",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.switchAccounts(key)
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold"
                      },
                      [
                        _c("img", {
                          staticClass: "mr-2 menu-item-icon",
                          attrs: { src: _vm.getProfileImage(data.image) }
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "p",
                            { staticClass: "col pl-0 m-0 username app-text" },
                            [_vm._v(_vm._s(data.username))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "col pl-0 m-0 email app-text para-3"
                            },
                            [_vm._v(_vm._s(data.email))]
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]
        }),
        _vm._v(" "),
        _c("div", { staticClass: "app" }, [
          _c(
            "a",
            {
              staticClass:
                "d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addAccount.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "app-icon" },
                [
                  _c("InlineSvg", {
                    staticClass: "menu-item-icon",
                    attrs: { src: "/images/icons/ic-integrations.svg" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "app-text para-2 text-bold text-dark" },
                [_vm._v("\n\t\t\t\t\tAdd Account\n\t\t\t\t")]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "app" }, [
          _c(
            "a",
            {
              staticClass:
                "d-flex justify-content-start align-items-center my-0 menu-item-link h4 text-bold",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.logout.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "app-icon" },
                [
                  _c("InlineSvg", {
                    staticClass: "menu-item-icon",
                    attrs: { src: "/images/icons/ic-logout.svg" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "app-text para-2 text-bold text-dark" },
                [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.logoutText) + "\n\t\t\t\t")]
              )
            ]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }