var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-wrapper" }, [
    _vm.isAdminLoggedInAsUser
      ? _c(
          "div",
          {
            staticClass: "d-flex justify-content-end mb-2",
            staticStyle: { color: "red" }
          },
          [
            _vm._v(
              "\n\t\tTemporarily logged in as user [" +
                _vm._s(_vm.user.user_id) +
                "] [" +
                _vm._s(_vm.user.username) +
                "]\n\t"
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user
      ? _c("nav", { staticClass: "navbar d-flex" }, [
          _c(
            "div",
            { staticClass: "logo d-lg-none d-flex col-auto p-0" },
            [
              _c("RouterLink", { attrs: { to: { name: "dashboard" } } }, [
                _c("img", {
                  staticClass: "mr-2",
                  attrs: { src: "/images/stan-logo-text.svg" }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-none d-lg-inline-flex" },
            [
              _vm.$route.meta.breadcrumbs
                ? _c("Breadcrumb", {
                    attrs: { route: _vm.$route },
                    on: { activeTab: _vm.activeTab }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.user && _vm.user.username
            ? _c(
                "div",
                {
                  staticClass:
                    "ml-sm-auto sharelinkmobile tooltip-share-storelink px-0 col col-sm-auto d-flex justify-content-end align-items-center"
                },
                [
                  !_vm.isAffiliateOnly
                    ? _c(
                        "p",
                        {
                          key: _vm.displayUrl,
                          staticClass:
                            "text-truncate mb-0 mr-sm-3 d-flex align-items-center",
                          staticStyle: { "text-align": "right" }
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-truncate h4-md h5 text-bold text-primary-color",
                              attrs: { href: _vm.displayUrl, target: "_blank" }
                            },
                            [_vm._v(_vm._s(_vm.removeHttps(_vm.displayUrl)))]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isAffiliateOnly
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard",
                              value: _vm.copyUrl,
                              expression: "copyUrl"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.clipboardSuccessHandler,
                              expression: "clipboardSuccessHandler",
                              arg: "success"
                            }
                          ],
                          staticClass: "share-button-navbar stan-icon-wrapper"
                        },
                        [
                          _c("InlineSvg", {
                            staticClass:
                              "copy-icon stan-icon stan-icon-primary",
                            attrs: { src: "/images/icons/ic-copy.svg" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "d-none d-sm-inline-block h5 text-bold vertical-align-middle"
                            },
                            [_vm._v(_vm._s(_vm.$t("Copy")))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }