var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stan-device preview-type-page" }, [
    _c(
      "div",
      {
        staticClass:
          "site-preview-section mx-auto funnel-page-preview store-theme-page",
        class: _vm.rootClasses
      },
      [
        _vm.showPreview
          ? _c(
              "Transition",
              { attrs: { name: "fade-transform", appear: "", mode: "out-in" } },
              [
                !_vm.isCheckout
                  ? _c(
                      "div",
                      { staticClass: "page-container relative" },
                      [
                        !_vm.isFirst
                          ? _c(
                              "div",
                              {
                                staticClass: "page-highlight-topbar py-3",
                                style: _vm.userBackground
                              },
                              [
                                _vm.pageData.top_banner_text
                                  ? _c("TipTapViewer", {
                                      staticClass: "ProseMirror_readonly",
                                      attrs: { "read-only": "" },
                                      model: {
                                        value: _vm.pageData.top_banner_text,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.pageData,
                                            "top_banner_text",
                                            $$v
                                          )
                                        },
                                        expression: "pageData.top_banner_text"
                                      }
                                    })
                                  : _c("span", [
                                      _c("h3", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(_vm.$t("WAIT!")) +
                                            "\n\t\t\t\t\t\t"
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t(
                                                "Your Order Is Not Yet Complete!"
                                              )
                                            )
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _vm.product.price.sale_amount
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "Here’s a ONE TIME DISCOUNT!"
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("Banner", {
                          staticClass: "mb-4 pages-block",
                          attrs: {
                            title: _vm.product.title,
                            image: _vm.bannerImage,
                            "background-color": _vm.userPrimaryColor,
                            "show-back": _vm.showBackButton
                          }
                        }),
                        _vm._v(" "),
                        _vm.isStockManaged
                          ? _c(
                              "div",
                              {
                                key: "stock_quantity",
                                staticClass: "product-stock px-5-mod",
                                class: {
                                  "low-stock": _vm.isLowStock,
                                  "out-of-stock": !_vm.isInStock
                                }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t" +
                                    _vm._s(_vm.quantity_text) +
                                    "\n\t\t\t\t"
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isInStock
                          ? _c("Price", {
                              staticClass: "px-5-mod mb-3 pages-block",
                              attrs: {
                                currency: _vm.user.data.currency,
                                amount: _vm.product.price.amount,
                                "sale-amount": _vm.product.price
                                  .sale_amount_available
                                  ? _vm.product.price.sale_amount
                                  : 0,
                                interval: _vm.product.price.interval,
                                "accent-color": _vm.userSecondaryColor
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.description
                          ? _c("TipTapViewer", {
                              staticClass:
                                "mb-4 px-5-mod description ProseMirror_readonly pages-block",
                              attrs: {
                                "read-only": "",
                                "accent-color": _vm.userSecondaryColor
                              },
                              model: {
                                value: _vm.product.description,
                                callback: function($$v) {
                                  _vm.$set(_vm.product, "description", $$v)
                                },
                                expression: "product.description"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.pageData.video
                          ? _c("Video", {
                              staticClass: "py-4 pages-block",
                              attrs: {
                                "video-src": _vm.pageData.video,
                                "fcp-complete": true,
                                "video-width": 298,
                                "video-height": 300
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.secondBuiltInProduct
                          ? _c(
                              "div",
                              {
                                key: "one-time",
                                staticClass:
                                  "pages-block px-5-mod relative text-center mx-3 mb-3"
                              },
                              [
                                _c("div", { staticClass: "upsell" }, [
                                  _c(
                                    "strong",
                                    {
                                      staticClass:
                                        "stan-text-danger text-decoration-underline text-bold d-block"
                                    },
                                    [_vm._v("ONE TIME OFFER")]
                                  ),
                                  _vm._v(" "),
                                  _c("small", { staticClass: "d-block mb-1" }, [
                                    _c("span", [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.secondBuiltInProduct.data
                                              .product.title
                                          ) +
                                          " for just $" +
                                          _vm._s(
                                            _vm.secondBuiltInProduct.data
                                              .product.price
                                              .sale_amount_available
                                              ? _vm.secondBuiltInProduct.data
                                                  .product.price.sale_amount
                                              : _vm.secondBuiltInProduct.data
                                                  .product.price.amount
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                      _vm.secondBuiltInProduct.data.product
                                        .price.sale_amount_available
                                        ? _c("span", [
                                            _vm._v("("),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "text-decoration":
                                                    "line-through"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "$" +
                                                    _vm._s(
                                                      _vm.secondBuiltInProduct
                                                        .data.product.price
                                                        .amount
                                                    )
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\tValue)\n\t\t\t\t\t\t\t\t"
                                            )
                                          ])
                                        : _vm._e()
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "button",
                                      style: {
                                        background: _vm.userPrimaryColor
                                      }
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass: "v-icon blink",
                                          staticStyle: {
                                            color: "red",
                                            "background-color": "transparent"
                                          }
                                        },
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                width: "24",
                                                height: "24",
                                                viewBox: "0 0 24 24"
                                              }
                                            },
                                            [
                                              _c(
                                                "path",
                                                {
                                                  attrs: {
                                                    d:
                                                      "M4 15V9h8V4.16L19.84 12L12 19.84V15H4z"
                                                  }
                                                },
                                                [
                                                  _c("animate", {
                                                    attrs: {
                                                      attributeType: "XML",
                                                      attributeName: "fill",
                                                      values:
                                                        "#800;#f00;#800;#800",
                                                      dur: "0.8s",
                                                      repeatCount: "indefinite"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.add_upsell_to_order,
                                            expression: "add_upsell_to_order"
                                          }
                                        ],
                                        attrs: {
                                          id: "upsell_check",
                                          disabled: !_vm.secondBuiltInProduct,
                                          type: "checkbox"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.add_upsell_to_order
                                          )
                                            ? _vm._i(
                                                _vm.add_upsell_to_order,
                                                null
                                              ) > -1
                                            : _vm.add_upsell_to_order
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.add_upsell_to_order,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.add_upsell_to_order = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.add_upsell_to_order = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.add_upsell_to_order = $$c
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "upsell_check" } },
                                        [
                                          _vm._v(
                                            " Yes, I'd like this One-Time-Offer! "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.product.reviews && _vm.product.reviews.length
                          ? _c("UserReviews", {
                              staticClass: "pages-block",
                              attrs: { reviews: _vm.product.reviews }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.pageData.bottom_title
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pages-block payments px-5-mod text-center mb-3"
                              },
                              [
                                _c("h4", [
                                  _c("span", { style: _vm.userFontColor }, [
                                    _vm._v(_vm._s(_vm.pageData.bottom_title))
                                  ])
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type == "meeting"
                          ? _c("Calendar", {
                              staticClass:
                                "orient-horizontal mb-5 px-5-mod pages-block",
                              attrs: {
                                "primary-color": _vm.userPrimaryColor,
                                "secondary-color": _vm.userSecondaryColor,
                                slug: _vm.product.meeting.slug,
                                "is-demo": true,
                                username: _vm.user.username || "",
                                availability: _vm.product.meeting.availability
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.type == "webinar"
                          ? _c("WebinarCalendar", {
                              staticClass:
                                "orient-horizontal mb-5 px-1 pages-block",
                              attrs: {
                                "primary-color": _vm.userPrimaryColor,
                                "secondary-color": _vm.userSecondaryColor,
                                slug: _vm.product.webinar.slug,
                                "is-demo": true,
                                username: _vm.user.username || "",
                                "preview-demo-sessions":
                                  _vm.previewWebinarSessions
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("Form", {
                          staticClass: "pages-block",
                          attrs: {
                            questions: _vm.questions,
                            "fcp-complete": true,
                            disabled: true
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "Transition",
                          {
                            attrs: {
                              name: "fade-transform",
                              appear: "",
                              mode: "out-in"
                            }
                          },
                          [
                            _c("div", { staticClass: "pages-block" }, [
                              _vm.grossAmount &&
                              _vm.product.price.discount_codes_available &&
                              _vm.isInStock
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "discount-section-wrapper mb-4"
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "mb-3 section-title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Discount Code"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "discount-code-wrapper input-box"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.discount_code,
                                                expression: "discount_code"
                                              }
                                            ],
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "Enter promo code here"
                                              ),
                                              size: "20"
                                            },
                                            domProps: {
                                              value: _vm.discount_code
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.discount_code =
                                                  $event.target.value
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("button", [
                                            _vm._v(_vm._s(_vm.$t("Apply")))
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "px-5-mod mb-4 relative" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "amount-wrapper" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-flex  justify-content-between align-items-center divide-bar w-100"
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "total pr-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("Total :"))
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.isInStock
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "total-am pl-2",
                                                    style: {
                                                      color:
                                                        _vm.userSecondaryColor
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.$t("Sold Out")
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                )
                                              : _vm.grossAmount > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "total-am pl-2",
                                                    style: {
                                                      color:
                                                        _vm.userSecondaryColor
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          _vm.formatCurrency(
                                                            _vm.grossAmount,
                                                            _vm.user.data
                                                              .currency,
                                                            "en-US",
                                                            true
                                                          )
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "total-am pl-2 ",
                                                    style: {
                                                      color:
                                                        _vm.userSecondaryColor
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(_vm.$t("FREE")) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    )
                                                  ]
                                                )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _vm.showPaypal || _vm.showAfterpay
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "payment-gateway-section-wrapper mb-4"
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "mb-3 section-title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Payment Method"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.showPaypal
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "payment-gateway-button-container",
                                              attrs: {
                                                id:
                                                  "payment-gateway-button-container"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-center cursor-pointer payment-gateway-button align-items-center"
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "img-fluid paypal-icon",
                                                    attrs: {
                                                      src:
                                                        "/images/paypal-logo-white.svg"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showAfterpay
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "payment-gateway-button-container",
                                              attrs: {
                                                id:
                                                  "payment-gateway-button-container"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-center cursor-pointer payment-gateway-button"
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "payment-method-img img-fluid",
                                                    attrs: {
                                                      src:
                                                        "/images/afterpay.svg"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "separator" }, [
                                        _vm._v("or")
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "px-4 submit-section" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "confirm-button mb-4",
                                      style: _vm.userBackground,
                                      attrs: {
                                        type: "submit",
                                        disabled:
                                          _vm.formSubmitting || !_vm.isInStock
                                      },
                                      on: { click: _vm.triggerSubmit }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "text-center abos-loader"
                                        },
                                        [
                                          _vm.formSubmitting
                                            ? _c("HollowDotsSpinner", {
                                                staticClass:
                                                  "d-flex align-items-center",
                                                attrs: {
                                                  "animation-duration": 1000,
                                                  "dot-size": 12,
                                                  "dots-num": 3,
                                                  color: "var(--stan-white)"
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.formSubmitting
                                            ? _c(
                                                "span",
                                                { staticClass: "white-clr" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.pageData
                                                        .submit_title ||
                                                        _vm.$t("PURCHASE")
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _vm.hasPrevious || _vm.hasNext
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "site__groupButton d-flex justify-content-between py-5"
                              },
                              [
                                _c("a", { staticClass: "site__prev" }, [
                                  _vm.hasPrevious
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.prevPage()
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Prev")))]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("a", { staticClass: "site__next" }, [
                                  _vm.hasNext
                                    ? _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.nextPage()
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("Next")))]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "Transition",
                          { attrs: { name: "fade-transform", mode: "out-in" } },
                          [
                            _c(
                              "div",
                              [
                                _c("Summary", {
                                  staticClass: "body-wrapper relative",
                                  attrs: {
                                    "banner-image":
                                      _vm.funnelData.checkout_image ||
                                      "/images/device/placeholder.svg",
                                    currency: _vm.user.data.currency,
                                    "user-primary-color": _vm.userPrimaryColor,
                                    products: _vm.purchasedProducts,
                                    heading: _vm.funnelData.checkout_heading,
                                    title: _vm.funnelData.checkout_title,
                                    button: _vm.funnelData.checkout_button_text
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }