var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new" },
    [
      _c("div", { staticClass: "py-3 my-3 lesson-form-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "form-group col-md-12 mb-1",
            class: { "form-error": _vm.validation.data.title.$error }
          },
          [
            _c("AppInput", {
              ref: "input-title",
              attrs: {
                label: _vm.$t("Lesson Title"),
                placeholder: _vm.$t("Lesson Name"),
                id: "title-" + _vm.index,
                maxlength: "100"
              },
              on: { blur: _vm.validation.data.title.$touch },
              model: {
                value: _vm.value.data.title,
                callback: function($$v) {
                  _vm.$set(_vm.value.data, "title", $$v)
                },
                expression: "value.data.title"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group col-md-12 mb-4" },
          [
            _c("label", { staticClass: "control-label mr-3 mb-1" }, [
              _vm._v("\n\t\t\t\t" + _vm._s(_vm.$t("Description")) + "\n\t\t\t")
            ]),
            _vm._v(" "),
            _c("TipTapEditor", {
              staticClass: "tiptap-editor flexible mb-3",
              model: {
                value: _vm.value.data.description,
                callback: function($$v) {
                  _vm.$set(_vm.value.data, "description", $$v)
                },
                expression: "value.data.description"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group col-md-12 mb-4" },
          [
            _c("FileDropZone", {
              staticClass: "py-0 mb-3 new",
              attrs: {
                image: _vm.value.data.video,
                title: _vm.$t("Video"),
                enableDownloadFile: !!_vm.value.data.video,
                "show-button-image": false,
                "drop-zone-placeholder": "Upload a lesson video here",
                "button-text": "Upload",
                accept: "video"
              },
              on: {
                "download-file": _vm.downloadLessonVideo,
                "start-upload": function() {
                  return _vm.uploadStarted("videoUploader")
                },
                "uploaded-all": function() {
                  return _vm.uploadCompleted("videoUploader")
                },
                uploaded: _vm.setVideo,
                "cancel-file": _vm.resetVideo,
                "delete-file": _vm.resetVideo
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group col-md-12 mb-0",
            class: { "form-error": _vm.validation.data.digital_assets.$error }
          },
          [
            _c("label", { staticClass: "control-label mb-0" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("Supporting Materials")) +
                  "\n\t\t\t"
              )
            ]),
            _vm._v(" "),
            _c("UploadFile", {
              staticClass: "new",
              attrs: {
                isRequired: true,
                fileData: _vm.value.data,
                showTypeChooser: false,
                customSubheading: _vm.$t(
                  "Upload any files to help your students complete this module"
                ),
                objectType: "Lesson"
              },
              on: {
                "start-upload": function() {
                  return _vm.uploadStarted("supportingMaterialsUploader")
                },
                "uploaded-all": function() {
                  return _vm.uploadCompleted("supportingMaterialsUploader")
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("PageControlButtons", {
        staticClass: "p-0 border-0",
        attrs: {
          status: _vm.value.status,
          publishing: _vm.loading,
          ready: !_vm.isUploading,
          "variant-to-use": "lesson"
        },
        on: {
          "save-page": _vm.savePage,
          "delete-page": _vm.deletelesson,
          "save-draft": _vm.saveDraft
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }