import userflow from 'userflow.js'

export default {
	InitializeUserflow() {
		userflow.init('ct_gn77pva5ajcwthimoqmhgcmdee')
	},
	UpdateUserflowIdentityData(user) {
		if (user) {
			userflow.identify(user.user_id, {
				name: user.full_name,
				username: user.username,
				email: user.email,
				userId: user.userId,
				signed_up_at: new Date(user.created_at * 1000).toISOString().split('T')[0],
			})
		}
	},
	UserflowCustomUpdate(event, options) {
		const obj = {}
		obj[event] = options
		userflow.updateUser(obj)
	},
	UserflowCustomBulkUpdate(obj) {
		// obj = { key: value }
		userflow.updateUser(obj)
	},

	TrackUserflowEvent(event, options) {
		if (userflow.isIdentified()) {
			userflow.track(event, options)
		}
	},
}
