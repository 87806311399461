<template>
	<div class="form-group">
		<DiscountCodeAdditionalOptionsModal @onChange="handleChange" :enableDuration="enableDuration" ref="discount-code-additonal-options" />
		<div class="d-flex align-items-center justify-content-start mb-2">
			<AppToggle class="mr-2" size="sm" v-model="value.discount_codes_available" />
			<label class="para-3 mb-0">Add Discount Code</label>
		</div>
		<div v-if="value.discount_codes_available">
			<div
				class="discount-code-wrapper funnel-row mb-5 mb-sm-2"
				:class="{ 'with-duration': enableDuration }"
				v-for="(discount_code, i) in value.discount_codes"
				:key="`discount_code-${i}`"
			>
				<div class="row">
					<div class="col-12 col-sm " :class="{ 'form-error': validation.discount_codes.$each[i].code.$error }">
						<AppInput
							:id="`discount_code_code_${i}`"
							label="Discount Code"
							input-class="mb-1"
							v-model="discount_code.code"
							placeholder="ex. FRI30"
						/>
						<div class="form-group" v-show="!validation.discount_codes.$each[i].code.alphaNum">
							<small class="stan-text-danger ">Discount code can only contain a-z, A-Z, 0-9 </small>
						</div>
					</div>
					<div class="col-12 col-sm" :class="{ 'form-error': validation.discount_codes.$each[i].value.$error }">
						<AppInput
							label="Discount %"
							input-class="mb-1"
							type="number"
							v-model.number="discount_code.value"
							placeholder="30%"
							:id="`discount_code_value_${i}`"
						/>
						<div class="form-group" v-if="!validation.discount_codes.$each[i].value.in_range_membership">
							<small class="stan-text-danger">Membership can not be free </small>
						</div>
					</div>
					<div class="col-12 col-sm form-error" v-if="isCodeExpired(discount_code.valid_until, discount_code.timezone)">
						<label class="control-label">Expiration </label>
						<div class="form-control expired">
							EXPIRED
						</div>
					</div>
					<div class="d-flex align-items-center justify-content-center ml-0">
						<div class="pt-3 ml-4">
							<InlineSvg src="/images/icons/ic-options.svg" class="settings_color" @click="addtionalDiscountCodeSettings(i)" />
						</div>

						<div class="pt-3 ml-4">
							<InlineSvg src="/images/icons/ic-trash.svg" @click="$emit('removeDiscountCode', i)" />
						</div>
					</div>
				</div>
			</div>
			<div class="form-group my-2" v-if="!validation.discount_codes.uniqueCodes">
				<small class="stan-text-danger">Codes are case-insensitive and must be unique!</small>
			</div>
			<AppButton name="add-discount-code" outline class="w-100" @click="$emit('addDiscountCode')"
				>+ Add<span v-if="value.discount_codes.length">&nbsp;Another</span>&nbsp;Discount Code
			</AppButton>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import DiscountCodeAdditionalOptionsModal from '~/components/modals/DiscountCodeAdditionalOptionsModal'

	export default {
		name: 'DiscountCodes',
		components: {
			DiscountCodeAdditionalOptionsModal,
		},
		props: {
			value: {
				type: Object,
				default: () => {},
			},
			validation: {
				type: Object,
				default: () => {},
			},
			enableDuration: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				selectedIndex: null,
			}
		},
		watch: {
			// eslint-disable-next-line func-names
			'value.discount_codes_available': function() {
				this.value.discount_codes = this.value.discount_codes.filter(d => d.code !== '' && d.value !== '')
			},
		},
		computed: {
			...mapGetters('Auth', ['user']),
		},
		methods: {
			isCodeExpired(expiryDate, timezone) {
				return expiryDate != null && this.$commonHelper.isDateInPast(expiryDate, timezone)
			},
			addtionalDiscountCodeSettings(index) {
				this.selectedIndex = index
				this.$refs['discount-code-additonal-options'].show(this.value.discount_codes[index])
			},
			handleChange(updatedProperties) {
				Object.assign(this.value.discount_codes[this.selectedIndex], updatedProperties)

				this.$stanNotify({
					type: 'success', // can be 'info', 'warning', 'success', 'error'
					title: 'Changes Applied!',
					text: 'Remember to save your changes.',
					duration: 5000,
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.discount-code-wrapper .row {
		width: 100%;
	}
	.disabled {
		background: var(--stan-oxford-blue-2);
		pointer-events: none;
	}
	input[id^='discount_code_code'] {
		text-transform: uppercase;
	}
	.btn-remove-discount {
		color: var(--stan-gray-dark-color);
	}
	.expired {
		display: flex;
		align-items: center;
		min-height: 48px;
		color: red;
	}
	.settings_color::v-deep {
		rect:not(.no-fill),
		path:not(.no-fill) {
			fill: var(--stan-primary-primary-color);
		}
	}

	svg {
		cursor: pointer;
	}
</style>
