var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overflow-visible" }, [
    _vm.showTitle
      ? _c("div", { staticClass: "pb-2" }, [
          _c("span", { staticClass: "text-bold text-dark h4" }, [
            _vm._v(_vm._s(_vm.$t("Tags")))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "overflow-visible" },
      [
        _c("AppNewMultiSelect", {
          attrs: {
            placeholder: _vm.placeholder,
            items: _vm.availableFanTags,
            addNewItemPrefixCopy: _vm.allowAddTag
              ? "+ Press Enter to create a new tag"
              : "",
            canAddNewItem: _vm.allowAddTag,
            characterLimit: _vm.allowAddTag ? 50 : null,
            disabled: _vm.disabled,
            selectedItems: _vm.fanTags,
            label: "name",
            trackBy: "name"
          },
          on: {
            customSelectFunction: _vm.selectTag,
            customRemoveFunction: _vm.removeTag
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }