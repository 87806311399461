<template>
	<div class="d-flex flex-row tip-container align-items-top p-3 mb-2" v-if="tip">
		<div class="icon-container">
			<img :src="iconSrc" class="tip-icon" :class="{ small: tip.smallIcon }" />
		</div>
		<div class="body-container d-flex flex-column mx-2" v-if="!isTemplate">
			<div class="body-title" v-if="tip.title">{{ tip.title }}</div>
			<div class="d-flex flex-nowrap mt-1" :class="bodyDescriptionClass">
				<div class="body-text">
					<span v-if="tip.description" class="mr-1" v-html="tip.description"></span>
					<span class="body-action" v-if="tip.actionText" v-on:click="doAction">{{ tip.actionText }}</span>
				</div>
			</div>
		</div>
		<div class="body-container d-flex flex-column mx-2" v-else>
			<div class="body-title">
				<slot name="title"></slot>
			</div>
			<div class="d-flex flex-nowrap mt-1" :class="bodyDescriptionClass">
				<div class="body-text">
					<span class="mr-1">
						<slot name="description"></slot>
					</span>
					<span class="body-action" v-on:click="doAction">
						<slot name="actionText"></slot>
					</span>
				</div>
			</div>
		</div>
		<div class="video-container px-1" v-if="tip.videoPreviewUrl" @click="playVideo">
			<img :src="tip.videoPreviewUrl" />
			<div class="icon-overlay">
				<img src="/images/learning/video-play-icon.svg" />
			</div>
		</div>
	</div>
</template>
<script>
	import { mapGetters, mapActions, mapMutations } from 'vuex'

	export default {
		props: {
			tipType: { type: String, default: '' },
			tipSource: { type: Object },
			isTemplate: { type: Boolean, default: false },
		},
		mounted() {},
		data() {
			return {
				isPlayingVideo: false,
				selectedVideoUri: undefined,

				tipData: {
					pricing_availability: {
						description: this.$t(
							'Drive more sales by leveraging scarcity and exclusivity with Discount Codes and our Limit Quantity feature.'
						),
						actionText: this.$t('Learn More'),
						actionType: 'open-link',
						actionData: { url: 'https://help.stan.store/article/43-limit-quantity-discount-codes' },
						smallIcon: true,
					},
					promo_video: {
						description: this.$t(
							'Customer testimonials will help build trust with your audience. Add 1-3 reviews to your product page to increase your sales conversions.'
						),
						smallIcon: true,
					},
					calendar_availability: {
						description: this.$t(
							'Sync with Google Calendar and add Out of Office blocks directly to it. Your availability in Stan will check Google first and will adjust accordingly.'
						),
						actionText: this.$t('Learn More'),
						actionType: 'open-link',
						actionData: { url: 'https://help.stan.store/faqs/what-if-my-calendar-availability-changes-from-week-to-week' },
						smallIcon: true,
					},
					webinar_booked: {
						description: this.$t(
							'Webinar details can not be changed once a session has been booked. If you wish to make these changes, create a new webinar.'
						),
						smallIcon: true,
					},
				},
			}
		},
		computed: {
			...mapGetters('Auth', ['user']),
			tip() {
				return this.tipSource ?? this.tipData[this.tipType]
			},
			iconSrc() {
				return this.tip.customIcon ?? '/images/info.svg'
			},
			bodyDescriptionClass() {
				return this.tip.title ? 'body-description' : 'body-description-titleless'
			},
		},
		methods: {
			...mapActions('Auth', ['fetchUser']),
			...mapMutations('SiteSettingsStore', ['setVideoPlayer']),

			playVideo() {
				this.setVideoPlayer({ uri: this.tip.videoUrl, title: this.tip.title, placement: 'tip' })
			},
			doAction() {
				switch (this.tip.actionType) {
					case 'play-video':
						this.playVideo()
						break
					case 'open-link':
						this.$emit('tip-action-triggered')
						this.$stanAnalytics('tip-link-opened', {
							meta: { user_id: this.user.user_id, username: this.user.username },
							props: { url: this.tip.actionData.url },
						})
						window.open(this.tip.actionData.url, '_blank')
						break
					default:
				}
			},
		},
	}
</script>

<style lang="scss">
	.tip-video-play-modal {
		.add-section-modal {
			background: none;
		}
	}
</style>

<style lang="scss" scoped>
	.tip-container {
		background: rgba(101, 116, 255, 0.1);
		border-radius: 8px;

		.icon-container {
			.tip-icon {
				min-width: 30px;
				&.small {
					width: 20px;
					min-width: 20px;
				}
			}
		}
		.body-container {
			flex-grow: 1;

			font-style: normal;
			letter-spacing: 0.04em;
			.body-title {
				font-weight: 700;
				font-size: 16px;
				line-height: 22px;
				color: var(--stan-primary-dark-color);
				@media (max-width: 767px) {
					font-size: 14px;
					line-height: 19px;
					letter-spacing: 0.04em;
				}
			}
			.body-description {
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				color: var(--stan-blue-5);
				@media (max-width: 767px) {
					letter-spacing: 0.04em;
				}

				.body-action {
					cursor: pointer;
					color: var(--stan-primary-primary-color);
				}
			}
			.body-description-titleless {
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				color: var(--stan-primary-dark-color);
				@media (max-width: 767px) {
					letter-spacing: 0.04em;
				}

				.body-action {
					cursor: pointer;
					color: var(--stan-primary-primary-color);
				}
			}
		}
		.video-container {
			// padding: 0;
			position: relative;
			cursor: pointer;

			& > img {
				min-width: 90px !important;
				max-width: 131px !important;
				object-fit: cover;
				border-radius: 8px;
			}
			.icon-overlay {
				position: absolute;
				top: 18px;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: start;
				justify-content: center;
			}
		}
	}
</style>
