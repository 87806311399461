var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppModal",
    {
      attrs: { id: "dripSettingModuleModal", title: "Drip Module" },
      on: { close: _vm.closeModal }
    },
    [
      _c("div", { staticClass: "mb-2" }, [
        _c("div", { staticClass: "sub-heading" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t(
                  "Your customers will be given access to published modules after a set number of days"
                )
              )
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group mb-4" },
        [
          _c("AppInput", {
            class: { "form-error": _vm.$v.temp_drip_settings.days.$invalid },
            attrs: {
              label: _vm.$t("Drip Days After Enrollment"),
              placeholder: _vm.$t("Ex. 4"),
              id: "dripDays",
              type: "number"
            },
            model: {
              value: _vm.temp_drip_settings.days,
              callback: function($$v) {
                _vm.$set(_vm.temp_drip_settings, "days", $$v)
              },
              expression: "temp_drip_settings.days"
            }
          }),
          _vm._v(" "),
          !_vm.$v.temp_drip_settings.days.validDateRange
            ? _c("span", { staticClass: "stan-text-danger para-3" }, [
                _vm._v("\n\t\t\tPlease enter a number between 0 and 60\n\t\t")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-2 d-flex align-items-top" },
        [
          _c("label", { staticClass: "control-label mr-2" }, [
            _vm._v(_vm._s(_vm.$t("Update Customers About Drip Module")))
          ]),
          _vm._v(" "),
          _c("AppToggle", {
            model: {
              value: _vm.temp_drip_settings.email_notification,
              callback: function($$v) {
                _vm.$set(_vm.temp_drip_settings, "email_notification", $$v)
              },
              expression: "temp_drip_settings.email_notification"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.temp_drip_settings.email_notification
        ? _c(
            "div",
            { staticClass: "form-group mb-2" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(_vm._s(_vm.$t("Subject")))
              ]),
              _vm._v(" "),
              _c("TipTapEditor", {
                staticClass: "tiptap-editor mb-3",
                class: {
                  "form-error": _vm.$v.temp_drip_settings.subject.$invalid
                },
                attrs: {
                  height: 28,
                  hideMenuBar: true,
                  variableItemsData: _vm.variables_subject,
                  enableDrop: false,
                  enableYoutube: false
                },
                model: {
                  value: _vm.temp_drip_settings.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.temp_drip_settings, "subject", $$v)
                  },
                  expression: "temp_drip_settings.subject"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.temp_drip_settings.email_notification
        ? _c(
            "div",
            { staticClass: "form-group mb-2" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v(_vm._s(_vm.$t("Body")))
              ]),
              _vm._v(" "),
              _c("TipTapEditor", {
                staticClass: "tiptap-editor flexible",
                class: {
                  "form-error":
                    _vm.$v.temp_drip_settings.email_template.$invalid
                },
                attrs: {
                  extended: true,
                  showImage: false,
                  enableYoutube: false,
                  showLink: false,
                  variableItemsData: _vm.variables_body
                },
                model: {
                  value: _vm.temp_drip_settings.email_template,
                  callback: function($$v) {
                    _vm.$set(_vm.temp_drip_settings, "email_template", $$v)
                  },
                  expression: "temp_drip_settings.email_template"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap justify-content-end mt-3" },
        [
          _c(
            "AppButton",
            {
              staticClass: "py-2 md-flat ml-2 mt-1",
              staticStyle: { width: "100px" },
              attrs: { name: "save-drip-setting-modal" },
              on: { click: _vm.update }
            },
            [_vm._v(_vm._s(_vm.$t("Confirm")))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }