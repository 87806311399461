var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-tabs", class: [{ "has-content": _vm.showContent }] },
    [
      _c(
        "ul",
        {
          staticClass: "nav pl-3 pl-sm-0 pr-3 flex-nowrap",
          class: [_vm.sizeClass],
          attrs: { id: _vm.id, role: "tablist" }
        },
        _vm._l(_vm.items, function(item) {
          return _c(
            "li",
            {
              key: item.id + "-tab",
              staticClass: "nav-item",
              class: { "nav-item-error": item.error },
              attrs: { role: "presentation", id: item.id + "-tab" }
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link h4 text-bold",
                  class: { active: _vm.selectedTab === item.id },
                  attrs: {
                    "data-toggle": "tab",
                    href: "" + item.id,
                    role: "tab",
                    "aria-controls": "" + item.id,
                    "aria-selected": "true"
                  },
                  on: {
                    click: function($event) {
                      return _vm.onTabSelected(item.id)
                    }
                  }
                },
                [
                  item.icon
                    ? [
                        _vm.loadSvg
                          ? _c("inline-svg", {
                              staticClass: "tab-icon mr-2",
                              attrs: { src: item.icon }
                            })
                          : _c("img", {
                              staticClass: "tab-icon mr-2",
                              attrs: { src: item.icon }
                            })
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "text-truncate" }, [
                    _vm._v(_vm._s(item.title))
                  ])
                ],
                2
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.showContent
        ? _c(
            "div",
            { staticClass: "tab-content", attrs: { id: _vm.id + "Content" } },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }