var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block block--lead-magnet" },
    [
      _vm.image
        ? _c(
            "div",
            { staticClass: "d-none block__image" },
            [
              _c("image-preview", {
                attrs: {
                  source: _vm.image,
                  width: 433,
                  height: 433,
                  alt: "block image"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "block__content" }, [
        _vm.image
          ? _c(
              "div",
              { staticClass: "block__image" },
              [
                _c("image-preview", {
                  attrs: {
                    source: _vm.image,
                    width: 433,
                    height: 433,
                    alt: "block image"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "block__text" }, [
          _c("h4", { staticClass: "block__heading" }, [
            _vm._v(_vm._s(_vm.heading))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "block__subheading" }, [
            _vm._v(_vm._s(_vm.tagline))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("Form", {
        ref: "inputForm",
        attrs: {
          "show-base": true,
          "show-message": false,
          questions: _vm.questions,
          fcpComplete: _vm.fcpComplete,
          disabled: _vm.disabled
        },
        on: {
          setFanDetails: _vm.setFanDetails,
          setAnswers: _vm.setAnswers,
          formIsValid: _vm.setFanDetailsValid
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "cta-button" }, [
        _c(
          "button",
          {
            staticClass: "cta-button__button",
            attrs: { disabled: _vm.formSubmitting },
            on: { click: _vm.handleButtonClick }
          },
          [
            _c("div", { staticClass: "cta-button__label" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-none cta-button__icon--right" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "17",
                    viewBox: "0 0 20 17",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M19.4524 8.11813L10.6704 0.695421C10.3454 0.420714 9.84766 0.651735 9.84766 1.07729V15.9227C9.84766 16.3483 10.3454 16.5793 10.6704 16.3046L19.4524 8.88187C19.6887 8.68213 19.6887 8.31787 19.4524 8.11813Z",
                      fill: "white"
                    }
                  }),
                  _vm._v(" "),
                  _c("rect", {
                    attrs: {
                      x: "0.796875",
                      y: "6.1842",
                      width: "10.321",
                      height: "4.73679",
                      rx: "0.5",
                      fill: "white"
                    }
                  })
                ]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-center form-error" }, [
        _c("small", { staticClass: "stan-text-danger text-danger" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.formErrorMessage) + "\n\t\t")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }