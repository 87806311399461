var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobile
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "body flex-grow-1 col-12 col-md-5 px-0" }, [
          _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column  align-items-center  gap v-gap pt-1"
              },
              [
                _c(
                  "AppButton",
                  {
                    attrs: {
                      name: "next-step-cancel-subscription-confirmation",
                      size: "large"
                    },
                    on: { click: _vm.nextStep }
                  },
                  [_vm._v("Thanks!")]
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "body flex-grow-1 col-12 col-md-5 px-0" }, [
          !_vm.imageReady
            ? _c("div", { staticClass: "image-placeholder" })
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imageReady,
                expression: "imageReady"
              }
            ],
            attrs: { src: "/images/cancellation/bye.svg" },
            on: {
              load: function($event) {
                _vm.imageReady = true
              }
            }
          })
        ])
      ])
    : _c(
        "div",
        {
          staticClass:
            "container mobile-container d-flex justify-content-center align-items-center"
        },
        [
          _c("div", { staticClass: "body flex-column flex-gap-50 h-100" }, [
            _c(
              "div",
              { staticClass: "body flex-shrink-1 overflow-hidden px-0" },
              [
                !_vm.imageReady
                  ? _c("div", { staticClass: "image-placeholder" })
                  : _vm._e(),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageReady,
                      expression: "imageReady"
                    }
                  ],
                  attrs: { src: "/images/cancellation/bye.svg" },
                  on: {
                    load: function($event) {
                      _vm.imageReady = true
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "body px-0" }, [
              _c("div", [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column  align-items-center  gap v-gap pt-1"
                  },
                  [
                    _c(
                      "AppButton",
                      {
                        attrs: {
                          name: "next-step-cancel-subscription-confirmation",
                          size: "large"
                        },
                        on: { click: _vm.nextStep }
                      },
                      [_vm._v("Thanks!")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "modal-body w-full py-0 d-flex flex-column align-items-center"
      },
      [
        _c("h3", { staticClass: "modal-heading" }, [
          _vm._v("We hope to see you again soon!")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-subheading" }, [
          _vm._v("\n\t\t\t\t\tPlease reach out to "),
          _c("a", { attrs: { href: "mailto:friends@stanwith.me" } }, [
            _vm._v("friends@stanwith.me")
          ]),
          _vm._v(" if you have any questions\n\t\t\t\t")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "modal-body w-full py-0 d-flex flex-column align-items-center"
      },
      [
        _c("h3", { staticClass: "modal-heading" }, [
          _vm._v("We hope to see you again soon!")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-subheading" }, [
          _vm._v("\n\t\t\t\t\t\tPlease reach out to "),
          _c("a", { attrs: { href: "mailto:friends@stanwith.me" } }, [
            _vm._v("friends@stanwith.me")
          ]),
          _vm._v(" if you have any questions\n\t\t\t\t\t")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }